import React, { FC, useCallback, useEffect, useMemo, useState, ReactNode } from 'react';
import useDebounce from 'react-use/lib/useDebounce';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import _ from 'lodash';

import TableTitle from './tableTitle/TableTitle';
import Button from '../button/Button';
import TableToolbar from './tableToolbar/TableToolbar';
import {
  generateActions,
  generateColumns,
  generateOptions,
  getFilterParams,
  getSortParams,
} from './helpers/tableHelper';
import { TableProps } from './Table';
import { AnyFunction, CustomAny, AnyObject } from '../../types/generics';
import { DEFAULT_TABLE_SETTINGS } from './constants/common';
import { Variant } from '../button/enums/Variant';
import { ExtendedSearchAction } from './interfaces/ExtendedSearchAction';

import './Table.scss';

interface ServerTableProps extends TableProps {
  count: number;
  getData: AnyFunction;
  searchName?: string;
  loading?: boolean;
  filter?: boolean;
  extendedSearchOptions?: ExtendedSearchAction[];
  onFilterChanged?: AnyFunction;
  refreshButton?: boolean;
  onSearchChange?: AnyFunction;
  customRowRender?: (data: CustomAny[], dataIndex: number, rowIndex: number) => React.ReactNode;
  components?: CustomAny;
  totalPages: number;
  currentPageIndex: number;
}

const ServerTable: FC<ServerTableProps> = (props) => {
  // destructuring getData to prevent infinite refresh in dependencies
  const { getData, textLabels = {}, searchName = 'search', customRowRender, extendedSearchOptions } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || DEFAULT_TABLE_SETTINGS.rowsPerPage);
  const [filterParams, setFilterParams] = useState<AnyObject>({ page, rowsPerPage });
  const [sortParams, setSortParams] = useState<{ fieldToSort?: string; ascended?: boolean }>(props.sortOrder || {});
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [, cancelDebouncedSearch] = useDebounce(() => setDebouncedSearch(searchText), 1000, [searchText]);
  const columns = useMemo(() => {
    const generatedColumns = generateColumns(props.columns);
    if (props.actions?.length) {
      generatedColumns.push(generateActions(props.actions, props.data));
    }
    return generatedColumns;
  }, [props.actions, props.columns, props.data]);

  const getTableData = useCallback(
    async (data) => {
      setIsLoading(true);
      await getData(data);
      setIsLoading(false);
    },
    [getData],
  );
  const handleFilterSubmit = useCallback(
    (applyFilters) => {
      const filterList = applyFilters();
      setFilterParams(getFilterParams(filterList, columns));
    },
    [columns],
  );
  const onFilterChipClose = useCallback(
    (index: number, removedFilter: string, filterList: CustomAny): void => {
      const newFilters = (): CustomAny => filterList;
      handleFilterSubmit(newFilters);
    },
    [handleFilterSubmit],
  );
  const onColumnSortChange = useCallback(
    (changedColumn, direction) => {
      props.onSort?.(getSortParams(changedColumn, direction, columns));
      setSortParams(getSortParams(changedColumn, direction, columns));
    },
    [columns, props.onSort],
  );
  const onSearchChange = useCallback((filterText: string): void => {
    setSearchText(filterText);
  }, []);
  const onRowsPerPageChange = useCallback(
    (value: number) => {
      props.onRowPerPage?.(value);
      setRowsPerPage(value);
    },
    [props.onRowPerPage],
  );

  const getFilteredData = useCallback((): void => {
    let data = {
      ...filterParams,
      page,
      size: rowsPerPage,
      ...sortParams,
      [searchName]: debouncedSearch,
    };
    if (debouncedSearch && extendedSearchOptions?.length) {
      data = {
        ...data,
        ...extendedSearchOptions?.reduce<AnyObject>((acc, { name, value }) => {
          acc[name] = value;
          return acc;
        }, {}),
      };
    }
    getTableData(data);
  }, [debouncedSearch, extendedSearchOptions, filterParams, getTableData, page, rowsPerPage, searchName, sortParams]);

  const serverSideOptions: Partial<MUIDataTableOptions> = {
    serverSide: true,
    count: props.count,
    filter: props.filter,
    rowsPerPageOptions: props.rowsPerPageOptions || DEFAULT_TABLE_SETTINGS.rowsPerPageOptions,
    confirmFilters: true,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div className="filter-footer">
          <Button variant={Variant.Outlined} onClick={(): void => handleFilterSubmit(applyNewFilters)}>
            Apply Filters
          </Button>
        </div>
      );
    },
    search: props.search,
    setRowProps: props.setRowProps,
    rowsPerPage,
    page,
    onTableChange: (action, tableState) => {
      if (props.totalPages === tableState.page + 1 && page < tableState.page) {
        setPage(tableState.page);
      }
    },
    selectToolbarPlacement: props.hideToolbar ? 'none' : 'above',
    onChangeRowsPerPage: onRowsPerPageChange,
    onChangePage: setPage,
    onFilterChipClose,
    onColumnSortChange,
    onSearchClose: (): void => onSearchChange(''),
    searchProps: {
      onKeyUp: (e: CustomAny): void => {
        if (e.key === 'Enter') {
          cancelDebouncedSearch();
          return setDebouncedSearch(searchText);
        }

        onSearchChange(e.target.value.trim());
      },
    },
    textLabels,
    customRowRender,
  };

  if (!props.hideToolbar) {
    serverSideOptions.customToolbar = (): ReactNode =>
      props.refreshButton && <TableToolbar onClick={getFilteredData} />;
  }
  if (sortParams.fieldToSort) {
    serverSideOptions.sortOrder = {
      name: _.find(props.columns, { sortName: sortParams.fieldToSort })?.field || sortParams.fieldToSort,
      direction: sortParams.ascended ? 'asc' : 'desc',
    };
  }

  const options = generateOptions({
    title: <TableTitle title={props.title} onAdd={props.onAdd} isLoading={props.loading || isLoading} />,
    options: serverSideOptions,
    extendedSearchOptions: extendedSearchOptions,
  });

  useEffect(() => {
    getFilteredData();
  }, [getFilteredData]);

  useEffect(() => {
    props.onFilterChanged?.({
      ...filterParams,
      page,
      size: rowsPerPage,
      ...sortParams,
      content: searchText,
    });
  }, [filterParams, page, props.onFilterChanged, rowsPerPage, searchText, sortParams]);
  useEffect(() => {
    if (props.totalPages && props.totalPages < props.currentPageIndex + 1) {
      setPage(props.totalPages - 1);
    }
  }, [props.currentPageIndex, props.totalPages]);
  useEffect(
    useCallback(() => {
      props.onFilterChanged?.({
        ...filterParams,
        page,
        size: rowsPerPage,
        ...sortParams,
        content: searchText,
      });
    }, [filterParams, page, props.onFilterChanged, rowsPerPage, searchText, sortParams]),
    [],
  );

  return (
    <div className="table server-table">
      <MUIDataTable
        data={props.data}
        columns={columns}
        title={
          !props.hideToolbar && (
            <TableTitle
              title={props.title}
              onAdd={props.onAdd}
              entity={props.entity}
              isLoading={props.loading || isLoading}
            />
          )
        }
        options={options}
        components={props.components}
      />
    </div>
  );
};

export default ServerTable;
