import React, { FC, useCallback } from 'react';

import { AnyFunction, AnyObject, CustomAny } from '../../../shared/types/generics';
import { MetadataState } from '../../../store/interfaces/MetadataState';
import { Format } from '../../../store/enums/Format';
import InputControl from '../../../shared/components/form/controls/input/InputControl';
import CheckboxControl from '../../../shared/components/form/controls/checkbox/CheckboxControl';
import DateTimeInputControl from '../../../shared/components/form/controls/datePicker/DateTimeInputControl';
import { truncate } from '../../../shared/helpers/textHelpers';
import { Color as CheckboxColor } from '../../../shared/components/form/controls/checkbox/enums/Color';

import './MetadataFields.scss';
import _ from 'lodash';

interface MetadataFieldsProps {
  values: AnyObject;
  fields: MetadataState[];
}

const MetadataFields: FC<MetadataFieldsProps> = (props) => {
  const handleError = useCallback(
    (field): AnyFunction => (errors): CustomAny => {
      const isError = !!errors?.documentMetadata?.[`md${field.id as string}`];
      let message = '';
      if (isError) {
        if (errors?.documentMetadata?.[`md${field.id as string}`].type === 'required') {
          message = `*${truncate(field.name)} is a required field`;
        } else if (errors?.documentMetadata?.[`md${field.id as string}`].type === 'typeError') {
          message = '*Invalid date format';
        } else {
          message = errors?.documentMetadata?.[`md${field.id as string}`].message;
        }
      }
      return [isError, message];
    },
    [],
  );
  return (
    <section className="metadata-fields">
      {props.fields.map((field) => {
        switch (field.format) {
          case Format.String:
          case Format.Number:
            return (
              <InputControl
                fullWidth
                key={field.id}
                name={`documentMetadata.md${field.id as string}`}
                type={field.format}
                required={field.required}
                defaultValue={
                  props.values[field.id as string] || field.defaultValue || (field.format === Format.Number ? 0 : '')
                }
                label={truncate(field.name)}
                handleError={handleError(field)}
              />
            );
          case Format.Boolean:
            return (
              <CheckboxControl
                key={field.id}
                label={truncate(field.name)}
                name={`documentMetadata.md${field.id as string}`}
                checked={
                  (props.values[field.id as string] !== 'false' && !!props.values[field.id as string]) ||
                  ((field.defaultValue as string) === 'true' && !props.values[field.id as string])
                }
                color={CheckboxColor.Secondary}
                title={field.name}
              />
            );
          case Format.Date:
          case Format.DateTime:
            return (
              <DateTimeInputControl
                key={field.id}
                name={`documentMetadata.md${field.id as string}`}
                label={truncate(field.name)}
                type={field.format}
                value={
                  props.values[field.id as string] ||
                  (_.isNull(field.defaultValue) ? null : new Date(field.defaultValue))
                }
                handleError={handleError(field)}
                fullWidth
              />
            );
          default:
            return null;
        }
      })}
    </section>
  );
};

export default MetadataFields;
