import { connect } from 'react-redux';

import { RootState } from '../../../../store/interfaces/RootState';
import { isProcessingPath } from '../../../../store/requestsProcessing/selectors';
import { DocumentsPaths } from '../../../../shared/api/documents/DocumentsPaths';

export interface StateProps {
  processingUpload: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    processingUpload: isProcessingPath(state)(DocumentsPaths.List),
  };
};

export const connector = connect(mapStateToProps);
