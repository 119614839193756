import React, { FC, useCallback, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';

import Widget from '../../../shared/widget/Widget';
import PrimarySection from '../../../shared/layouts/primarySection/PrimarySection';
import { PERSONAL_DOCS_COLUMNS } from '../helpers/common';
import { AnyObject } from '../../../shared/types/generics';
import { connector, StateProps, DispatchProps } from './connector';
import { TableConf } from '../../../shared/components/table/interfaces/TableConf';
import { TABLE_CONF_PREFIX } from '../../../shared/constants/application';
import { DocumentsTableState } from '../../../store/interfaces/DocumentsTableState';
import ServerTable from '../../../shared/components/table/ServerTable';
import { Entity } from '../../../shared/enums/Entity/Entity';
import { serverTableInitialState } from '../constants/constants';

import './PersonalDocumentsWidget.scss';

const PersonalDocumentsWidget: FC<StateProps & DispatchProps> = (props) => {
  const { activeUser, getDocuments: fetchDocuments } = props;
  const [tableConf, setTableConf] = useLocalStorage<TableConf>(`${TABLE_CONF_PREFIX}_DASH_PERSONAL`, {});
  const [personalList, setPersonalList] = useState<DocumentsTableState>(serverTableInitialState);
  const [processing, setProcessing] = useState<boolean>(false);
  const sortOrder = useMemo(() => {
    if (tableConf?.fieldToSort) {
      return {
        fieldToSort: tableConf.fieldToSort,
        ascended: !!tableConf.ascended,
      };
    }

    return undefined;
  }, [tableConf]);

  const getDocuments = useCallback(
    async (filterParams: AnyObject) => {
      setProcessing(true);
      const docList = await fetchDocuments(
        {
          ...filterParams,
          userIdFilter: activeUser,
        },
        false,
      );
      setPersonalList(docList);
      setProcessing(false);
    },
    [activeUser, fetchDocuments],
  );

  const handleSortChange = useCallback(
    ({ ascended, fieldToSort }) => {
      setTableConf({
        ...tableConf,
        ascended,
        fieldToSort,
      });
    },
    [setTableConf, tableConf],
  );

  return (
    <Widget title="Personal documents">
      <PrimarySection ignoreHeader className="widget-content personal-widget-content" processing={processing}>
        <ServerTable
          columns={PERSONAL_DOCS_COLUMNS}
          entity={Entity.Document}
          hideToolbar
          getData={getDocuments}
          data={personalList.content}
          count={personalList.totalElements}
          search={false}
          fixedHeader
          totalPages={personalList.totalPages}
          currentPageIndex={personalList.number}
          rowsPerPage={10}
          rowsPerPageOptions={[5, 10]}
          sortOrder={sortOrder}
          onSort={handleSortChange}
        />
      </PrimarySection>
    </Widget>
  );
};

export default connector(PersonalDocumentsWidget);
