import { createAction } from 'redux-actions';

import { AnyDispatch, CustomAny } from '../../shared/types/generics';
import { finishProcessingPath, startProcessingPath } from '../requestsProcessing/actions';
import { FoldersPaths } from '../../shared/api/folders/FoldersPaths';
import { HotfolderState } from '../interfaces/HotfolderState';
import * as folderService from '../../shared/api/folders/FoldersApiService';

const setFolders = createAction('FOLDERS_UPDATE');

export const getFoldersList = (queryParams = {}, shouldUpdateList = true) => async (
  dispatch: AnyDispatch,
): Promise<CustomAny> => {
  dispatch(startProcessingPath(FoldersPaths.Admin));
  try {
    const folderList = await folderService.get(queryParams);
    if (shouldUpdateList) {
      dispatch(setFolders(folderList));
    }
    return folderList;
  } finally {
    dispatch(finishProcessingPath(FoldersPaths.Admin));
  }
};

export const addHotfolder = (data: HotfolderState) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(FoldersPaths.Admin));
  try {
    await folderService.add(data);
    await dispatch(getFoldersList());
  } finally {
    dispatch(finishProcessingPath(FoldersPaths.Admin));
  }
};

export const editHotfolder = (id: number, data: HotfolderState) => async (
  dispatch: AnyDispatch,
): Promise<CustomAny> => {
  dispatch(startProcessingPath(FoldersPaths.Admin));
  try {
    await folderService.update(id, data);
    await dispatch(getFoldersList());
  } finally {
    dispatch(finishProcessingPath(FoldersPaths.Admin));
  }
};

export const removeHotfolder = (id: number) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(FoldersPaths.Admin));
  try {
    await folderService.remove(id);
    await dispatch(getFoldersList());
  } finally {
    dispatch(finishProcessingPath(FoldersPaths.Admin));
  }
};

export const activateHotfolder = (id: number) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(FoldersPaths.Admin));
  try {
    await folderService.activateOrPause(id, true);
    await dispatch(getFoldersList());
    return {};
  } catch (e) {
    if ((e as Error).message) {
      return { error: (e as Error).message };
    }
  } finally {
    dispatch(finishProcessingPath(FoldersPaths.Admin));
  }
};

export const pauseHotfolder = (id: number) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(FoldersPaths.Admin));
  try {
    await folderService.activateOrPause(id, false);
    await dispatch(getFoldersList());
    return {};
  } catch (e) {
    if ((e as Error).message) {
      return { error: (e as Error).message };
    }
  } finally {
    dispatch(finishProcessingPath(FoldersPaths.Admin));
  }
};
