import React, { FC, useState, useCallback } from 'react';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import IconButton from '../../../shared/components/iconButton/IconButton';
import { AnyFunction } from '../../../shared/types/generics';
import { Size } from '../../../shared/components/iconButton/enums/Size';

interface AddMenuProps {
  openTemplateDialog: AnyFunction;
  openFolderDialog: AnyFunction;
  label?: string;
  size?: Size;
}

const AddMenu: FC<AddMenuProps> = (props) => {
  const { label = 'Add tree node', size = Size.Medium } = props;
  const [popupOpened, setPopupOpened] = useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const handleOpenClick = useCallback((event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
    setPopupOpened(true);
  }, []);

  const openTemplateDialog = useCallback(
    (event) => {
      event.stopPropagation();
      props.openTemplateDialog({});
      setPopupOpened(false);
    },
    [props],
  );

  const openFolderDialog = useCallback(
    (event) => {
      event.stopPropagation();
      props.openFolderDialog({});
      setPopupOpened(false);
    },
    [props],
  );

  const closePopup = useCallback((event) => {
    event.stopPropagation();
    setPopupOpened(false);
  }, []);

  return (
    <div>
      <IconButton onClick={handleOpenClick} ariaLabel={label} size={size}>
        <AddBoxOutlinedIcon />
      </IconButton>
      <Menu id="create-template-menu" anchorEl={menuAnchor} keepMounted open={popupOpened} onClose={closePopup}>
        <MenuItem onClick={openTemplateDialog}>Add new template</MenuItem>
        <MenuItem onClick={openFolderDialog}>Add new folder</MenuItem>
      </Menu>
    </div>
  );
};

export default AddMenu;
