import React, { FC } from 'react';
import classNames from 'classnames';

import './FormGroup.scss';

interface FormGroupProps {
  className?: string;
  doubleColumn?: boolean;
}

const FormGroup: FC<FormGroupProps> = (props) => {
  return (
    <section className={classNames('form-group', props.className, { 'form-group_double': props.doubleColumn })}>
      {props.children}
    </section>
  );
};

export default FormGroup;
