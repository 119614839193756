import { deleteRequest, getRequest, postRequest, putRequest } from '../api';
import { FoldersPaths } from './FoldersPaths';
import { HotfolderTableState } from '../../../store/interfaces/HotfolderTableState';
import { HotfolderState } from '../../../store/interfaces/HotfolderState';
import { CustomAny, AnyObject } from '../../types/generics';

export const get = (queryParams: AnyObject = {}): Promise<HotfolderTableState> =>
  getRequest(FoldersPaths.Admin, queryParams);

export const add = (folderData: HotfolderState): Promise<CustomAny> =>
  postRequest(FoldersPaths.Admin, JSON.stringify(folderData));

export const update = (id: number, folderData: HotfolderState): Promise<CustomAny> =>
  putRequest(`${FoldersPaths.Admin}/${id}`, folderData);

export const activateOrPause = (id: number, active = false): Promise<CustomAny> =>
  putRequest(`${FoldersPaths.Admin}/${id}/active?active=${active}`, {});

export const remove = (id: number): Promise<CustomAny> => deleteRequest(`${FoldersPaths.Admin}/${id}`);
