import React, { FC, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { HeaderContext } from '../headerProvider/HeaderProvider';
import Header from '../header/Header';
import SideMenu from '../sideMenu/SideMenu';
import { connector, StateProps } from './connector';
import { PAGE_TITLE } from '../../constants/application';

import './Page.scss';

const Page: FC<StateProps> = (props) => {
  const location = useLocation();
  const { showHeader = false, showMenu = false } = props;
  const { header, menu } = useContext(HeaderContext);
  useEffect(() => {
    header.setShow(showHeader);
    menu.setShow(showHeader && showMenu);
  });

  return (
    <section className={classNames('page', { _open: menu.show && menu.opened })}>
      <Header page={PAGE_TITLE[location.pathname]} />
      <SideMenu />
      {props.children}
    </section>
  );
};

export default connector(Page);
