import React, { FC, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import AppLogo from '../../../components/appLogo/AppLogo';
import IconButton from '../../../components/iconButton/IconButton';
import { HeaderContext } from '../../headerProvider/HeaderProvider';

import './SideMenuHeader.scss';

const SideMenuHeader: FC = () => {
  const history = useHistory();
  const { menu } = useContext(HeaderContext);
  const onCloseMenu = useCallback(() => menu.setOpened(false), [menu]);
  const onClickLogo = useCallback(() => history.push('/'), [history]);
  return (
    <section className="side-menu-header">
      <div className="side-menu-header__logo" onClick={onClickLogo}>
        <AppLogo />
      </div>
      <IconButton onClick={onCloseMenu}>
        <ChevronLeftIcon />
      </IconButton>
    </section>
  );
};

export default SideMenuHeader;
