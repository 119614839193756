import React, { FC, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import DateTimeInput, { DatePickerProps } from './DateTimeInput';
import { AnyFunction, AnyObject } from '../../../../types/generics';

interface DatePickerControl extends Partial<DatePickerProps> {
  name: string;
  label?: string;
  onChange?: AnyFunction;
  handleError?(errors: AnyObject): [boolean, string];
}

const DateTimeInputControl: FC<DatePickerControl> = (props) => {
  const { errors, control } = useFormContext();
  let error = !!errors?.[props.name];
  let helperText = errors?.[props.name]?.message;
  if (props.handleError) {
    const [isError, message] = props.handleError(errors);
    if (isError) {
      error = isError;
      helperText = message;
    }
  }
  const [value, setValue] = useState(props.value === null ? null : props.value || new Date());
  const onChange = useCallback(
    (date) => {
      props.onChange?.(!date);
      setValue(date);
    },
    [props],
  );
  return (
    <Controller
      control={control}
      as={
        <DateTimeInput
          error={error}
          name={props.name}
          label={props.label}
          onChange={onChange}
          disabled={props.disabled}
          type={props.type}
          value={value}
          helperText={helperText}
          fullWidth={props.fullWidth}
        />
      }
      defaultValue={value}
      name={props.name}
    />
  );
};

export default DateTimeInputControl;
