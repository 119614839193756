import React, { FC } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import DroppableArea from './droppableArea/DroppableArea';
import DraggableItem from './draggableItem/DraggableItem';
import ItemContent from './itemContent/ItemContent';
import PrimarySection from '../../layouts/primarySection/PrimarySection';
import { Item } from './interfaces/Item';
import { Area } from './interfaces/Area';
import { AnyFunction } from '../../types/generics';

import './DraggableList.scss';

const defaultArea = {
  id: 'droppable',
};

// TODO: implement multiple droppable areas when necessary
interface DraggableListProps {
  items: Item[];
  onDragEnd: AnyFunction;
  areaList?: Area[];
  emptyPlaceholder?: string;
  processing?: boolean;
}

const DraggableList: FC<DraggableListProps> = (props) => {
  const { areaList = [defaultArea] } = props;
  return (
    <PrimarySection ignoreHeader className="empty" processing={props.processing}>
      {props.items.length ? (
        <DragDropContext onDragEnd={props.onDragEnd}>
          {areaList.map((area: Area) => (
            <DroppableArea droppableId={area.id} key={area.id} processing={props.processing}>
              {props.items.map((item: Item, index) => (
                <DraggableItem draggableId={item.id.toString()} index={index} key={item.id}>
                  <ItemContent item={item} />
                </DraggableItem>
              ))}
            </DroppableArea>
          ))}
        </DragDropContext>
      ) : props.emptyPlaceholder && !props.processing ? (
        props.emptyPlaceholder
      ) : (
        ''
      )}
    </PrimarySection>
  );
};

export default DraggableList;
