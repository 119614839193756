import { connect } from 'react-redux';

import { AnyDispatch, AnyObject } from '../../../../shared/types/generics';
import { DocumentState } from '../../../../store/interfaces/DocumentState';
import { changeState, postForDocumentListByState } from '../../../../store/documents/actions';
import { DocumentsTableState } from '../../../../store/interfaces/DocumentsTableState';

export interface DispatchProps {
  changeState(id: number, moveProps: { stateDescriptorId: number; comment?: string }): Promise<DocumentState>;
  getDocuments(bodyParams?: AnyObject, shouldUpdateList?: boolean): Promise<DocumentsTableState>;
}

const mapDispatchToProps = (dispatch: AnyDispatch): DispatchProps => {
  return {
    changeState: (id, moveProps): Promise<DocumentState> => dispatch(changeState(id, moveProps)),
    getDocuments: (props): Promise<DocumentsTableState> => dispatch(postForDocumentListByState(props, false, false)),
  };
};

export const connector = connect(null, mapDispatchToProps);
