import React, { FC } from 'react';

import Title from '../title/Title';
import { Variants } from '../title/enums/Variants';
import { APPLICATION_NAME } from '../../constants/application';

import './AppLogo.scss';
import logo from '../../../assets/logo/pdf2data.png';

const AppLogo: FC = () => {
  return (
    <section className="logo">
      <img src={logo} className="logo__icon" alt="logo" />
      <Title variant={Variants.H6} className="logo__name">
        manager
      </Title>
    </section>
  );
};

export default AppLogo;
