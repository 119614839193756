import { Option } from '../../../../shared/components/form/controls/select/interfaces/Option';
import { Format } from '../../../../store/enums/Format';

export const FORMAT_OPTIONS: Option[] = [
  { value: Format.Boolean, label: 'Boolean' },
  { value: Format.String, label: 'String' },
  { value: Format.Number, label: 'Number' },
  { value: Format.Date, label: 'Date' },
  { value: Format.DateTime, label: 'DateTime' },
];
