import moment from 'moment';
import _ from 'lodash';

import { Column } from '../../../shared/components/table/interfaces/Column';
import { DocumentFormFields } from '../../documents/documentFormDialog/DocumentFormFields';
import { AnyObject, CustomAny } from '../../../shared/types/generics';
import { getDocumentVersion } from '../../documents/helpers/documentHelper';
import { StatesState } from '../../../store/interfaces/StatesState';
import { AgeRange } from '../agingWidget/constants/constants';

const getDaysDiff = (value: CustomAny): number => {
  const creation = moment(new Date(value));
  const today = moment(new Date());
  return today.diff(creation, 'days');
};

const sortComplicatedFieldByName = (order: string) => {
  return (obj1: AnyObject, obj2: AnyObject): number => {
    const val1 = obj1.data.name.toLowerCase();
    const val2 = obj2.data.name.toLowerCase();
    if (val1 > val2) {
      return order === 'asc' ? 1 : -1;
    }
    if (val2 > val1) {
      return order === 'asc' ? -1 : 1;
    }

    return 0;
  };
};

const compareAge = (order: string) => {
  return (obj1: AnyObject, obj2: AnyObject): number => {
    const val1 = moment(new Date(obj1.data));
    const val2 = moment(new Date(obj2.data));
    if (val1 > val2) {
      return order === 'asc' ? -1 : 1;
    }
    if (val2 > val1) {
      return order === 'asc' ? 1 : -1;
    }

    return 0;
  };
};

export const QUEUE_WIDGET_TABLE_COLUMNS: Column[] = [
  {
    title: 'id',
    field: 'id',
    display: 'excluded',
  },
  {
    title: 'Age',
    field: 'creationDate',
    sort: true,
    sortCompare: compareAge,
    getValue: (value: CustomAny): string => `${getDaysDiff(value)} day(s)`,
  },
  {
    title: 'Template',
    field: 'templateFile',
    sort: true,
    sortCompare: sortComplicatedFieldByName,
    getValue: (value: CustomAny): string => value?.name || '',
  },
  {
    title: 'Document',
    field: DocumentFormFields.Name,
    sort: true,
  },
];

export const PERSONAL_DOCS_COLUMNS: Column[] = [
  {
    title: 'Age',
    field: 'creationDate',
    sort: true,
    sortCompare: compareAge,
    getValue: (value: CustomAny): string => `${getDaysDiff(value)} day(s)`,
  },
  {
    title: 'Template',
    field: 'templateFile',
    sort: true,
    sortCompare: sortComplicatedFieldByName,
    getValue: (value: CustomAny): string => value?.name || '',
  },
  {
    title: 'Document',
    field: DocumentFormFields.Name,
    sort: true,
  },
  {
    title: 'State',
    field: 'stateDescriptor',
    sort: true,
    sortCompare: sortComplicatedFieldByName,
    getValue: (value: CustomAny): string => value?.name || '',
  },
  {
    title: 'Version',
    field: 'documentFileVersions',
    sort: false,
    getValue: (versionList): string => getDocumentVersion(versionList),
  },
];

export const DocumentByStateProcessingReducer = (state: number[], action: AnyObject): number[] => {
  switch (action.type) {
    case 'start':
      return _.union(state, [action.id]);
    case 'stop':
      if (state?.includes(action.id)) {
        return _.filter(state, (processingId) => processingId !== action.id);
      }

      return state;
    default:
      return state;
  }
};

export const buildAgingChartData = (
  graphAges: AgeRange[],
  agingStat: AnyObject[],
  states: StatesState[],
): AnyObject => {
  const statesMap = new Map<number, string>();
  states.forEach((state: StatesState) => {
    statesMap.set(state.id, state.name);
  });
  const data = graphAges.map((ages: AgeRange, index: number) => {
    return Object.assign(
      { age: `${ages.minAge}-${ages.maxAge.replace('-1', '...')}` },
      Object.entries(agingStat[index]).reduce<AnyObject>((acc, [key, value]) => {
        const name = statesMap.get(+key);
        if (name) {
          acc[name] = value;
        }
        return acc;
      }, {}),
    );
  });
  return { data, keys: Array.from(statesMap.values()) };
};
