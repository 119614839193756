import React, { FC, useCallback, useRef } from 'react';
import Chip from '@material-ui/core/Chip';

import { getDocumentVersion } from '../../helpers/documentHelper';
import { Entity } from '../../../../shared/enums/Entity/Entity';
import { DocumentState } from '../../../../store/interfaces/DocumentState';
import { Variant } from '../../../../shared/components/button/enums/Variant';
import ButtonUpload from '../../../../shared/components/button/ButtonUpload';
import { connector, StateProps } from './connector';
import { CustomAny, Nullable } from '../../../../shared/types/generics';

interface DocumentTitleProps extends StateProps {
  document?: DocumentState;
  updatedFile?: Nullable<File>;
  setUpdatedFile?(file: File): void;
}

const DocumentTitle: FC<DocumentTitleProps> = (props) => {
  const labelEl = useRef<CustomAny>(null);
  const onFileUpdate = useCallback(
    (file: File) => {
      if (props.document?.id) {
        props.setUpdatedFile?.(file);
      }
    },
    [props],
  );
  return (
    <div className="document-dialog-title dialog-title">
      {props.document?.id ? (
        <>
          <span className="document-dialog-title__label">
            Edit <span className="dialog-title__entity-name">{props.document.name}</span>
          </span>
          <Chip
            color="primary"
            size="small"
            variant="outlined"
            label={`version ${getDocumentVersion(props.document.documentFileVersions)}`}
          />
          {props.setUpdatedFile && (
            <ButtonUpload
              disabled={props.document.archived}
              title="Update document version"
              variant={Variant.Outlined}
              processing={props.processingUpload}
              onFileUpdate={onFileUpdate}
            >
              <span ref={labelEl} className="button-upload-label">
                {props.updatedFile?.name || 'Update'}
              </span>
            </ButtonUpload>
          )}
        </>
      ) : (
        `Add new ${Entity.Document}`
      )}
    </div>
  );
};

export default connector(DocumentTitle);
