import React, { FC, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Dropzone, { DropzoneProps } from '../../../dropzone/Dropzone';
import { AnyFunction } from '../../../../types/generics';

interface FileInputProps extends DropzoneProps {
  name: string;
  defaultValue?: string;
  onChange?: AnyFunction;
}

const FileInput: FC<FileInputProps> = (props) => {
  const FILE_REJECTED_CODE = 'file_rejected';
  const { errors, control, setError, clearErrors } = useFormContext();
  const error = !!errors?.[props.name] || !!errors?.[FILE_REJECTED_CODE];
  const helperText = errors?.[FILE_REJECTED_CODE]
    ? '*File max size is 100mb'
    : errors?.[props.name]?.message && '*File is required';
  const [file, setFile] = useState(props.defaultValue);
  const onChange = useCallback(
    (file) => {
      setFile(file);
      props.onChange?.(file);
    },
    [props.onChange],
  );
  const onError = useCallback(
    (message: string) => {
      setError(FILE_REJECTED_CODE, { type: 'submit', message });
    },
    [setError],
  );
  const clearError = useCallback(() => clearErrors(FILE_REJECTED_CODE), [clearErrors]);
  return (
    <Controller
      control={control}
      as={
        <Dropzone
          onChange={onChange}
          accept={props.accept}
          multiple={props.multiple}
          placeholder={props.placeholder}
          error={error}
          helperText={helperText}
          onError={onError}
          clearError={clearError}
        />
      }
      defaultValue={file}
      name={props.name}
    />
  );
};

export default FileInput;
