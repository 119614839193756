import React, { ComponentType, EventHandler, FC, useCallback, useMemo } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Fade from '@material-ui/core/Fade';
import ClearIcon from '@material-ui/icons/Clear';
import SettingsIcon from '@material-ui/icons/Settings';

import Input from '../../form/controls/input/Input';
import { AnyFunction, AnyObject, CustomAny } from '../../../types/generics';
import IconButton from '../../iconButton/IconButton';
import { Size } from '../../iconButton/enums/Size';
import FormGroup from '@material-ui/core/FormGroup';
import CheckboxControl from '../../form/controls/checkbox/CheckboxControl';
import { Color as CheckboxColor } from '../../../../shared/components/form/controls/checkbox/enums/Color';
import Popover from '../../popover/Popover';
import { ExtendedSearchAction } from '../interfaces/ExtendedSearchAction';

import './TableSearch.scss';

interface TableSearchProps {
  searchText: string;
  onSearch: EventHandler<CustomAny>;
  onHide: AnyFunction;
  options: AnyObject;
  title: ComponentType;
  extendedSearchOptions?: ExtendedSearchAction[];
}

const TableSearch: FC<TableSearchProps> = (props) => {
  const handleTextChange: EventHandler<CustomAny> = (event) => {
    props.onSearch(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const isDisabled = useCallback(
    (option: ExtendedSearchAction): boolean => {
      const filteredValues = props.extendedSearchOptions?.filter((e) => e.value === option.value);
      return !!(option.value && filteredValues && filteredValues.length < 2);
    },
    [props.extendedSearchOptions],
  );

  return (
    <section className="table-search">
      {props.title}
      <Fade in={true} timeout={200}>
        <div className="table-search__input">
          <Input
            placeholder={props.options.textLabels.toolbar.search}
            defaultValue={props.searchText || ''}
            onChange={handleTextChange}
            InputProps={{
              ...props.options.searchProps,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size={Size.Small} onClick={props.onHide}>
                    <ClearIcon />
                  </IconButton>
                  {props.extendedSearchOptions?.length && (
                    <>
                      <IconButton size={Size.Small} onClick={handleClick}>
                        <SettingsIcon />
                      </IconButton>
                      <Popover
                        id="popover"
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                      >
                        <FormGroup>
                          {props.extendedSearchOptions.map((option) => {
                            return (
                              <CheckboxControl
                                key={option.name}
                                name={`extended-search-option-${option.name}`}
                                label={option.label}
                                color={CheckboxColor.Primary}
                                checked={option.value}
                                onChange={option.onClick}
                                disabled={isDisabled(option)}
                              />
                            );
                          })}
                        </FormGroup>
                      </Popover>
                    </>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Fade>
    </section>
  );
};

export default TableSearch;
