import React, { useMemo, FC, ReactEventHandler, ReactNode } from 'react';

import ButtonMaterial from '@material-ui/core/Button';
import { Variant } from './enums/Variant';
import { Color } from './enums/Color';

export interface ButtonProps {
  variant?: Variant;
  color?: Color;
  submit?: boolean;
  disabled?: boolean;
  className?: string;
  title?: string;
  onClick?: ReactEventHandler;
  endIcon?: ReactNode;
}

const Button: FC<ButtonProps> = (props) => {
  const type = useMemo(() => (props.submit ? 'submit' : undefined), [props.submit]);

  return (
    <ButtonMaterial
      type={type}
      variant={props.variant}
      color={props.color}
      disabled={props.disabled}
      title={props.title}
      className={props.className}
      onClick={props.onClick}
      endIcon={props.endIcon}
    >
      {props.children}
    </ButtonMaterial>
  );
};

export default Button;
