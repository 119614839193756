import React, { FC, useCallback, useMemo } from 'react';
import _ from 'lodash';

import FormDialog from '../../../../shared/components/dialog/FormDialog';
import FormGroup from '../../../../shared/components/form/formGroup/FormGroup';
import SelectControl from '../../../../shared/components/form/controls/select/SelectControl';
import SecondaryText from '../../../../shared/components/secondaryText/SecondaryText';
import { StatesState } from '../../../../store/interfaces/StatesState';
import { AnyFunction } from '../../../../shared/types/generics';
import { StateDeleteFormSchema } from './StateDeleteFormSchema';
import { StateDeleteFormData } from './StateDeleteFormData';
import { StateDeleteFormFields } from './StateDeleteFormFields';

interface StateDeleteDialogProps {
  open: boolean;
  onSubmit: AnyFunction;
  onClose: AnyFunction;
  state: StatesState;
  stateList: StatesState[];
}

const StateDeleteDialog: FC<StateDeleteDialogProps> = (props) => {
  const stateOptions = useMemo(
    () =>
      _.filter(props.stateList, ({ id }) => id !== props.state?.id).map((state) => ({
        value: state.id,
        label: <div>{state.name}</div>,
      })),
    [props.state, props.stateList],
  );
  const onSubmit = useCallback(
    (data: StateDeleteFormData) => {
      props.onSubmit(data);
      props.onClose();
    },
    [props],
  );

  return (
    <FormDialog
      open={props.open}
      onClose={props.onClose}
      title={
        <div className="dialog-title">
          Delete <span className="dialog-title__entity-name">{props.state?.name}</span>
        </div>
      }
      onSubmit={onSubmit}
      validationSchema={StateDeleteFormSchema}
      submitLabel="Remove"
    >
      <FormGroup>
        <p>This action cannot be undone. Are you sure?</p>
        Replace by another state for existing documents:
      </FormGroup>
      <FormGroup>
        <SelectControl
          name={StateDeleteFormFields.NewStateDescriptorId}
          label="New state"
          options={stateOptions}
          fullWidth
          required
          value={props.stateList[0]?.id}
        />
      </FormGroup>
    </FormDialog>
  );
};

export default StateDeleteDialog;
