import { connect } from 'react-redux';

import { OnSubmit } from '../../../shared/components/form/types/OnSubmit';
import { AnyDispatch, CustomAny } from '../../../shared/types/generics';
import { login } from '../../../store/user/actions';
import { isProcessingPath } from '../../../store/requestsProcessing/selectors';
import { LoginPaths } from '../../../shared/api/login/LoginPaths';
import { RootState } from '../../../store/interfaces/RootState';

export interface StateProps {
  processing: boolean;
}

export interface DispatchProps {
  onSubmit: OnSubmit;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    processing: isProcessingPath(state)(LoginPaths.SignIn),
  };
};

const mapDispatchToProps = (dispatch: AnyDispatch): DispatchProps => {
  return {
    onSubmit: (formData): Promise<CustomAny> => dispatch(login(formData)),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
