import { handleActions } from 'redux-actions';
import { StatesState } from '../interfaces/StatesState';

const initialState: StatesState[] = [];

export default handleActions(
  {
    STATES_UPDATE: (state, { payload: states }) => states,
  },
  initialState,
);
