import * as yup from 'yup';

import { DocumentFormFields } from './DocumentFormFields';
import { AnyObject } from '../../../shared/types/generics';
import { MetadataState } from '../../../store/interfaces/MetadataState';
import { Format } from '../../../store/enums/Format';

export const DocumentFormSchema = yup.object().shape({
  [DocumentFormFields.Name]: yup.string().trim().required().max(64),
  [DocumentFormFields.User]: yup.number().required('User is a required field'),
  [DocumentFormFields.Template]: yup.number().required('Template is a required field'),
  [DocumentFormFields.State]: yup.number().required('State is a required field'),
  [DocumentFormFields.File]: yup.array().required(),
});

export const DocumentFormEditSchema = yup.object().shape({
  [DocumentFormFields.Name]: yup.string().required().max(64),
  [DocumentFormFields.Template]: yup.number().required('Template is a required field'),
});

export const extendWithMetadata = (schema: yup.ObjectSchema, metadata: AnyObject): yup.ObjectSchema => {
  const metadataSchema: AnyObject = {};
  metadata.forEach((field: MetadataState): void => {
    let format: Format;
    switch (field.format) {
      case Format.String:
      case Format.Number:
      case Format.Boolean:
        format = field.format;
        break;
      case Format.Date:
      case Format.DateTime:
        format = Format.Date;
        break;
    }

    metadataSchema[`md${field.id}`] = yup[format]()
      .when([], {
        is: () => field.required,
        then: yup[format]().required(),
        otherwise: yup[format]().nullable(),
      })
      .when([], (schema: yup.NumberSchema) =>
        field.format === Format.Number && !field.required ? schema.truncate() : schema,
      )
      .when([], (schema: yup.NumberSchema) => (field.format === Format.String ? schema.max(256) : schema));
  });

  return schema.shape({ documentMetadata: yup.object(metadataSchema) });
};
