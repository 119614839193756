import React, { FC, useCallback, useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import SwipeableViews from 'react-swipeable-views';
import _ from 'lodash';

import FormDialog from '../../../shared/components/dialog/FormDialog';
import Tabs from '../../../shared/components/tabs/Tabs';
import GeneralFormTab from './generalFormTab/GeneralFormTab';
import FieldsTab from './fieldsTab/FieldsTab';
import HistoryTab from './historyTab/HistoryTab';
import VersionsTab from './versionsTab/VersionsTab';
import MoveStateDialog from '../moveStateDialog/MoveStateDialog';
import DocumentTitle from '../documentFormDialog/documentTitle/DocumentTitle';
import { AnyObject, Nullable } from '../../../shared/types/generics';
import { DocumentState } from '../../../store/interfaces/DocumentState';
import { DocumentFormEditSchema, extendWithMetadata } from '../documentFormDialog/DocumentFormSchema';
import { DocumentFormData } from '../documentFormDialog/DocumentFormData';
import { DocumentFormFields } from '../documentFormDialog/DocumentFormFields';
import { StatesState } from '../../../store/interfaces/StatesState';
import { Size } from '../../../shared/components/dialog/enums/Size';
import { Variant } from '../../../shared/components/tabs/enums/Variant';
import { DocumentFormDialogProps } from '../documentFormDialog/DocumentFormDialog';
import { DIALOG_TABS } from '../constants/dialog';
import { LogState } from '../../../store/interfaces/LogState';
import { DocumentField } from '../../../store/interfaces/DocumentField';

import '../documentFormDialog/DocumentFormDialog.scss';

interface EditDocumentFormDialogProps extends DocumentFormDialogProps {
  document: DocumentState;
  processing?: boolean;
  invisibleState: StatesState | undefined;
  onDownload(data: DocumentState, version?: number): void;
  onUpdateVersion(file: File): void;
  onChangeState(stateId: number, comment?: string): void;
  onChangeUser(userId: number): void;
  getHistory(queryParams: AnyObject): Promise<LogState[]>;
  getDocumentFieldsWithImages(id: number): Promise<DocumentField[]>;
}

const EditDocumentFormDialog: FC<EditDocumentFormDialogProps> = (props) => {
  const originalAssignee = useMemo(() => props.document?.user?.id || null, [props.document]);
  const [newAssignee, setNewAssignee] = useState(originalAssignee);
  const [selectedDocument, updateSelectedDocument] = useState(props.document);
  const [isDocumentProcessing, setIsDocumentProcessing] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [selectedTab, setSelectedTab] = useState(DIALOG_TABS[0].value);
  const [tags, setTags] = useState<string[]>(props.document?.tags || []);
  const [moveDialogOpened, setMoveDialogOpened] = useState(false);
  const [moveTo, setMoveTo] = useState<Nullable<AnyObject>>(null);
  const [updatedFile, setUpdatedFile] = useState<Nullable<File>>(null);
  const [commentToMoveState, setCommentToMoveState] = useState<string>('');
  const [swipeableActions, setSwipeableActions] = useState<AnyObject>({});
  const onTagsChange = useCallback((tagList: string[]) => {
    setTags(tagList);
  }, []);
  const onSubmit = useCallback(
    async (formData: DocumentFormData) => {
      if (moveTo?.id) {
        await props.onChangeState(moveTo?.id, commentToMoveState);
        updateSelectedDocument({
          ...selectedDocument,
          stateDescriptor: moveTo,
        });
      }
      if (updatedFile) {
        await props.onUpdateVersion(updatedFile);
      }
      formData[DocumentFormFields.Tags] = tags;
      if (originalAssignee !== newAssignee && newAssignee) {
        await props.onChangeUser(newAssignee);
      }
      await props.onSubmit({
        ...formData,
        [DocumentFormFields.Metadata]: _.mapKeys(
          formData[DocumentFormFields.Metadata],
          (value: AnyObject, key: string) => key.replace('md', ''),
        ),
      });
      props.onClose();
    },
    [updatedFile, moveTo, tags, originalAssignee, newAssignee, props, commentToMoveState, selectedDocument],
  );
  const handleCloseMoveDialog = useCallback(() => {
    setMoveDialogOpened(false);
    setMoveTo(null);
    setCommentToMoveState('');
  }, []);
  const moveState = ({ comment }: AnyObject): void => {
    setCommentToMoveState(comment);
    setMoveDialogOpened(false);
  };
  const assignUser = useCallback(async (newUserId) => setNewAssignee(newUserId), [props, selectedDocument]);

  // refresh effect
  useEffect(() => {
    if (props.open) {
      setSelectedTab(DIALOG_TABS[0].value);
      setIsFormValid(true);
      setNewAssignee(originalAssignee);
    } else {
      setCommentToMoveState('');
      setMoveTo(null);
    }
  }, [props.open]);

  useEffect(() => {
    if (props.document && props.invisibleState && props.document.stateDescriptor.id === props.invisibleState.id) {
      setIsDocumentProcessing(true);
    } else {
      setIsDocumentProcessing(false);
    }
    setTags(props.document?.tags || []);
    setIsFormValid(true);
    updateSelectedDocument(props.document);
  }, [props.document, props.invisibleState]);

  useEffect(() => {
    DIALOG_TABS[0].className = !isFormValid ? 'invalid-tab' : '';
  }, [isFormValid, selectedTab]);

  const updateSwipeableViewHeight = useCallback(() => {
    if (swipeableActions && swipeableActions.updateHeight) swipeableActions.updateHeight();
  }, [swipeableActions]);

  return (
    <>
      <FormDialog
        className={classNames('document-dialog document-dialog_edit', {
          'document-dialog__lg': _.size(props.metadataFields) > 0,
        })}
        onSubmit={onSubmit}
        validationSchema={extendWithMetadata(DocumentFormEditSchema, props.metadataFields)}
        open={props.open}
        onClose={props.onClose}
        title={
          <DocumentTitle
            document={selectedDocument}
            updatedFile={updatedFile}
            setUpdatedFile={!isDocumentProcessing ? setUpdatedFile : undefined}
          />
        }
        size={Size.Md}
        isValid={setIsFormValid}
        processing={props.processing}
      >
        <Tabs
          tabsObject={DIALOG_TABS}
          selectedTab={selectedTab}
          onChange={setSelectedTab}
          variant={Variant.FullWidth}
        />
        {
          // @types/react-swipeable-views has missing action type as callback
        }
        <SwipeableViews
          action={(setSwipeableActions as unknown) as string}
          animateHeight
          index={_.findIndex(DIALOG_TABS, { value: selectedTab })}
        >
          <GeneralFormTab
            document={selectedDocument}
            userList={props.userList}
            isDocumentProcessing={isDocumentProcessing}
            stateList={
              isDocumentProcessing && props.invisibleState
                ? [props.invisibleState, ...props.stateList]
                : props.stateList
            }
            metadataFields={props.metadataFields}
            setMoveDialogOpened={setMoveDialogOpened}
            updateSwipeableViewHeight={updateSwipeableViewHeight}
            setMoveTo={setMoveTo}
            moveTo={moveTo}
            commentToMoveState={commentToMoveState}
            tags={tags}
            onTagsChange={onTagsChange}
            onUserChange={assignUser}
            userId={newAssignee}
          />
          <FieldsTab
            updateSwipeableViewHeight={updateSwipeableViewHeight}
            document={selectedDocument}
            getDocumentFieldsWithImages={props.getDocumentFieldsWithImages}
          />
          <HistoryTab
            updateSwipeableViewHeight={updateSwipeableViewHeight}
            processing={props.processing}
            document={selectedDocument}
            getHistory={props.getHistory}
          />
          <VersionsTab document={selectedDocument} onDownload={props.onDownload} />
        </SwipeableViews>
      </FormDialog>
      <MoveStateDialog
        open={moveDialogOpened}
        onSubmit={moveState}
        onClose={handleCloseMoveDialog}
        from={selectedDocument?.stateDescriptor.name}
        to={moveTo?.name}
      />
    </>
  );
};

export default EditDocumentFormDialog;
