import _ from 'lodash';

import { AxiosResponse } from 'axios';
import { AnyObject, CustomAny } from '../../types/generics';

export const handleResponse = (response: AxiosResponse): CustomAny => response.data;

export const handleDownload = (fileName = 'file') => (response: AxiosResponse): CustomAny => {
  let extension;
  // extend extensions when need
  switch (response.headers['content-type']) {
    case 'application/pdf':
      extension = 'pdf';
      break;
    default:
      extension = 'txt';
  }
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.${extension}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const buildFileFormData = (files: File[], data: AnyObject): AnyObject => {
  const fileData = new FormData();
  if (files.length === 1) {
    fileData.append('file', files[0]);
  } else {
    files.forEach((file: File, index) => fileData.append(`file[${index}]`, files[index]));
  }
  _.mapKeys(data, (value, key) => fileData.append(key, new Blob([value], { type: 'application/json' })));

  return fileData;
};

export const buildFormData = (data: AnyObject): AnyObject => {
  const formData = new FormData();
  _.mapKeys(data, (value, key) => {
    formData.append(
      key,
      new Blob([typeof value === 'object' ? JSON.stringify(value) : value], { type: 'application/json' }),
    );
  });
  return formData;
};

export const clearGETFromRestrictedChars = (data: AnyObject): AnyObject => {
  return _.mapValues(data, (param: CustomAny) => {
    if (typeof param === 'string') {
      return param.replace(/[[\]]/g, '');
    }
    return param;
  });
};
