import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import MaterialDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import { AnyFunction } from '../../types/generics';
import { Size } from './enums/Size';
import { Action } from './interfaces/Action';
import Button from '../button/Button';

import './Dialog.scss';

export interface DialogProps {
  open: boolean;
  onClose: AnyFunction;
  size?: Size;
  className?: string;
  title?: ReactNode;
  actions?: Action[];
}

const Dialog: FC<DialogProps> = (props) => {
  const { size = Size.Xs } = props;
  return (
    <MaterialDialog
      open={props.open}
      onClose={props.onClose}
      className={classNames('dialog', props.className)}
      fullWidth
      maxWidth={size}
    >
      {props.title && <DialogTitle>{props.title}</DialogTitle>}
      <DialogContent dividers>{props.children}</DialogContent>
      <DialogActions>
        {props.actions?.map((action, index) => {
          return (
            <Button
              onClick={action.onClick}
              title={action.label}
              key={index}
              variant={action.variant}
              color={action.color}
            >
              {action.label}
            </Button>
          );
        })}
      </DialogActions>
    </MaterialDialog>
  );
};

export default Dialog;
