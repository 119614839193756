import { createAction } from 'redux-actions';
import _ from 'lodash';

import { AnyDispatch, AnyObject, CustomAny } from '../../shared/types/generics';
import { finishProcessingPath, startProcessingPath } from '../requestsProcessing/actions';
import { DocumentsPaths } from '../../shared/api/documents/DocumentsPaths';
import * as documentsApiService from '../../shared/api/documents/DocumentsApiService';
import { DocumentState } from '../interfaces/DocumentState';
import { DocumentField } from '../interfaces/DocumentField';

const setDocuments = createAction('DOCUMENTS_UPDATE');
const setTags = createAction('TAGS_SET', (tags: string[]) => ({ tags }));

export const getDocumentsList = (queryParams = {}, shouldUpdateList = true, shouldStartLoading = true) => async (
  dispatch: AnyDispatch,
): Promise<CustomAny> => {
  shouldStartLoading && dispatch(startProcessingPath(DocumentsPaths.List));
  try {
    const docList = await documentsApiService.get(queryParams);
    if (shouldUpdateList) {
      dispatch(setDocuments(docList));
    }
    return docList;
  } finally {
    shouldStartLoading && dispatch(finishProcessingPath(DocumentsPaths.List));
  }
};

export const postForDocumentList = (
  bodyParams: AnyObject = {},
  shouldUpdateList = true,
  shouldStartLoading = true,
) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  shouldStartLoading && dispatch(startProcessingPath(DocumentsPaths.List));
  try {
    if (bodyParams.tagsFilter) {
      bodyParams.tagsFilter = bodyParams.tagsFilter.split(',');
    }
    const docList = await documentsApiService.postWithTextSearch(bodyParams);
    // for tests
    /* docList.content[0].stateDescriptor.name = 'processing';
    docList.content[0].stateDescriptor.id = 1; */

    if (shouldUpdateList) {
      dispatch(setDocuments(docList));
    }
    return docList;
  } finally {
    shouldStartLoading && dispatch(finishProcessingPath(DocumentsPaths.List));
  }
};

export const postForDocumentListByState = (
  bodyParams: AnyObject = {},
  shouldUpdateList = true,
  shouldStartLoading = true,
) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  shouldStartLoading && dispatch(startProcessingPath(DocumentsPaths.List));
  try {
    const docList = await documentsApiService.postWithStateSearch(bodyParams);
    if (shouldUpdateList) {
      dispatch(setDocuments(docList));
    }
    return docList;
  } finally {
    shouldStartLoading && dispatch(finishProcessingPath(DocumentsPaths.List));
  }
};

export const postForDocumentListByUser = (
  bodyParams: AnyObject = {},
  shouldUpdateList = true,
  shouldStartLoading = true,
) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  shouldStartLoading && dispatch(startProcessingPath(DocumentsPaths.List));
  try {
    const docList = await documentsApiService.postWithUserSearch(bodyParams);
    if (shouldUpdateList) {
      dispatch(setDocuments(docList));
    }
    return docList;
  } finally {
    shouldStartLoading && dispatch(finishProcessingPath(DocumentsPaths.List));
  }
};

export const addDocument = (documentData: DocumentState, queryParams = {}) => async (
  dispatch: AnyDispatch,
): Promise<CustomAny> => {
  dispatch(startProcessingPath(DocumentsPaths.List));
  try {
    const file = documentData.file;
    delete documentData.file;
    const document = await documentsApiService.add(documentData, file);
    await documentsApiService.assignUser(document.id, (documentData as CustomAny).userId);
    await dispatch(postForDocumentList(queryParams));
    await dispatch(getTagList());
  } finally {
    dispatch(finishProcessingPath(DocumentsPaths.List));
  }
};

export const editDocument = (id: string, documentData: DocumentState, queryParams = {}) => async (
  dispatch: AnyDispatch,
): Promise<CustomAny> => {
  dispatch(startProcessingPath(DocumentsPaths.List));
  try {
    await documentsApiService.update(id, documentData);
    await dispatch(postForDocumentList(queryParams));
    await dispatch(getTagList());
  } finally {
    dispatch(finishProcessingPath(DocumentsPaths.List));
  }
};

export const archiveDocument = (documentData: DocumentState, queryParams = {}) => async (
  dispatch: AnyDispatch,
): Promise<CustomAny> => {
  dispatch(startProcessingPath(DocumentsPaths.List));
  try {
    await documentsApiService.archiveDocument(documentData.id);
    await dispatch(postForDocumentList(queryParams));
    await dispatch(getTagList());
    return {};
  } catch (e) {
    if ((e as Error).message) {
      return { error: (e as Error).message };
    }
  } finally {
    dispatch(finishProcessingPath(DocumentsPaths.List));
  }
};

export const unarchiveDocument = (documentData: DocumentState, queryParams = {}) => async (
  dispatch: AnyDispatch,
): Promise<CustomAny> => {
  dispatch(startProcessingPath(DocumentsPaths.List));
  try {
    await documentsApiService.unarchiveDocument(documentData.id);
    await dispatch(postForDocumentList(queryParams));
    await dispatch(getTagList());
  } finally {
    dispatch(finishProcessingPath(DocumentsPaths.List));
  }
};

export const resetDocsToDefault = () => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(setDocuments({ content: [] }));
};

export const downloadDocument = (documentData: DocumentState, version = 0) => (): Promise<CustomAny> => {
  let downloadVersion = version;
  if (!downloadVersion) {
    downloadVersion = _.orderBy(documentData.documentFileVersions, ['version'], ['desc'])[0]?.version;
  }

  return documentsApiService.download(documentData.id, downloadVersion, documentData.name);
};

export const updateVersion = (id: string | number, file: File) => async (
  dispatch: AnyDispatch,
): Promise<DocumentState> => {
  dispatch(startProcessingPath(DocumentsPaths.List));
  try {
    return await documentsApiService.updateVersion(id, file);
  } finally {
    dispatch(finishProcessingPath(DocumentsPaths.List));
  }
};

export const changeState = (id: number, moveProps: { stateDescriptorId: number; comment?: string }) => async (
  dispatch: AnyDispatch,
): Promise<DocumentState> => {
  dispatch(startProcessingPath(DocumentsPaths.List));
  try {
    return await documentsApiService.changeState(id, moveProps);
  } finally {
    dispatch(finishProcessingPath(DocumentsPaths.List));
  }
};

export const changeUser = (id: number, userId: number) => async (dispatch: AnyDispatch): Promise<DocumentState> => {
  dispatch(startProcessingPath(DocumentsPaths.List));
  try {
    return await documentsApiService.assignUser(id, userId);
  } finally {
    dispatch(finishProcessingPath(DocumentsPaths.List));
  }
};

export const getTagList = () => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(DocumentsPaths.Tags));
  try {
    dispatch(setTags(await documentsApiService.getTagList()));
  } finally {
    dispatch(finishProcessingPath(DocumentsPaths.Tags));
  }
};

export const getDocumentFields = (id: number) => async (dispatch: AnyDispatch): Promise<DocumentField[]> => {
  dispatch(startProcessingPath(DocumentsPaths.List));
  try {
    return await documentsApiService.getDocumentFields(id);
  } finally {
    dispatch(finishProcessingPath(DocumentsPaths.List));
  }
};

export const getDocumentFieldsWithImages = (id: number) => async (dispatch: AnyDispatch): Promise<DocumentField[]> => {
  dispatch(startProcessingPath(DocumentsPaths.List));
  try {
    return await documentsApiService.getDocumentFieldsWithImages(id);
  } finally {
    dispatch(finishProcessingPath(DocumentsPaths.List));
  }
};
