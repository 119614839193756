import React, { FC, ReactNode, useCallback } from 'react';

import { AnyFunction } from '../../types/generics';
import { Color } from '../button/enums/Color';
import { Variant } from '../button/enums/Variant';
import Dialog from './Dialog';
import { Action } from './interfaces/Action';

interface CustomActionDialogProps {
  open: boolean;
  onClose: AnyFunction;
  onSubmit?: AnyFunction;
  actionName?: string;
  title?: ReactNode;
  message?: string;
}

const CustomActionDialog: FC<CustomActionDialogProps> = (props) => {
  const { message = 'This action cannot be undone. Are you sure?' } = props;
  const onSubmit = useCallback(() => {
    props.onSubmit && props.onSubmit();
    props.onClose();
  }, [props]);

  const actions: Action[] = [
    {
      label: 'Cancel',
      onClick: props.onClose,
      color: Color.Default,
    },
  ];

  if (props.actionName && props.onSubmit) {
    actions.push({
      label: props.actionName,
      onClick: onSubmit,
      color: Color.Primary,
      variant: Variant.Contained,
    });
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} actions={actions} title={props.title}>
      <p>{message}</p>
    </Dialog>
  );
};

export default CustomActionDialog;
