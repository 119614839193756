import React, { FC, ReactElement } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import MaterialList from '@material-ui/core/List';

import PrimarySection from '../../../layouts/primarySection/PrimarySection';
import Paper from '../../paper/Paper';

interface DroppableAreaProps {
  droppableId: string;
  processing?: boolean;
}

const DroppableArea: FC<DroppableAreaProps> = (props) => {
  return (
    <PrimarySection ignoreHeader fullWidth>
      <Paper className="draggable-area-container" elevation={0} padding={false}>
        <MaterialList>
          <Droppable droppableId={props.droppableId}>
            {(provided, snapshot): ReactElement => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {props.children}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </MaterialList>
      </Paper>
    </PrimarySection>
  );
};

export default DroppableArea;
