import React, { FC, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import { OnSubmit } from './types/OnSubmit';
import { AnyObject, CustomAny } from '../../types/generics';

import './Form.scss';

interface FormProps {
  className?: string;
  validationSchema?: CustomAny;
  defaultValues?: AnyObject;
  isValid?(state: boolean): void;
  onSubmit: OnSubmit;
}

const Form: FC<FormProps> = (props) => {
  const methods = useForm({
    resolver: props.validationSchema ? yupResolver(props.validationSchema) : undefined,
    defaultValues: props.defaultValues,
    shouldFocusError: true,
    mode: 'all',
    reValidateMode: 'all',
  });

  const submitForm: OnSubmit = async (formData) => {
    methods.clearErrors();
    try {
      await props.onSubmit(formData);
    } catch (e) {
      if (e.fieldErrors) {
        e.fieldErrors.forEach(({ field, message }: AnyObject) => {
          if (field.includes('documentMetadata')) {
            methods.setError(field.replace('[', '[md'), { type: 'submit', message });
          } else {
            methods.setError(field, { type: 'submit', message });
          }
        });
      } else {
        methods.setError('form', { type: 'submit', message: e.message });
      }
    }
  };

  const clearFormErrors = useCallback(() => {
    methods.clearErrors('form');
  }, [methods]);

  useEffect(() => {
    props.isValid?.(methods.formState.isValid);
  }, [methods.formState, props.isValid]);

  return (
    <FormProvider {...methods}>
      <form
        className={classNames('form', props.className)}
        onSubmit={methods.handleSubmit(submitForm)}
        onClick={clearFormErrors}
      >
        {props.children}
      </form>
    </FormProvider>
  );
};

export default Form;
