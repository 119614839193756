import React, { FC } from 'react';
import classNames from 'classnames';

import './SecondaryText.scss';

interface SecondaryTextProps {
  className?: string;
}

const SecondaryText: FC<SecondaryTextProps> = (props) => {
  return <span className={classNames('secondary-text', props.className)}>{props.children}</span>;
};

export default SecondaryText;
