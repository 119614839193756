import { ReactNode } from 'react';
import _ from 'lodash';

import { Column } from '../../../../shared/components/table/interfaces/Column';
import { HotfoldersFormFields } from '../hotfoldersFormDialog/HotfoldersFormFields';
import { ColumnType } from '../../../../shared/components/table/enums/ColumnType';
import { Align } from '../../../../shared/components/table/enums/Align';
import { Padding } from '../../../../shared/components/table/enums/Padding';

export const HOTFOLDERS_COLUMNS: Column[] = [
  {
    title: 'Name',
    field: HotfoldersFormFields.Name,
    type: ColumnType.ClickableString,
  },
  {
    title: 'User',
    field: HotfoldersFormFields.User,
  },
  {
    title: 'State',
    field: HotfoldersFormFields.State,
  },
  {
    title: 'Directory Path',
    field: HotfoldersFormFields.DirectoryPath,
    type: ColumnType.LongString,
  },
  {
    title: 'Active',
    field: HotfoldersFormFields.Active,
    type: ColumnType.Boolean,
    align: Align.Center,
    padding: Padding.Checkbox,
  },
];
