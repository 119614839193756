import React, { FC, useCallback } from 'react';
import classNames from 'classnames';

import { AnyFunction, CustomAny } from '../../../shared/types/generics';
import { FolderFormData } from './FolderFormData';
import { TemplatesTreeNode } from '../../../store/types/TemplatesTreeNode';
import { FolderFormSchema } from './FolderFormSchema';
import { FolderFormFields } from './FolderFormFields';
import { NodeType } from '../../../store/enums/NodeType';
import FormDialog from '../../../shared/components/dialog/FormDialog';
import FormGroup from '../../../shared/components/form/formGroup/FormGroup';
import InputControl from '../../../shared/components/form/controls/input/InputControl';
import SelectControl from '../../../shared/components/form/controls/select/SelectControl';

interface NodeFormDialogProps {
  open: boolean;
  onSubmit: AnyFunction;
  onClose: AnyFunction;
  folder?: TemplatesTreeNode;
  parentId?: string;
  directories?: CustomAny;
}

const FolderFormDialog: FC<NodeFormDialogProps> = (props) => {
  const onSubmit = useCallback(
    async (formData: FolderFormData) => {
      await props.onSubmit(formData);
    },
    [props],
  );

  return (
    <FormDialog
      open={props.open}
      onClose={props.onClose}
      title={
        <div className="dialog-title">
          {props.folder?.id ? (
            <>
              Edit <span className="dialog-title__entity-name">{props.folder.name}</span>
            </>
          ) : (
            `Add new ${NodeType.Folder.toLowerCase()}`
          )}
        </div>
      }
      className={classNames('template-node-dialog', { 'template-node-dialog_new': !props.folder?.id })}
      onSubmit={onSubmit}
      validationSchema={FolderFormSchema}
    >
      <FormGroup>
        <InputControl
          name={FolderFormFields.Name}
          label="Name"
          required
          fullWidth
          defaultValue={props.folder?.name}
          autoFocus
        />
      </FormGroup>
      <FormGroup>
        <SelectControl
          name={FolderFormFields.Parent}
          options={props.directories}
          label="Parent folder"
          fullWidth
          value={props.folder?.parentId || props.parentId || undefined}
        />
      </FormGroup>
    </FormDialog>
  );
};

export default FolderFormDialog;
