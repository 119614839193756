import { Routes } from '../../routes/Routes';
import { AdminRoutes } from '../../routes/adminRouter/AdminRoutes';

export const APPLICATION_NAME = 'Pdf2Data manager';
export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY, h:mm:ss';
export const PAGE_TITLE: { [key: string]: string } = {
  [Routes.Admin]: 'Administration',
  [AdminRoutes.Metadata]: 'Administration',
  [AdminRoutes.States]: 'Administration',
  [AdminRoutes.Users]: 'Administration',
  [Routes.Templates]: 'Templates',
  [Routes.Documents]: 'Documents',
  [Routes.Dashboard]: 'Dashboard',
  [Routes.Logs]: 'Logs',
};
// localStorage keys
export const USER_KEY = 'docs-stream-user';
export const SIDE_MENU_KEY = 'side-menu-opened';
export const EXPANDED_TEMPLATES_KEY = 'expanded-templates';
export const TABLE_CONF_PREFIX = 'table-configuration-';
export const LAST_TEMPLATE = 'last-template-id';
export const IS_CONTENT_SEARCH = 'is-content-search';
export const IS_METADATA_SEARCH = 'is-matadata-search';
export const IS_PARSING_SEARCH = 'is-parsing-search';
