import React, { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { Skeleton } from '@material-ui/lab';

import Widget from '../../../shared/widget/Widget';
import AgingGraph from './agingGraph/AgingGraph';
import PrimarySection from '../../../shared/layouts/primarySection/PrimarySection';
import { buildAgingChartData } from '../helpers/common';
import { Nullable, AnyObject, CustomAny } from '../../../shared/types/generics';
import { StatesState } from '../../../store/interfaces/StatesState';
import { AGE_RANGES, AGE_RANGES_OPTIONS } from './constants/constants';
import FormGroup from '../../../shared/components/form/formGroup/FormGroup';
import Select from '../../../shared/components/form/controls/select/Select';
import * as documentsApiService from '../../../shared/api/documents/DocumentsApiService';

import './AgingWidget.scss';

interface AgingWidgetProps {
  states: StatesState[];
}

const AgingWidget: FC<AgingWidgetProps> = (props) => {
  const { states } = props;
  const [progressing, setProgressing] = useState(true);
  const [agingStat, setAgingStat] = useState<{ [key: string]: number }[]>([]);
  const [graphData, setGraphData] = useState<Nullable<AnyObject>>(null);
  const [ageRangeOption, setAgeRangeOption] = useState(AGE_RANGES_OPTIONS[0].value);

  const currentGraphAges = useMemo(() => AGE_RANGES[ageRangeOption], [ageRangeOption]);

  useEffect(() => {
    states?.length && getStatData(currentGraphAges);
  }, [states, currentGraphAges]);

  useEffect(() => {
    agingStat.length && setGraphData(buildAgingChartData(currentGraphAges, agingStat, states));
  }, [agingStat, currentGraphAges, states]);

  const getStatData = async (queryParams: AnyObject[]): Promise<void> => {
    setProgressing(true);
    const promises: Promise<CustomAny>[] = [];
    queryParams.forEach((obj) => promises.push(documentsApiService.getStatistics(obj)));
    const res = await Promise.allSettled(promises);
    setAgingStat(res.map((e: AnyObject) => (e.status === 'fulfilled' ? e.value : {})));
    setProgressing(false);
  };

  return (
    <Widget title="Aging graph">
      <PrimarySection ignoreHeader className="widget-content aging-widget-content">
        {progressing || !graphData ? (
          <Skeleton variant="rect" width="100%" height="300px" />
        ) : (
          <>
            <AgingGraph chartData={graphData} />
            <FormGroup className="aging-select">
              <Select
                name="graphAges"
                label="Graph ages"
                options={AGE_RANGES_OPTIONS}
                value={ageRangeOption}
                onChange={(e): void => setAgeRangeOption(e.target.value)}
              />
            </FormGroup>
          </>
        )}
      </PrimarySection>
    </Widget>
  );
};

export default AgingWidget;
