import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { FilledInputProps } from '@material-ui/core/FilledInput';
import TextField from '@material-ui/core/TextField';

import { ControlRef } from '../../types/ControlRef';
import { AnyFunction } from '../../../../types/generics';
import { Variant } from './enums/Variant';

import './Input.scss';

export interface InputProps {
  name?: string;
  inputRef?: ControlRef;
  label?: string;
  placeholder?: string;
  type?: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  className?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  autoFocus?: boolean;
  defaultValue?: string | number;
  autoComplete?: string;
  variant?: Variant;
  onChange?: AnyFunction;
  InputProps?: Partial<FilledInputProps>;
  onKeyPress?: React.KeyboardEventHandler;
  disabled?: boolean;
}

const Input: FC<InputProps> = (props) => {
  const { autoComplete = 'off' } = props;
  const label = useMemo(() => (props.label ? `${props.label}${props.required ? ' *' : ''}` : ''), [
    props.label,
    props.required,
  ]);
  const onKeyPress = useCallback(
    (e) => {
      if (['e', 'E'].includes(e.key) && props.type === 'number') {
        e.preventDefault();
      }

      props.onKeyPress?.(e);
    },
    [props.onKeyPress, props.type],
  );
  return (
    <TextField
      size="small"
      fullWidth={props.fullWidth}
      name={props.name}
      inputRef={props.inputRef}
      label={label}
      type={props.type}
      error={props.error}
      helperText={props.helperText}
      className={classNames('input', props.className)}
      multiline={props.multiline}
      defaultValue={props.defaultValue}
      autoComplete={autoComplete}
      InputProps={props.InputProps}
      onChange={props.onChange}
      placeholder={props.placeholder}
      variant={props.variant}
      onKeyPress={onKeyPress}
      autoFocus={props.autoFocus}
      disabled={props.disabled}
    />
  );
};

export default Input;
