import React, { FC, useCallback, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { AnyFunction, AnyObject } from '../../../../shared/types/generics';
import FormDialog from '../../../../shared/components/dialog/FormDialog';
import FormGroup from '../../../../shared/components/form/formGroup/FormGroup';
import InputControl from '../../../../shared/components/form/controls/input/InputControl';
import SelectControl from '../../../../shared/components/form/controls/select/SelectControl';
import IconButton from '../../../../shared/components/iconButton/IconButton';
import { PERMISSIONS_OPTIONS } from '../constants/dialog';
import { UserFormData } from './UserFormData';
import { AddUserFormSchema, EditUserFormSchema } from './UserFormSchema';
import { UserFormFields } from './UserFormFields';
import { Size } from '../../../../shared/components/iconButton/enums/Size';
import { Entity } from '../../../../shared/enums/Entity/Entity';

interface UserRemoveDialogProps {
  open: boolean;
  onClose: AnyFunction;
  onSubmit: AnyFunction;
  data?: AnyObject;
}

const UserFormDialog: FC<UserRemoveDialogProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = useCallback(() => setShowPassword(!showPassword), [showPassword]);
  const onSubmit = useCallback(
    async (formData: UserFormData) => {
      await props.onSubmit(formData);
      props.onClose();
    },
    [props],
  );

  return (
    <FormDialog
      open={props.open}
      onClose={props.onClose}
      title={
        <div className="dialog-title">
          {props.data?.id ? (
            <>
              Edit <span className="dialog-title__entity-name">{props.data.login}</span>
            </>
          ) : (
            `Add new ${Entity.User}`
          )}
        </div>
      }
      className="metadata-dialog"
      onSubmit={onSubmit}
      validationSchema={props.data?.id ? EditUserFormSchema : AddUserFormSchema}
    >
      <FormGroup>
        <InputControl
          name={UserFormFields.Login}
          label="Login"
          fullWidth
          required
          defaultValue={props.data?.login}
          autoFocus
        />
      </FormGroup>
      <FormGroup>
        <InputControl
          name={UserFormFields.Email}
          label="Email"
          fullWidth
          type="email"
          defaultValue={props.data?.email}
        />
      </FormGroup>
      <FormGroup>
        <InputControl
          autoComplete="new-password"
          name={UserFormFields.Password}
          label="Password"
          fullWidth
          type={showPassword ? 'text' : 'password'}
          required={!props.data}
          helperText={props.data?.id ? 'Do not fill password data if you are not going to update it' : ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size={Size.Small} aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormGroup>
      <FormGroup>
        <SelectControl
          name={UserFormFields.Permissions}
          label="Role"
          options={PERMISSIONS_OPTIONS}
          fullWidth
          multiple
          required
          value={props.data?.permissions || []}
        />
      </FormGroup>
    </FormDialog>
  );
};

export default UserFormDialog;
