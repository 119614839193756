import React, { FC, useCallback, useRef } from 'react';
import { useClickAway } from 'react-use';
import InputAdornment from '@material-ui/core/InputAdornment';
import DoneIcon from '@material-ui/icons/Done';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import IconButton from '../../iconButton/IconButton';
import Input from '../../form/controls/input/Input';
import { Variant } from '../../form/controls/input/enums/Variant';
import { Size } from '../../iconButton/enums/Size';
import { ChipItem } from '../interfaces/ChipItem';
import { AnyFunction } from '../../../types/generics';

interface ChipInputProps {
  item: ChipItem;
  onChange: AnyFunction;
  onSave: AnyFunction;
  onCancel: AnyFunction;
}

const ChipInput: FC<ChipInputProps> = (props) => {
  const ref = useRef(null);
  useClickAway(ref, () => {
    if (props.item.value.trim()) {
      props.onSave();
    }
  });
  const onEnterPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (props.item.value.trim()) {
          props.onSave();
        }
      }
    },
    [props],
  );

  return (
    <section ref={ref}>
      <Input
        className="chip-list__input chip-list__item"
        variant={Variant.Outlined}
        required
        defaultValue={props.item.value}
        onChange={props.onChange}
        onKeyPress={onEnterPress}
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size={Size.Small} onClick={props.onSave} disabled={!props.item.value.trim()}>
                <DoneIcon />
              </IconButton>
              <IconButton size={Size.Small} onClick={props.onCancel}>
                <CloseOutlinedIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </section>
  );
};

export default ChipInput;
