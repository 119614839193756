import { Action, handleActions } from 'redux-actions';
import _ from 'lodash';

import { RequestProcessingState } from '../interfaces/RequestProcessingState';
import { Path } from '../types/Path';

const initialState: RequestProcessingState = {
  processingPaths: [],
};

export default handleActions(
  {
    PATH_PROCESSING_START: (state, action: Action<Path>) => {
      return {
        processingPaths: _.union(state.processingPaths, [action.payload]),
      };
    },
    PATH_PROCESSING_FINISH: (state, action: Action<Path>) => {
      return {
        processingPaths: _.remove(state.processingPaths, action.payload),
      };
    },
  },
  initialState,
);
