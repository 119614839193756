import { createAction } from 'redux-actions';

import { AnyDispatch, CustomAny } from '../../shared/types/generics';
import { finishProcessingPath, startProcessingPath } from '../requestsProcessing/actions';
import * as logsService from '../../shared/api/logs/LogsApiService';
import { LogsPaths } from '../../shared/api/logs/LogsPaths';

const setLogs = createAction('LOGS_SET');
const setOperations = createAction('OPERATIONS_SET', (operations: string[]) => ({ operations }));

export const getLogsList = (queryParams = {}) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(LogsPaths.List));
  try {
    const logs = await logsService.get(queryParams);
    dispatch(setLogs(logs));
  } finally {
    dispatch(finishProcessingPath(LogsPaths.List));
  }
};

export const getDocumentHistory = (queryParams = {}) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(LogsPaths.List));
  try {
    return await logsService.get(queryParams);
  } finally {
    dispatch(finishProcessingPath(LogsPaths.List));
  }
};

export const getOperationsList = () => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(LogsPaths.Operations));
  try {
    const operations = await logsService.getOperations();
    dispatch(setOperations(operations));
  } finally {
    dispatch(finishProcessingPath(LogsPaths.Operations));
  }
};
