import { deleteRequest, getRequest, postRequest, putRequest, postUploadRequest, downloadRequest } from '../api';
import { TemplatesPaths } from './TemplatesPaths';
import { TreeNode } from '../../components/tree/interfaces/TreeNode';
import { AnyObject, CustomAny } from '../../types/generics';
import { FolderState } from '../../../store/interfaces/FolderState';
import { TemplateState } from '../../../store/interfaces/TemplateState';
import { buildFormData } from '../helpers/apiHelper';

export const getTree = (url = TemplatesPaths.Tree): Promise<TreeNode[]> => getRequest(url);

export const getTemplateStatus = (id: string): Promise<CustomAny> =>
  getRequest(`${TemplatesPaths.Templates}/${id}/status`);

export const getTemplateFields = (id: string): Promise<CustomAny> =>
  getRequest(`${TemplatesPaths.Templates}/${id}/fields`);

export const addFolder = (folder: FolderState): Promise<CustomAny> => postRequest(TemplatesPaths.Folders, folder);

export const addTemplate = (template: TemplateState, files: File[]): Promise<CustomAny> =>
  postUploadRequest(TemplatesPaths.Templates, files, { templateFileDescriptor: JSON.stringify(template) });

export const updateFolder = (id: string, folder: FolderState): Promise<CustomAny> =>
  putRequest(`${TemplatesPaths.Folders}/${id}`, folder);

export const updateTemplate = (id: string, template: TemplateState): Promise<CustomAny> =>
  putRequest(`${TemplatesPaths.Templates}/${id}`, template);

export const removeFolder = (id: string): Promise<CustomAny> => deleteRequest(`${TemplatesPaths.Folders}/${id}`);

export const removeTemplate = (id: string): Promise<CustomAny> => deleteRequest(`${TemplatesPaths.Templates}/${id}`);

export const downloadTemplate = (id: string, fileName: string): Promise<CustomAny> =>
  downloadRequest(`${TemplatesPaths.Templates}/${id}`, fileName);

export const addPolicyRules = (id: string, postProcessingRulesObject: AnyObject): Promise<CustomAny> =>
  postRequest(`${TemplatesPaths.Templates}/${id}/policy`, buildFormData(postProcessingRulesObject));
