import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import PrimarySection from '../../../../shared/layouts/primarySection/PrimarySection';
import Table from '../../../../shared/components/table/Table';
import { FIELDS_COLUMNS } from '../../constants/dialog';
import TabContent from '../../../../shared/components/tabs/tabContent/TabContent';
import { DocumentState } from '../../../../store/interfaces/DocumentState';
import { DocumentField } from '../../../../store/interfaces/DocumentField';
import { TableRow, TableCell, Tooltip } from '@material-ui/core';
import { AnyObject, CustomAny } from '../../../../shared/types/generics';
import { FieldParsingStatus } from '../../../../store/enums/FieldParsingStatus';
import { DocumentFieldPolicy } from '../../../../store/enums/DocumentFieldPolicy';

import './FieldsTab.scss';

interface FieldsTabProps {
  document: DocumentState;
  updateSwipeableViewHeight(): void;
  getDocumentFieldsWithImages(id: number): Promise<DocumentField[]>;
}

const FieldsTab: FC<FieldsTabProps> = (props) => {
  const { getDocumentFieldsWithImages, updateSwipeableViewHeight } = props;
  const [documentFields, setDocumentFields] = useState<DocumentField[]>([]);

  const getFields = useCallback(
    async (id) => {
      const fields = await getDocumentFieldsWithImages(id);
      setDocumentFields(fields);
    },
    [getDocumentFieldsWithImages],
  );

  const customRowRender = useCallback(
    (data: CustomAny[], dataIndex: number): ReactNode => {
      return (
        <TableRow>
          {data.map((dataValue, index) => {
            const policyArrayFields = [
              DocumentFieldPolicy.dateDataFieldPolicy,
              DocumentFieldPolicy.decimalDataFieldPolicy,
              DocumentFieldPolicy.integerDataFieldPolicy,
              DocumentFieldPolicy.stringDataFieldPolicy,
            ];

            if (dataValue === FieldParsingStatus.POLICY_FAILED && index === 3) {
              // apply tooltip only for Parsing Status cell
              let title = '';
              const fieldPolicy = props.document.templateFile.templateDataFieldDTOS?.find(
                (policy) => policy.name === data[0],
              );

              if (fieldPolicy) {
                policyArrayFields.forEach((policyName) => {
                  title += fieldPolicy[policyName]
                    ? Object.entries(fieldPolicy[policyName] as AnyObject)
                        .filter(([key, value]) => key !== 'id' && value !== null)
                        .reduce<string>((acc, [key, value]) => {
                          acc += `${key}: ${value}; `;
                          return acc;
                        }, '')
                    : '';
                });
              }
              return (
                <TableCell key={index}>
                  <Tooltip disableFocusListener disableTouchListener title={title}>
                    <span>{dataValue.toLocaleLowerCase().replaceAll('_', ' ')}</span>
                  </Tooltip>
                </TableCell>
              );
            }
            return (
              <TableCell key={index}>
                {dataValue in FieldParsingStatus ? dataValue.toLocaleLowerCase().replaceAll('_', ' ') : dataValue}
              </TableCell>
            );
          })}
        </TableRow>
      );
    },
    [props.document.templateFile.templateDataFieldDTOS],
  );

  useEffect(
    useCallback(() => {
      if (props.document.id) {
        getFields(props.document.id);
      }
    }, [getFields, props.document.id]),
    [props.document],
  );

  useEffect(() => {
    setTimeout(updateSwipeableViewHeight, 0);
  }, [documentFields, updateSwipeableViewHeight]);

  return (
    <TabContent>
      <PrimarySection className="fields-table" ignoreHeader>
        <Table
          columns={FIELDS_COLUMNS}
          data={documentFields}
          search={false}
          customRowRender={customRowRender}
          hideToolbar
          fixedHeader
          customIdField="version"
          pagination={false}
        />
      </PrimarySection>
    </TabContent>
  );
};

export default FieldsTab;
