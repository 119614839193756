import { connect } from 'react-redux';
import { MouseEventHandler } from 'react';

import { AnyDispatch, CustomAny } from '../../types/generics';
import { logout } from '../../../store/user/actions';
import { RootState } from '../../../store/interfaces/RootState';
import { getUserLogin } from '../../../store/user/selectors';

export interface StateProps {
  login: string;
}

export interface DispatchProps {
  onLogout: MouseEventHandler;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    login: getUserLogin(state),
  };
};

const mapDispatchToProps = (dispatch: AnyDispatch): DispatchProps => {
  return {
    onLogout: (): Promise<CustomAny> => dispatch(logout()),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
