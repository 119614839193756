import { DocumentsTableState } from '../../../store/interfaces/DocumentsTableState';

export const serverTableInitialState: DocumentsTableState = {
  content: [],
  tags: [],
  totalElements: 0,
  numberOfElements: 0,
  totalPages: 0,
  number: 0,
};
