import { createSelector } from 'reselect';

import { UserState } from '../interfaces/UserState';
import { RootState } from '../interfaces/RootState';
import { Permission } from '../enums/Permission';

export const getUser = (state: RootState): UserState => state.user;

export const getUserId = createSelector(getUser, (user: UserState) => user.id);

export const getUserPermissions = createSelector(getUser, (user: UserState) => user.permissions);

export const getUserLogin = createSelector(getUser, (user: UserState) => user.login);

export const isUserLoggedIn = createSelector(getUser, (user: UserState) => !!user.login);

export const hasAdminPermission = createSelector(getUserPermissions, (permissions: Permission[]) =>
  permissions.includes(Permission.Admin),
);

export const hasUserPermission = createSelector(getUserPermissions, (permissions: Permission[]) =>
  permissions.includes(Permission.User),
);
