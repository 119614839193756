import { createSelector } from 'reselect';
import _ from 'lodash';

import { RootState } from '../interfaces/RootState';
import { RequestProcessingState } from '../interfaces/RequestProcessingState';
import { Path } from '../types/Path';

const getRequestsProcessing = (state: RootState): RequestProcessingState => state.requestProcessing;

const getProcessingPaths = createSelector(
  getRequestsProcessing,
  (requestProcessing: RequestProcessingState) => requestProcessing.processingPaths,
);

export const isProcessingPath = createSelector(getProcessingPaths, (processingPaths) =>
  _.memoize((path: Path) => processingPaths.includes(path)),
);
