import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import CommentIcon from '@material-ui/icons/Comment';

import PrimarySection from '../../../../shared/layouts/primarySection/PrimarySection';
import ServerTable from '../../../../shared/components/table/ServerTable';
import { HISTORY_COLUMNS } from '../../constants/dialog';
import TabContent from '../../../../shared/components/tabs/tabContent/TabContent';
import { AnyObject } from '../../../../shared/types/generics';
import { DocumentState } from '../../../../store/interfaces/DocumentState';
import { Action } from '../../../../shared/components/table/interfaces/Action';
import { LogState } from '../../../../store/interfaces/LogState';
import LogCommentDialog from '../../logCommentDialog/LogCommentDialog';
import { TABLE_CONF_PREFIX } from '../../../../shared/constants/application';
import { TableConf } from '../../../../shared/components/table/interfaces/TableConf';

import './HistoryTab.scss';

interface HistoryTabProps {
  processing?: boolean;
  document: DocumentState;
  updateSwipeableViewHeight(): void;
  getHistory(queryParams: AnyObject): Promise<LogState[]>;
}

const HistoryTab: FC<HistoryTabProps> = (props) => {
  const { getHistory, updateSwipeableViewHeight } = props;
  const [tableConf, setTableConf] = useLocalStorage<TableConf>(`${TABLE_CONF_PREFIX}document-history`, {});
  const [documentHistory, setDocumentHistory] = useState<AnyObject>({});
  const [logComment, setLogComment] = useState<string>('');
  const [commentDialogOpened, setCommentDialogOpened] = useState(false);
  const sortOrder = useMemo(() => {
    if (tableConf?.fieldToSort) {
      return {
        fieldToSort: tableConf.fieldToSort,
        ascended: !!tableConf.ascended,
      };
    }

    return undefined;
  }, [tableConf]);
  const updateHistory = useCallback(
    async (queryParams = {}) => {
      if (props.document?.id) {
        setDocumentHistory(await getHistory({ ...queryParams, documentIdFilter: props.document?.id }));
      } else {
        setDocumentHistory([]);
      }
    },
    [getHistory, props.document.stateDescriptor],
  );

  const handleReadComment = useCallback((log) => {
    setLogComment(log.comment);
    setCommentDialogOpened(true);
  }, []);

  const handleCloseComment = useCallback(() => {
    setCommentDialogOpened(false);
  }, []);

  useEffect(() => {
    setTimeout(updateSwipeableViewHeight, 0);
  }, [documentHistory.content, updateSwipeableViewHeight]);

  const logActions: Action[] = useMemo(
    () => [
      {
        label: 'Read comment',
        onClick: handleReadComment,
        icon: CommentIcon,
        isHidden: (log): boolean => !log.comment,
      },
    ],
    [handleReadComment],
  );
  const handleRowPerPageChange = useCallback(
    (value: number) => {
      setTableConf({
        ...tableConf,
        rowsPerPage: value,
      });
    },
    [setTableConf, tableConf],
  );
  const handleSortChange = useCallback(
    ({ ascended, fieldToSort }) => {
      setTableConf({
        ...tableConf,
        ascended,
        fieldToSort,
      });
    },
    [setTableConf, tableConf],
  );

  return (
    <TabContent>
      <PrimarySection ignoreHeader processing={props.processing}>
        <ServerTable
          columns={HISTORY_COLUMNS}
          count={documentHistory.totalElements || 0}
          data={documentHistory.content || []}
          getData={updateHistory}
          refreshButton={false}
          actions={logActions}
          hideToolbar
          totalPages={documentHistory.totalPages}
          currentPageIndex={documentHistory.number}
          rowsPerPage={(tableConf as TableConf).rowsPerPage}
          onRowPerPage={handleRowPerPageChange}
          sortOrder={sortOrder}
          onSort={handleSortChange}
        />
      </PrimarySection>
      <LogCommentDialog open={commentDialogOpened} onClose={handleCloseComment}>
        {logComment}
      </LogCommentDialog>
    </TabContent>
  );
};

export default HistoryTab;
