import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import './FormError.scss';

const FormError: FC = () => {
  const { errors } = useFormContext();
  const formError = errors?.form?.message;
  if (!formError) {
    return null;
  }
  return <section className="form-error">*{formError}</section>;
};

export default FormError;
