import React, { FC, FunctionComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { Routes } from '../Routes';
import { connector, StateProps } from './connector';
import Loading from '../../shared/components/loading/Loading';

interface PrivateRouteProps extends StateProps {
  exact?: boolean;
  path: string;
  available: boolean;
  component: FunctionComponent;
}

const PrivateRoute: FC<PrivateRouteProps> = (props) => {
  if (props.processingLogin) {
    return <Loading />;
  }

  if (!props.userLoggedIn) {
    return <Redirect to={Routes.Login} />;
  }

  if (!props.available) {
    return <Redirect to={props.hasUserPermission ? Routes.Dashboard : Routes.Admin} />;
  }

  return <Route exact={props.exact} path={props.path} component={props.component} />;
};

export default connector(PrivateRoute);
