import React, { FC, useCallback, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';

import { Size } from '../../../shared/components/iconButton/enums/Size';
import IconButton from '../../../shared/components/iconButton/IconButton';
import { AnyFunction } from '../../../shared/types/generics';
import CircularLoading from '../../../shared/components/circularLoading/CircularLoading';

interface IconPanelProps {
  onEdit?: AnyFunction;
  onDelete?: AnyFunction;
  onDownload?: AnyFunction;
  disableDelete?: boolean;
}

const IconPanel: FC<IconPanelProps> = (props) => {
  const [downloading, setDownloading] = useState(false);
  const onDownload = useCallback(async () => {
    setDownloading(true);
    await props.onDownload?.();
    setDownloading(false);
  }, [props.onDownload]);
  return (
    <section className="icon-panel">
      {props.onDownload &&
        (downloading ? (
          <div className="icon-panel__progress">
            <CircularLoading size={18} />
          </div>
        ) : (
          <IconButton size={Size.Small} onClick={onDownload} ariaLabel="Download template">
            <GetAppIcon fontSize="small" />
          </IconButton>
        ))}
      <IconButton size={Size.Small} onClick={props.onEdit} ariaLabel="Edit node">
        <EditIcon fontSize="small" />
      </IconButton>
      <IconButton size={Size.Small} onClick={props.onDelete} ariaLabel="Delete node" disabled={props.disableDelete}>
        <DeleteIcon fontSize="small" />
      </IconButton>
    </section>
  );
};

export default IconPanel;
