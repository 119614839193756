import React, { FC, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';

import PrimarySection from '../../shared/layouts/primarySection/PrimarySection';
import QueueWidget from './queueWidget/QueueWidget';
import AgingWidget from './agingWidget/AgingWidget';
import PersonalDocumentsWidget from './personalDocumentsWidget/PersonalDocumentsWidget';
import { DispatchProps, connector, StateProps } from './connector';

import './Dashboard.scss';

const Dashboard: FC<DispatchProps & StateProps> = (props) => {
  useEffect(
    useCallback(() => {
      props.getStatesList();
    }, [props]),
    [],
  );

  useEffect(() => {
    return (): void => props.onLeaveTab();
  }, []);

  return (
    <PrimarySection className="dashboard">
      <Grid container spacing={1} className="dashboard__grid">
        <Grid item xs={12}>
          <QueueWidget processing={props.processingStates} states={props.states} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <AgingWidget states={props.states} />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <PersonalDocumentsWidget />
        </Grid>
      </Grid>
    </PrimarySection>
  );
};

export default connector(Dashboard);
