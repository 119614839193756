import React, { FC, useMemo, ReactNode } from 'react';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MaterialSelect from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import _ from 'lodash';

import { AnyFunction, CustomAny } from '../../../../types/generics';
import { Option } from './interfaces/Option';
import Checkbox from '../checkbox/Checkbox';
import { Color } from '../checkbox/enums/Color';

import './Select.scss';

export interface SelectProps {
  options: Option[];
  value?: CustomAny;
  onChange?: AnyFunction;
  name?: string;
  helperText?: string;
  label?: string;
  required?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  className?: string;
  multiple?: boolean;
  onBlur?: AnyFunction;
  disabled?: boolean;
}

const Select: FC<SelectProps> = (props) => {
  const { value = props.multiple ? [] : '' } = props;
  const classList: string = useMemo(
    () =>
      classNames(
        'select',
        {
          'select_full-width': props.fullWidth,
        },
        props.className,
      ),
    [props.className, props.fullWidth],
  );
  const label = useMemo(() => (props.label ? `${props.label}${props.required ? ' *' : ''}` : ''), [
    props.label,
    props.required,
  ]);

  return (
    <FormControl className={classList} error={props.error} disabled={props.disabled}>
      <InputLabel>{label}</InputLabel>
      <MaterialSelect
        value={value}
        onChange={props.onChange}
        multiple={props.multiple}
        onBlur={props.onBlur}
        renderValue={(selected: CustomAny): ReactNode => {
          return props.multiple
            ? (selected as [])
                .map((value) => _.find(props.options, (option) => option.value === value)?.label)
                .join(', ')
            : _.find(props.options, (option) => option.value === selected)?.label;
        }}
        MenuProps={{
          variant: 'menu',
        }}
      >
        {props.options.map((option, index) =>
          !option.subheader ? (
            <MenuItem value={option.value} key={index} style={option.style}>
              {props.multiple ? <Checkbox checked={value.includes(option.value)} color={Color.Primary} /> : null}
              {option.label}
            </MenuItem>
          ) : (
            <ListSubheader disableSticky style={option.style} key={index} className="select__subheader">
              {option.label}
            </ListSubheader>
          ),
        )}
      </MaterialSelect>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

export default Select;
