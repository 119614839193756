import { handleActions } from 'redux-actions';
import { MetadataState } from '../interfaces/MetadataState';

const initialState: MetadataState[] = [];

export default handleActions(
  {
    METADATA_UPDATE: (state, { payload: metadata }) => metadata,
  },
  initialState,
);
