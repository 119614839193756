import React, { FC, useCallback, useState } from 'react';
import Collapse from '@material-ui/core/Collapse';

import { AnyFunction } from '../../../../shared/types/generics';
import { MetadataFormData } from './MetadataFormData';
import { MetadataFormSchema } from './MetadataFormSchema';
import { MetadataFormFields } from './MetadataFormFields';
import { Color as CheckboxColor } from '../../../../shared/components/form/controls/checkbox/enums/Color';
import FormDialog from '../../../../shared/components/dialog/FormDialog';
import FormGroup from '../../../../shared/components/form/formGroup/FormGroup';
import InputControl from '../../../../shared/components/form/controls/input/InputControl';
import CheckboxControl from '../../../../shared/components/form/controls/checkbox/CheckboxControl';
import DateTimeInputControl from '../../../../shared/components/form/controls/datePicker/DateTimeInputControl';
import SelectControl from '../../../../shared/components/form/controls/select/SelectControl';
import { FORMAT_OPTIONS } from '../constants/dialog';
import { MetadataState } from '../../../../store/interfaces/MetadataState';
import { Entity } from '../../../../shared/enums/Entity/Entity';
import { Format } from '../../../../store/enums/Format';

interface MetadataFormDialogProps {
  open: boolean;
  onSubmit: AnyFunction;
  onClose: AnyFunction;
  field?: MetadataState;
}

const MetadataFormDialog: FC<MetadataFormDialogProps> = (props) => {
  const [selectedFormat, setSelectedFormat] = useState(props.field?.format || FORMAT_OPTIONS[0].value);
  const [required, setRequired] = useState(props.field?.required);
  const [defaultValue, setDefaultValue] = useState(props.field?.defaultValue);
  const onSubmit = useCallback(
    async (formData: MetadataFormData) => {
      if (formData.format === Format.Boolean && formData.required) {
        formData.defaultValue = (defaultValue as string | undefined) === 'true' ? 'true' : 'false';
      }
      await props.onSubmit(formData);
      props.onClose();
    },
    [props, defaultValue],
  );

  React.useEffect(() => {
    setRequired(props.field?.required);
    setDefaultValue(props.field?.defaultValue);
  }, [props.field]);

  const renderDefaultInput = useCallback((): JSX.Element | null => {
    switch (selectedFormat) {
      case Format.String:
      case Format.Number:
        return (
          <InputControl
            name={MetadataFormFields.DefaultValue}
            label="Default"
            type={selectedFormat}
            required={required}
            fullWidth
            defaultValue={props.field?.defaultValue}
          />
        );
      case Format.Boolean:
        return (
          <CheckboxControl
            name={MetadataFormFields.DefaultValue}
            label="Default"
            color={CheckboxColor.Secondary}
            checked={(defaultValue as string) === 'true'}
            onChange={(value): void => setDefaultValue(value.toString())}
          />
        );
      case Format.Date:
      case Format.DateTime:
        return (
          <DateTimeInputControl
            name={MetadataFormFields.DefaultValue}
            label="Default"
            type={selectedFormat}
            value={props.field?.defaultValue || new Date()}
            fullWidth
          />
        );
      default:
        return null;
    }
  }, [defaultValue, props.field, required, selectedFormat]);

  return (
    <FormDialog
      open={props.open}
      onClose={props.onClose}
      title={
        <div className="dialog-title">
          {props.field?.id ? (
            <>
              Edit <span className="dialog-title__entity-name">{props.field.name}</span>
            </>
          ) : (
            `Add new ${Entity.Metadata}`
          )}
        </div>
      }
      className="metadata-dialog"
      onSubmit={onSubmit}
      validationSchema={MetadataFormSchema}
    >
      <FormGroup>
        <InputControl
          name={MetadataFormFields.Name}
          label="Name"
          required
          fullWidth
          defaultValue={props.field?.name}
          autoFocus
        />
      </FormGroup>
      <FormGroup>
        <InputControl
          name={MetadataFormFields.Description}
          label="Description"
          multiline={true}
          fullWidth
          defaultValue={props.field?.description}
        />
      </FormGroup>
      <FormGroup>
        <SelectControl
          name={MetadataFormFields.Format}
          label="Field format"
          options={FORMAT_OPTIONS}
          fullWidth
          required
          value={props.field?.format}
          onChange={setSelectedFormat}
        />
      </FormGroup>
      <FormGroup className="metadata-required-field">
        <CheckboxControl
          disabled={!selectedFormat}
          name={MetadataFormFields.Required}
          label="Required"
          color={CheckboxColor.Secondary}
          checked={props.field?.required}
          onChange={setRequired}
        />
      </FormGroup>

      {required && (
        <FormGroup>
          <Collapse in={required}>{renderDefaultInput()}</Collapse>
        </FormGroup>
      )}
    </FormDialog>
  );
};

export default MetadataFormDialog;
