import * as yup from 'yup';
import { MetadataFormFields } from './MetadataFormFields';

export const MetadataFormSchema = yup.object().shape({
  [MetadataFormFields.Name]: yup.string().trim().required().max(64),
  [MetadataFormFields.Description]: yup.string().trim().max(3600),
  [MetadataFormFields.Format]: yup.string().trim().required(),
  [MetadataFormFields.Required]: yup.boolean(),
  [MetadataFormFields.DefaultValue]: yup
    .string()
    .when([MetadataFormFields.Format], {
      is: (format) => format === 'number',
      then: () => {
        return yup.number().typeError('You must specify a number');
      },
      otherwise: () => {
        return yup.string();
      },
    })
    .when([MetadataFormFields.Required, MetadataFormFields.Format], {
      is: (required, format) => required && format !== 'boolean',
      then: (schema: yup.ObjectSchema) => {
        return schema.required('Default value is required');
      },
      otherwise: (schema: yup.ObjectSchema) => {
        return schema;
      },
    }),
});
