import { connect } from 'react-redux';

import { AnyDispatch, AnyFunction, CustomAny } from '../../shared/types/generics';
import { RootState } from '../../store/interfaces/RootState';
import { isProcessingPath } from '../../store/requestsProcessing/selectors';
import { TemplatesTree } from '../../store/interfaces/TemplatesTree';
import { getTree } from '../../store/templates/selectors';
import { TemplatesPaths } from '../../shared/api/templates/TemplatesPaths';
import {
  getTemplatesTree,
  addTemplatesNode,
  editTemplatesNode,
  removeTemplatesNode,
  addPolicyRules,
  downloadTemplate,
  getTemplateStatus,
  getTemplateFields,
} from '../../store/templates/actions';

export interface StateProps {
  tree: TemplatesTree;
  processing: boolean;
}

export interface DispatchProps {
  getTree: AnyFunction;
  addNode: AnyFunction;
  editNode: AnyFunction;
  addPolicyRules: AnyFunction;
  removeNode: AnyFunction;
  downloadTemplate: AnyFunction;
  getTemplateStatus: AnyFunction;
  getTemplateFields: AnyFunction;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    tree: getTree(state),
    processing: isProcessingPath(state)(TemplatesPaths.Tree),
  };
};

const mapDispatchToProps = (dispatch: AnyDispatch): DispatchProps => {
  return {
    getTree: (): Promise<CustomAny> => dispatch(getTemplatesTree()),
    addNode: (node, isTemplate): Promise<CustomAny> => dispatch(addTemplatesNode(node, isTemplate)),
    editNode: (node, isTemplate): Promise<CustomAny> => dispatch(editTemplatesNode(node, isTemplate)),
    removeNode: (id, isTemplate): Promise<CustomAny> => dispatch(removeTemplatesNode(id, isTemplate)),
    addPolicyRules: (id, updateDocuments, policyArray): Promise<CustomAny> =>
      dispatch(addPolicyRules(id, updateDocuments, policyArray)),
    downloadTemplate: (id, fileName): Promise<CustomAny> => dispatch(downloadTemplate(id, fileName)),
    getTemplateStatus: (id): Promise<CustomAny> => dispatch(getTemplateStatus(id)),
    getTemplateFields: (id): Promise<CustomAny> => dispatch(getTemplateFields(id)),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
