import { ReactNode } from 'react';
import _ from 'lodash';

import { Column } from '../../../../shared/components/table/interfaces/Column';
import { MetadataFormFields } from '../metadataFormDialog/MetadataFormFields';
import { ColumnType } from '../../../../shared/components/table/enums/ColumnType';
import { Align } from '../../../../shared/components/table/enums/Align';
import { Padding } from '../../../../shared/components/table/enums/Padding';
import { FORMAT_OPTIONS } from './dialog';

export const METADATA_COLUMNS: Column[] = [
  { title: 'Name', field: MetadataFormFields.Name, type: ColumnType.ClickableString },
  { title: 'Description', field: MetadataFormFields.Description, type: ColumnType.LongString },
  {
    title: 'Format',
    field: MetadataFormFields.Format,
    getValue: (value: string): ReactNode => _.find(FORMAT_OPTIONS, { value })?.label || '',
  },
  {
    title: 'Required',
    field: MetadataFormFields.Required,
    type: ColumnType.Boolean,
    align: Align.Center,
    padding: Padding.Checkbox,
    searchable: false,
  },
];
