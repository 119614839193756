import * as yup from 'yup';
import { HotfolderType } from '../../../../store/enums/HotfolderType';
import { HotfoldersFormFields } from './HotfoldersFormFields';

export const HotfoldersFormSchema = yup.object().shape({
  [HotfoldersFormFields.Name]: yup.string().trim().required().max(64),
  [HotfoldersFormFields.DirectoryPath]: yup.string().trim().required().max(256),
  [HotfoldersFormFields.TemplateFileId]: yup.number().required(),
  [HotfoldersFormFields.Prefix]: yup.string().trim().max(64),
  [HotfoldersFormFields.Host]: yup.string().when([HotfoldersFormFields.HotfolderType], {
    is: HotfolderType.SFTP,
    then: () => {
      return yup.string().trim().max(256).required('Host is required');
    },
    otherwise: () => {
      return yup.string().trim().max(256);
    },
  }),
  [HotfoldersFormFields.Username]: yup.string().when([HotfoldersFormFields.HotfolderType], {
    is: HotfolderType.SFTP,
    then: () => {
      return yup.string().trim().max(64).required('Username is required');
    },
    otherwise: () => {
      return yup.string().trim().max(64);
    },
  }),
  [HotfoldersFormFields.Password]: yup.string().when([HotfoldersFormFields.HotfolderType], {
    is: HotfolderType.SFTP,
    then: () => {
      return yup.string().trim().max(64).required('Password is required');
    },
    otherwise: () => {
      return yup.string().trim().max(64);
    },
  }),
});
