import React, { FC } from 'react';

import { Size } from '../../../shared/components/iconButton/enums/Size';
import IconPanel from '../iconPanel/IconPanel';
import AddMenu from '../addMenu/AddMenu';

import './TreeNodeLabel.scss';
import { AnyFunction } from '../../../shared/types/generics';

interface TreeNodeLabelProps {
  label: string;
  isTemplate?: boolean;
  hasChildren?: boolean;
  onTemplateAdd?: AnyFunction;
  onFolderAdd?: AnyFunction;
  onEdit?: AnyFunction;
  onDelete?: AnyFunction;
  onDownload?: AnyFunction;
}

const TreeNodeLabel: FC<TreeNodeLabelProps> = (props) => {
  const { onFolderAdd = (): void => {}, onTemplateAdd = (): void => {} } = props;
  return (
    <section className="tree-node-label" data-value={`${props.label}_${props.isTemplate ? 'template' : 'folder'}`}>
      <div className="tree-node-label__item">{props.label}</div>
      {!props.isTemplate && (
        <AddMenu
          openFolderDialog={onFolderAdd}
          openTemplateDialog={onTemplateAdd}
          size={Size.Small}
          label="Add child node"
        />
      )}
      <IconPanel
        onEdit={props.onEdit}
        onDelete={props.onDelete}
        disableDelete={props.hasChildren}
        onDownload={props.onDownload}
      />
    </section>
  );
};

export default TreeNodeLabel;
