import { connect } from 'react-redux';

import { RootState } from '../../store/interfaces/RootState';
import { hasUserPermission, isUserLoggedIn } from '../../store/user/selectors';

export interface StateProps {
  hasUserPermission: boolean;
  userLoggedIn: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    hasUserPermission: hasUserPermission(state),
    userLoggedIn: isUserLoggedIn(state),
  };
};

export const connector = connect(mapStateToProps);
