import React, { ComponentType, ReactNode } from 'react';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import CheckIcon from '@material-ui/icons/Check';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';

import { Size } from '../enums/Size';
import { AnyFunction, AnyObject, CustomAny } from '../../../types/generics';
import { Column } from '../interfaces/Column';
import { Padding } from '../enums/Padding';
import { Align } from '../enums/Align';
import { ColumnType } from '../enums/ColumnType';
import { Color } from '../enums/Color';
import { Action } from '../interfaces/Action';
import TableSearch from '../tableSearch/TableSearch';
import { Variant } from '../../button/enums/Variant';
import TableActions from '../tableActions/TableActions';

export const generateOptions: AnyFunction = ({
  title,
  options = {},
  extendedSearchOptions = {},
}): MUIDataTableOptions => {
  return {
    elevation: 0,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: true,
    selectableRows: 'none',
    responsive: 'standard',
    setTableProps: (): AnyObject => ({
      size: Size.Small,
    }),
    ...options,
    setRowProps: (row: CustomAny[], rowIndex: number): AnyObject => {
      const isArchived = row[6];
      let optionalProps: AnyObject = {};
      if (typeof options.setRowProps === 'function') {
        optionalProps = options.setRowProps(row, rowIndex);
      }

      return {
        'data-value': row[0].props?.title || row[0],
        className: `${isArchived ? 'documents__archived-row' : ''}`,
        ...optionalProps,
      };
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options): CustomAny => {
      return (
        <TableSearch
          title={title}
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          extendedSearchOptions={extendedSearchOptions}
        />
      );
    },
    setFilterChipProps: (): AnyObject => {
      return {
        color: Color.Primary,
        variant: Variant.Outlined,
        className: 'filter-chip',
      };
    },
  };
};

export const generateColumns = (columns: Column[]): MUIDataTableColumn[] =>
  columns.map((column) => {
    const staticOptions = {
      sort: column.sort !== false,
      sortName: column.sortName || column.field,
      sortCompare: column.sortCompare,
      filter: column.filter,
      filterType: column.filterType,
      filterName: column.filterName || column.field,
      display: column.display || 'true',
      searchable: column.searchable !== false,
      customFilterListOptions: column.customFilterListOptions || {
        render: (value: CustomAny): string => `${column.title}: ${value}`,
      },
      filterOptions: { ...column.filterOptions, fullWidth: true } || { fullWidth: true },
      setCellProps: (value: CustomAny): CustomAny => ({
        padding: column.padding || Padding.Default,
        align: column.align || Align.Inherit,
        title: ['string', 'number'].includes(typeof value) ? value : undefined,
      }),
    };
    const newColumn = {
      name: column.field,
      label: column.title,
      options: { ...staticOptions },
    };
    switch (column.type) {
      case ColumnType.Enum:
        return {
          ...newColumn,
          options: {
            ...staticOptions,
            customBodyRender: (value): ComponentType[] =>
              value.map((el: CustomAny, index: number) => (
                <Chip className="table-cell__chip" size={Size.Small} label={el} key={index} color={Color.Primary} />
              )),
          },
        };
      case ColumnType.Boolean:
        return {
          ...newColumn,
          options: {
            ...staticOptions,
            customBodyRender: (value: CustomAny): CustomAny =>
              value ? <CheckIcon className="table-cell__icon" /> : '',
            setCellProps: (): CustomAny => ({
              padding: Padding.Checkbox,
              align: Align.Center,
            }),
          },
        };
      case ColumnType.LongString:
        return {
          ...newColumn,
          options: {
            ...staticOptions,
            customBodyRender: (value): ReactNode => (
              <div className="table-cell__long-string" title={value}>
                {value}
              </div>
            ),
          },
        };
      case ColumnType.ClickableString:
        return {
          ...newColumn,
          options: {
            ...staticOptions,
            customBodyRender: (value, tableMeta: CustomAny): ReactNode => (
              <div
                className="table-cell__clickable"
                title={value}
                onClick={(): void => column.onClick?.(tableMeta.currentTableData[tableMeta.rowIndex].index)}
              >
                {value}
              </div>
            ),
          },
        };
      default:
        if (column.getValue) {
          return {
            ...newColumn,
            options: {
              ...staticOptions,
              customBodyRender: (value, tableMeta): ReactNode => column.getValue?.(value, tableMeta),
            },
          };
        }

        return newColumn;
    }
  });

export const generateActions = (actions: Action[], data: AnyObject[], idField = 'id'): MUIDataTableColumn => {
  return {
    name: 'id',
    label: ' ',
    options: {
      sort: false,
      filter: false,
      searchable: false,
      customBodyRender: (id, tableMeta): ReactNode => (
        <TableActions data={data} actions={actions} id={id} tableMeta={tableMeta} idField={idField} />
      ),
      setCellProps: (): CustomAny => ({
        padding: Padding.Checkbox,
      }),
    },
  };
};

export const getFilterParams = (filterList: CustomAny[], columns: AnyObject[]): AnyObject => {
  const params: AnyObject = {};
  filterList.forEach((fList, index) => {
    if (_.size(fList)) {
      params[columns[index].options.filterName] = fList.join(',');
    }
  });
  return params;
};

export const getSortParams = (changedColumn: string, direction: string, columns: AnyObject[]): AnyObject => {
  const column = _.find(columns, { name: changedColumn });
  return {
    fieldToSort: column?.options.sortName || changedColumn,
    ascended: direction === 'asc',
  };
};
