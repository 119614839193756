import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import Toolbar from '@material-ui/core/Toolbar';

import { connector, DispatchProps, StateProps } from './connector';
import { Nullable } from '../../shared/types/generics';
import { TemplatesTreeNode } from '../../store/types/TemplatesTreeNode';
import { Align } from '../../shared/layouts/primarySection/enums/Align';
import { Align as TitleAlign } from '../../shared/components/title/enums/Align';
import { buildTemplatesTree, buildDirectoriesList, isFolder } from './helpers/treeHelper';
import { EXPANDED_TEMPLATES_KEY } from '../../shared/constants/application';
import { FolderFormFields } from './folderFormDialog/FolderFormFields';
import PrimarySection from '../../shared/layouts/primarySection/PrimarySection';
import Tree from '../../shared/components/tree/Tree';
import Paper from '../../shared/components/paper/Paper';
import TemplateFormDialog from './templateFormDialog/TemplateFormDialog';
import EditTemplateFormDialog from './editTemplateFormDialog/EditTemplateFormDialog';
import FolderFormDialog from './folderFormDialog/FolderFormDialog';
import RemoveDialog from '../../shared/components/dialog/RemoveDialog';
import Title from '../../shared/components/title/Title';
import AddMenu from './addMenu/AddMenu';

import './Templates.scss';

interface TemplatesProps extends StateProps, DispatchProps {}

const Templates: FC<TemplatesProps> = (props) => {
  const { editNode, addNode, addPolicyRules } = props;
  const [expandedNodes, setExpandedNodes] = useLocalStorage(EXPANDED_TEMPLATES_KEY, [] as string[]);
  const [openFolderDialog, setOpenFolderDialog] = useState(false);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [openEditTemplateDialog, setOpenEditTemplateDialog] = useState(false);
  const [selectedNode, setSelectedNode] = useState<Nullable<TemplatesTreeNode>>(null);
  const [selectedParent, setSelectedParent] = useState<Nullable<string>>(null);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const handleNodeToggle = useCallback(
    (e, items) => {
      setExpandedNodes(items);
    },
    [setExpandedNodes],
  );
  const expandNode = useCallback(
    (id) => {
      if (!(expandedNodes as string[]).includes(id.toString())) {
        setExpandedNodes([...(expandedNodes as string[]), id.toString()]);
      }
    },
    [expandedNodes, setExpandedNodes],
  );
  const handleOpenFolderDialog = useCallback(({ folder = null, parent = null }) => {
    setSelectedNode(folder);
    setSelectedParent(parent);
    setOpenFolderDialog(true);
  }, []);

  const handleOpenTemplateDialog = useCallback(({ template = null, parent = null }) => {
    setSelectedNode(template);
    setSelectedParent(parent);
    setOpenTemplateDialog(true);
  }, []);

  const handleOpenEditTemplateDialog = useCallback(({ template = null, parent = null }) => {
    setSelectedNode(template);
    setSelectedParent(parent);
    setOpenEditTemplateDialog(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenFolderDialog(false);
    setOpenTemplateDialog(false);
  }, []);

  const handleCloseEditDialog = useCallback(() => {
    setOpenFolderDialog(false);
    setOpenEditTemplateDialog(false);
  }, []);

  const handleOpenRemoveDialog = useCallback((node) => {
    setSelectedNode(node);
    setOpenRemoveDialog(true);
  }, []);

  const handleCloseRemoveDialog = useCallback(() => {
    setOpenRemoveDialog(false);
  }, []);

  const handleFolderSubmit = useCallback(
    async (formData) => {
      if (selectedNode?.id) {
        await editNode({ ...selectedNode, ...formData }, false);
        return handleCloseDialog();
      }
      await addNode(formData, false);
      if (formData[FolderFormFields.Parent]) {
        expandNode(formData[FolderFormFields.Parent]);
      }
      return handleCloseDialog();
    },
    [addNode, editNode, expandNode, handleCloseDialog, selectedNode],
  );

  const handleTemplateSubmit = useCallback(
    async (formData) => {
      if (selectedNode?.id) {
        await editNode({ ...selectedNode, ...formData }, true);
        return handleCloseDialog();
      }
      await addNode(formData, true);
      if (formData[FolderFormFields.Parent]) {
        expandNode(formData[FolderFormFields.Parent]);
      }
      return handleCloseDialog();
    },
    [expandNode, handleCloseDialog, editNode, selectedNode, addNode],
  );

  const handleTemplateEdit = useCallback(
    async (name, parentId, updateDocuments, policyArray) => {
      if (selectedNode?.id) {
        await editNode({ ...selectedNode, name, parentId }, true);
        if (policyArray) {
          await addPolicyRules(selectedNode.id, updateDocuments, policyArray);
        }
        return handleCloseEditDialog();
      }
      return handleCloseEditDialog();
    },
    [selectedNode, handleCloseEditDialog, editNode, addPolicyRules],
  );

  const handleRemove = useCallback(() => {
    return props.removeNode(selectedNode?.id, !isFolder(selectedNode));
  }, [props, selectedNode]);

  const downloadTemplate = useCallback((node) => props.downloadTemplate(node.id, node.name), [props]);

  useEffect(
    useCallback(() => {
      props.getTree();
    }, [props]),
    [],
  );
  const structure = useMemo(
    () =>
      buildTemplatesTree(
        props.tree,
        handleOpenEditTemplateDialog,
        handleOpenFolderDialog,
        handleOpenRemoveDialog,
        downloadTemplate,
      ),
    [downloadTemplate, handleOpenEditTemplateDialog, handleOpenFolderDialog, handleOpenRemoveDialog, props.tree],
  );
  const directoriesList = useMemo(
    () => [
      { label: 'No parent', value: undefined, style: { fontStyle: 'italic' } },
      ...buildDirectoriesList(props.tree, selectedNode),
    ],
    [props.tree, selectedNode],
  );

  return (
    <PrimarySection className="templates" verticalAlign={Align.Start} fullWidth processing={props.processing}>
      <Paper elevation={0}>
        <Toolbar disableGutters>
          <AddMenu openFolderDialog={handleOpenFolderDialog} openTemplateDialog={handleOpenTemplateDialog} />
        </Toolbar>
        {structure.length ? (
          <Tree structure={structure} onNodeToggle={handleNodeToggle} expanded={expandedNodes as []} />
        ) : (
          <Title align={TitleAlign.Center} className="templates__empty-placeholder">
            Templates tree is empty
          </Title>
        )}
      </Paper>
      <FolderFormDialog
        onSubmit={handleFolderSubmit}
        open={openFolderDialog}
        onClose={handleCloseDialog}
        folder={selectedNode}
        parentId={selectedParent}
        directories={directoriesList}
      />
      <TemplateFormDialog
        onSubmit={handleTemplateSubmit}
        open={openTemplateDialog}
        onClose={handleCloseDialog}
        parentId={selectedParent}
        directories={directoriesList}
      />
      <EditTemplateFormDialog
        onSubmit={handleTemplateEdit}
        open={openEditTemplateDialog}
        onClose={handleCloseEditDialog}
        template={selectedNode}
        getStatus={props.getTemplateStatus}
        getTemplateFields={props.getTemplateFields}
        parentId={selectedParent}
        directories={directoriesList}
      />
      <RemoveDialog
        open={openRemoveDialog}
        onClose={handleCloseRemoveDialog}
        onSubmit={handleRemove}
        type={isFolder(selectedNode) ? 'folder' : 'template'}
        name={selectedNode?.name}
      />
    </PrimarySection>
  );
};

export default connector(Templates);
