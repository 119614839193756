import { connect } from 'react-redux';

import { AnyDispatch, CustomAny } from '../../../shared/types/generics';
import { addUser, getUsers, removeUser, updateUser } from '../../../store/users/actions';
import { UserState } from '../../../store/interfaces/UserState';
import { RootState } from '../../../store/interfaces/RootState';
import { getUsersList } from '../../../store/users/selectors';
import { isProcessingPath } from '../../../store/requestsProcessing/selectors';
import { UsersPaths } from '../../../shared/api/users/UsersPaths';
import { UserFormData } from './userFormDialog/UserFormData';
import { getUserId } from '../../../store/user/selectors';

export interface StateProps {
  users: UserState[];
  activeUserId: number;
  processing: boolean;
}

export interface DispatchProps {
  getUsers: () => void;
  onEditUser: (id: number, formData: UserFormData) => void;
  onAddUser: (formData: UserFormData) => void;
  onRemoveUser: (id: number) => void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    users: getUsersList(state),
    activeUserId: getUserId(state),
    processing: isProcessingPath(state)(UsersPaths.Admin),
  };
};

const mapDispatchToProps = (dispatch: AnyDispatch): DispatchProps => {
  return {
    getUsers: (): Promise<CustomAny> => dispatch(getUsers()),
    onEditUser: (id, formData): Promise<CustomAny> => dispatch(updateUser(id, formData)),
    onAddUser: (formData): Promise<CustomAny> => dispatch(addUser(formData)),
    onRemoveUser: (id): Promise<CustomAny> => dispatch(removeUser(id)),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
