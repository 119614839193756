import { combineReducers } from 'redux';

import user from './user/reducer';
import metadata from './metadata/reducer';
import requestProcessing from './requestsProcessing/reducer';
import users from './users/reducer';
import states from './states/reducer';
import templates from './templates/reducer';
import documents from './documents/reducer';
import folders from './folders/reducer';
import logs from './logs/reducer';

export default combineReducers({
  user,
  metadata,
  requestProcessing,
  users,
  states,
  templates,
  documents,
  logs,
  folders,
});
