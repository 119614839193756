import React, { FC } from 'react';

import Page from './shared/layouts/page/Page';
import HeaderProvider from './shared/layouts/headerProvider/HeaderProvider';
import AppRouter from './routes/AppRouter';

import './App.scss';

const App: FC = () => {
  return (
    <section className="docs-stream-app">
      <HeaderProvider>
        <Page>
          <AppRouter />
        </Page>
      </HeaderProvider>
    </section>
  );
};

export default App;
