import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

import FormGroup from '../../../../shared/components/form/formGroup/FormGroup';
import InputControl from '../../../../shared/components/form/controls/input/InputControl';
import { DocumentFormFields } from '../../documentFormDialog/DocumentFormFields';
import SelectControl from '../../../../shared/components/form/controls/select/SelectControl';
import Select from '../../../../shared/components/form/controls/select/Select';
import Title from '../../../../shared/components/title/Title';
import TagList from '../../documentFormDialog/tagList/TagList';
import { Variants } from '../../../../shared/components/title/enums/Variants';
import MetadataFields from '../../metadataFields/MetadataFields';
import TabContent from '../../../../shared/components/tabs/tabContent/TabContent';
import { DocumentState } from '../../../../store/interfaces/DocumentState';
import { UserState } from '../../../../store/interfaces/UserState';
import { buildStatesList } from '../../../templates/helpers/treeHelper';
import { StatesState } from '../../../../store/interfaces/StatesState';
import { MetadataState } from '../../../../store/interfaces/MetadataState';
import { AnyFunction, AnyObject, Nullable } from '../../../../shared/types/generics';

interface GeneralFormTabProps {
  document: DocumentState;
  userId: Nullable<number>;
  userList: UserState[];
  stateList: StatesState[];
  isDocumentProcessing: boolean;
  metadataFields: MetadataState[];
  tags: string[];
  commentToMoveState: string;
  setMoveTo: AnyFunction;
  moveTo: Nullable<AnyObject>;
  setMoveDialogOpened: AnyFunction;
  onTagsChange: AnyFunction;
  onUserChange: AnyFunction;
  updateSwipeableViewHeight(): void;
}

const GeneralFormTab: FC<GeneralFormTabProps> = (props) => {
  const userOptions = useMemo(() => props.userList.map((user) => ({ value: user.id, label: user.login })), [
    props.userList,
  ]);
  const stateOptions = useMemo(() => buildStatesList(props.stateList), [props.stateList]);
  const tagItems = useMemo(() => props.tags.map((tag) => ({ value: tag })), [props.tags]);
  const [metadataObject, setMetadataObject] = useState<AnyObject>(props.document?.documentMetadata || {});
  const templateOptions = useMemo(
    () => (props.document ? [{ value: props.document.templateFile.id, label: props.document.templateFile.name }] : []),
    [props.document],
  );

  const handleStateChange = useCallback(
    (event) => {
      props.setMoveTo(_.find(props.stateList, { id: event.target.value }));
      props.setMoveDialogOpened(true);
    },
    [props],
  );

  const handleUserChange = useCallback((event) => props.onUserChange(event.target.value), [props]);

  useEffect(() => {
    setMetadataObject(props.document?.documentMetadata || {});
  }, [props.document]);

  return (
    <TabContent>
      <FormGroup doubleColumn>
        <InputControl
          name={DocumentFormFields.Name}
          label="Name"
          required
          defaultValue={props.document?.name}
          fullWidth
          autoFocus
        />
        <SelectControl
          name={DocumentFormFields.Template}
          label="Template"
          required
          options={templateOptions}
          value={(templateOptions.length && props.document?.templateFile?.id) || ''}
          disabled
          fullWidth
        />
      </FormGroup>
      <FormGroup doubleColumn>
        <Select
          name={DocumentFormFields.User}
          label="Assign to"
          required
          options={userOptions}
          fullWidth
          value={props.userId || ''}
          onChange={handleUserChange}
        />
        <Select
          name={DocumentFormFields.State}
          label="State"
          helperText={props.commentToMoveState}
          required
          options={stateOptions}
          fullWidth
          value={props.moveTo?.id || props.document?.stateDescriptor.id}
          disabled={props.isDocumentProcessing}
          onChange={handleStateChange}
        />
      </FormGroup>
      <FormGroup>
        <TagList
          tagList={tagItems}
          onChange={(tagList): void => {
            props.onTagsChange(tagList);
            props.updateSwipeableViewHeight();
          }}
        />
      </FormGroup>
      {_.size(props.metadataFields) > 0 && (
        <FormGroup>
          <section className="metadata">
            <Title variant={Variants.H6}>Metadata</Title>
            <MetadataFields values={metadataObject} fields={props.metadataFields} />
          </section>
        </FormGroup>
      )}
    </TabContent>
  );
};

export default GeneralFormTab;
