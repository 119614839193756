import React, { useMemo, FC } from 'react';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';

import Button, { ButtonProps } from './Button';
import CircularLoading from '../circularLoading/CircularLoading';
import { Color as LoadingColor } from '../circularLoading/enums/Color';

import './ButtonSubmit.scss';

interface ButtonSubmitProps extends ButtonProps {
  processing?: boolean;
}

const ButtonSubmit: FC<ButtonSubmitProps> = (props) => {
  const { formState, errors } = useFormContext();
  const { isSubmitting } = formState;
  const loadingColor = useMemo(
    () =>
      props.color && Object.values(LoadingColor).includes(props.color as LoadingColor)
        ? (props.color as LoadingColor)
        : LoadingColor.Inherit,
    [props.color],
  );

  return (
    <Button
      submit
      variant={props.variant}
      color={props.color}
      disabled={isSubmitting || props.processing || _.keys(errors).filter((key) => key !== 'form').length > 0}
      className="button-submit"
    >
      {props.children}
      {(isSubmitting || props.processing) && <CircularLoading size={24} color={loadingColor} />}
    </Button>
  );
};

export default ButtonSubmit;
