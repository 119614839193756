import { connect } from 'react-redux';
import { Action } from 'redux-actions';

import { RootState } from '../../store/interfaces/RootState';
import { StatesState } from '../../store/interfaces/StatesState';
import { AnyDispatch, CustomAny } from '../../shared/types/generics';
import { DocumentsTableState } from '../../store/interfaces/DocumentsTableState';
import { getDocumentsList } from '../../store/documents/actions';
import { getStates } from '../../store/states/selectors';
import { getStatesList } from '../../store/states/actions';
import { StatesPaths } from '../../shared/api/states/StatesPaths';
import { isProcessingPath } from '../../store/requestsProcessing/selectors';
import { finishProcessingPath } from '../../store/requestsProcessing/actions';
import { Path } from '../../store/types/Path';

export interface DispatchProps {
  getStatesList(): void;
  onLeaveTab(): void;
}

export interface StateProps {
  states: StatesState[];
  processingStates: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    states: getStates(state),
    processingStates: isProcessingPath(state)(StatesPaths.User),
  };
};

const mapDispatchToProps = (dispatch: AnyDispatch): DispatchProps => {
  return {
    onLeaveTab: (): Action<Path> => dispatch(finishProcessingPath(StatesPaths.User)),
    getStatesList: (): Promise<CustomAny> => dispatch(getStatesList(StatesPaths.User)),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
