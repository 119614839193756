export enum DocumentPolicyFieldRules {
  EQUALS = 'equals',
  NOT_EQUALS = 'notEquals',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'notContains',
  REG_EXP = 'regexp',
  MIN = 'min',
  MAX = 'max',
  BEFORE = 'before',
  AFTER = 'after',
}
