import React, { ReactElement } from 'react';
import moment from 'moment';

import { Column } from '../../../shared/components/table/interfaces/Column';
import { DocumentState } from '../../../store/interfaces/DocumentState';
import { CustomAny } from '../../../shared/types/generics';
import { DEFAULT_DATE_TIME_FORMAT, DEFAULT_DATE_FORMAT } from '../../../shared/constants/application';
import { UserState } from '../../../store/interfaces/UserState';
import DateTimeInput from '../../../shared/components/form/controls/datePicker/DateTimeInput';

export const normalizeOperationType = (value: string): string => value.replace(/ /g, '_').toUpperCase();

export const prettifyOperationType = (value: string): string => {
  const newOpType = value.replace(/_/g, ' ').trim();
  return newOpType.charAt(0) + newOpType.slice(1).toLowerCase();
};

export const LOGS_COLUMNS: Column[] = [
  {
    title: 'Document',
    field: 'document',
    sort: true,
    sortName: 'document.name',
    getValue: (value: DocumentState): string => value?.name || '',
  },
  {
    title: 'Template',
    field: 'document.templateFile.name',
    sort: true,
    getValue: (value, tableMeta): string => tableMeta.rowData[0]?.templateFile?.name || '',
  },
  {
    title: 'Operation',
    field: 'type',
    sort: true,
    filter: true,
    filterName: 'logTypeFilter',
    getValue: prettifyOperationType,
  },
  {
    title: 'DateTime',
    field: 'createdAt',
    sort: true,
    filter: true,
    filterType: 'custom',
    filterName: 'dateFilter',
    customFilterListOptions: {
      render: (value: CustomAny): string => `Created on: ${moment(new Date(value)).format(DEFAULT_DATE_FORMAT)}`,
    },
    filterOptions: {
      display: (filterList, onChange, index, column): ReactElement => {
        return (
          <DateTimeInput
            fullWidth
            disableKeyboard
            label="Date"
            value={filterList[index][0] ? moment(filterList[index][0]).toDate() : null}
            onChange={(value: string): void => {
              (filterList[index] as CustomAny) = [moment.utc(value).format()];
              onChange(filterList[index], index, column);
            }}
          />
        );
      },
    },
    getValue: (value: CustomAny): string => moment(new Date(value)).format(DEFAULT_DATE_TIME_FORMAT),
  },
  {
    title: 'User',
    field: 'user',
    sort: true,
    sortName: 'user.login',
    filter: true,
    filterName: 'userFilter',
    getValue: (value: UserState): string => value?.login || '',
  },
  {
    title: 'Description',
    field: 'description',
    sort: false,
  },
];
