import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import _ from 'lodash';

import PrimarySection from '../../shared/layouts/primarySection/PrimarySection';
import ServerTable from '../../shared/components/table/ServerTable';
import { Align } from '../../shared/layouts/primarySection/enums/Align';
import { connector, DispatchProps, StateProps } from './connector';
import { LOGS_COLUMNS, prettifyOperationType } from './constants/table';
import { TABLE_CONF_PREFIX } from '../../shared/constants/application';
import { TableConf } from '../../shared/components/table/interfaces/TableConf';

import './Logs.scss';

interface LogsProps extends StateProps, DispatchProps {}

export const Logs: FC<LogsProps> = (props) => {
  const [tableConf, setTableConf] = useLocalStorage<TableConf>(`${TABLE_CONF_PREFIX}logs`, {});
  const sortOrder = useMemo(() => {
    if (tableConf?.fieldToSort) {
      return {
        fieldToSort: tableConf.fieldToSort,
        ascended: !!tableConf.ascended,
      };
    }

    return {
      fieldToSort: 'createdAt',
      ascended: false,
    };
  }, [tableConf]);
  const handleRowPerPageChange = useCallback(
    (value: number) => {
      setTableConf({
        ...tableConf,
        rowsPerPage: value,
      });
    },
    [setTableConf, tableConf],
  );
  const handleSortChange = useCallback(
    ({ ascended, fieldToSort }) => {
      setTableConf({
        ...tableConf,
        ascended,
        fieldToSort,
      });
    },
    [setTableConf, tableConf],
  );
  useEffect(
    useCallback(() => {
      props.getUsers();
      props.getOperations();
    }, [props]),
    [],
  );

  LOGS_COLUMNS[_.findIndex(LOGS_COLUMNS, { field: 'type' })].filterOptions = {
    names: [...(props.logs.operations?.map(prettifyOperationType) || [])],
  };
  LOGS_COLUMNS[_.findIndex(LOGS_COLUMNS, { field: 'user' })].filterOptions = {
    names: [...props.users.map(({ login }) => login)],
  };
  return (
    <PrimarySection align={Align.Start} className="app-logs" processing={props.processing}>
      <ServerTable
        columns={[...LOGS_COLUMNS]}
        count={props.logs.totalElements}
        data={props.logs.content}
        getData={props.getLogs}
        textLabels={{
          toolbar: { filterTable: 'Filter logs' },
          body: {
            noMatch: 'No logs',
          },
        }}
        filter
        searchName="searchFilter"
        search
        totalPages={props.logs.totalPages}
        currentPageIndex={props.logs.number}
        rowsPerPage={(tableConf as TableConf).rowsPerPage}
        onRowPerPage={handleRowPerPageChange}
        sortOrder={sortOrder}
        onSort={handleSortChange}
      />
    </PrimarySection>
  );
};

export default connector(Logs);
