import React, { FC } from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';

import IconButton from '../../iconButton/IconButton';
import { Size } from '../../iconButton/enums/Size';
import { Edge } from '../../iconButton/enums/Edge';
import { Item } from '../interfaces/Item';

interface ItemContentProps {
  item: Item;
}

const ItemContent: FC<ItemContentProps> = (props) => {
  return (
    <ListItem className="list__item" key={1} button dense onClick={props.item.onItemClick}>
      <ListItemText primary={props.item.label} />
      <ListItemSecondaryAction data-value={props.item.name} className="item-actions">
        {props.item.actions?.map((action) => (
          <IconButton
            key={action.label}
            size={Size.Small}
            edge={Edge.End}
            ariaLabel={action.label}
            onClick={action.onClick}
            disabled={action.isDisabled?.()}
          >
            <action.icon />
          </IconButton>
        ))}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ItemContent;
