import { deleteRequest, getRequest, postRequest, putRequest } from '../api';
import { MetadataPaths } from './MetadataPaths';
import { MetadataState } from '../../../store/interfaces/MetadataState';
import { CustomAny } from '../../types/generics';
import { MetadataFormData } from '../../../pages/admin/metadata/metadataFormDialog/MetadataFormData';

export const get = (url = MetadataPaths.Admin): Promise<MetadataState[]> => getRequest(url);

export const add = (field: MetadataFormData): Promise<CustomAny> => postRequest(MetadataPaths.Admin, field);

export const update = (id: number, field: MetadataFormData): Promise<CustomAny> =>
  putRequest(`${MetadataPaths.Admin}/${id}`, field);

export const remove = (id: number): Promise<CustomAny> => deleteRequest(`${MetadataPaths.Admin}/${id}`);
