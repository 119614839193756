import React, { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Color } from './enums/Color';

interface CircularLoadingProps {
  size?: number;
  color?: Color;
}

const CircularLoading: FC<CircularLoadingProps> = (props) => {
  return <CircularProgress className="loading-circular" size={props.size} color={props.color} />;
};

export default CircularLoading;
