import { createAction } from 'redux-actions';
import moment from 'moment';

import { AnyDispatch, AnyObject, CustomAny } from '../../shared/types/generics';
import { finishProcessingPath, startProcessingPath } from '../requestsProcessing/actions';
import * as templatesService from '../../shared/api/templates/TemplatesApiService';
import { TemplatesPaths } from '../../shared/api/templates/TemplatesPaths';
import { TemplatesTreeNode } from '../types/TemplatesTreeNode';
import { FolderState } from '../interfaces/FolderState';
import { TemplateState } from '../interfaces/TemplateState';
import { DocumentSubFieldType } from '../enums/DocumentSubFieldType';
import { DocumentFieldRule } from '../interfaces/DocumentFieldRule';
import { DEFAULT_DATE_FORMAT } from '../../shared/constants/application';

const setTemplatesTree = createAction('TEMPLATES_TREE_SET');

interface DocumentFieldExtension extends DocumentFieldRule {
  value: string | number;
}

interface PolicyDataObject {
  stringPolicyMap: AnyObject;
  decimalPolicyMap: AnyObject;
  intPolicyMap: AnyObject;
  datePolicyMap: AnyObject;
  templateFieldOrder: string[];
  updateDocuments: boolean;
}

export const getTemplatesTree = (url = TemplatesPaths.Tree) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(url));
  try {
    const tree = await templatesService.getTree(url);
    dispatch(setTemplatesTree(tree));
  } finally {
    dispatch(finishProcessingPath(url));
  }
};

export const addTemplatesNode = (node: TemplatesTreeNode, isTemplate: boolean) => async (
  dispatch: AnyDispatch,
): Promise<CustomAny> => {
  dispatch(startProcessingPath(TemplatesPaths.Tree));
  try {
    let tree;
    if (isTemplate) {
      const nodeData = {
        name: node.name,
        parentId: node.parentId,
      };
      tree = await templatesService.addTemplate(nodeData as TemplateState, (node as TemplateState).file || []);
    } else {
      delete (node as TemplateState).file;
      tree = await templatesService.addFolder(node as FolderState);
    }
    dispatch(setTemplatesTree(tree));
  } finally {
    dispatch(finishProcessingPath(TemplatesPaths.Tree));
  }
};

export const editTemplatesNode = (node: TemplatesTreeNode, isTemplate: boolean) => async (
  dispatch: AnyDispatch,
): Promise<CustomAny> => {
  dispatch(startProcessingPath(TemplatesPaths.Tree));
  try {
    let tree;
    const nodeData = {
      name: node.name,
      parentId: node.parentId,
    };
    if (isTemplate) {
      tree = await templatesService.updateTemplate(node.id, nodeData as TemplateState);
    } else {
      tree = await templatesService.updateFolder(node.id, nodeData as FolderState);
    }
    dispatch(setTemplatesTree(tree));
  } finally {
    dispatch(finishProcessingPath(TemplatesPaths.Tree));
  }
};

export const addPolicyRules = (
  id: string,
  updateDocuments: boolean,
  policyArray: Partial<DocumentFieldExtension>[],
) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(TemplatesPaths.Templates));
  try {
    const policyDataObject: PolicyDataObject = {
      stringPolicyMap: {},
      decimalPolicyMap: {},
      intPolicyMap: {},
      datePolicyMap: {},
      templateFieldOrder: [],
      updateDocuments: updateDocuments,
    };

    policyArray.forEach((element) => {
      const name = element.name;
      const rule = element.rule;

      policyDataObject.templateFieldOrder.push(name as string);

      switch (element.subtype) {
        case DocumentSubFieldType.STRING:
          policyDataObject.stringPolicyMap[name as string] = {
            [rule as string]: element.value,
          };
          break;
        case DocumentSubFieldType.INTEGER:
          policyDataObject.intPolicyMap[name as string] = {
            [rule as string]: element.value,
          };
          break;
        case DocumentSubFieldType.DECIMAL:
          policyDataObject.decimalPolicyMap[name as string] = {
            [rule as string]: element.value,
          };
          break;
        case DocumentSubFieldType.DATE:
          policyDataObject.datePolicyMap[name as string] = {
            [rule as string]: moment(element.value).format(DEFAULT_DATE_FORMAT),
          };
          break;
        default:
          break;
      }
    });
    await templatesService.addPolicyRules(id, policyDataObject);
  } finally {
    dispatch(finishProcessingPath(TemplatesPaths.Templates));
  }
};

export const removeTemplatesNode = (id: string, isTemplate: boolean) => async (
  dispatch: AnyDispatch,
): Promise<CustomAny> => {
  dispatch(startProcessingPath(TemplatesPaths.Tree));
  try {
    let tree;
    if (isTemplate) {
      tree = await templatesService.removeTemplate(id);
    } else {
      tree = await templatesService.removeFolder(id);
    }
    dispatch(setTemplatesTree(tree));
  } finally {
    dispatch(finishProcessingPath(TemplatesPaths.Tree));
  }
};

export const downloadTemplate = (id: string, fileName: string) => (): CustomAny =>
  templatesService.downloadTemplate(id, fileName);

export const getTemplateStatus = (id: string) => (): CustomAny => templatesService.getTemplateStatus(id);

export const getTemplateFields = (id: string) => (): CustomAny => templatesService.getTemplateFields(id);
