import React, { FC } from 'react';
import classNames from 'classnames';
import PaperMaterial from '@material-ui/core/Paper';

import './Paper.scss';

interface PaperProps {
  elevation?: number;
  padding?: boolean;
  className?: string;
}

const Paper: FC<PaperProps> = (props) => {
  const { padding = true } = props;

  return (
    <PaperMaterial
      square
      className={classNames('paper', { 'padding-0': !padding }, props.className)}
      elevation={props.elevation}
    >
      {props.children}
    </PaperMaterial>
  );
};

export default Paper;
