import React, { FC, useMemo } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';

import Widget from '../../../shared/widget/Widget';
import WidgetContent from './widgetContent/WidgetContent';
import { StatesState } from '../../../store/interfaces/StatesState';

interface QueueWidgetProps {
  states: StatesState[];
  processing: boolean;
}

const QueueWidget: FC<QueueWidgetProps> = (props) => {
  const filteredStates = useMemo(() => _.sortBy(props.states, 'order'), [props.states]);

  return (
    <Widget title="Documents queue">
      {props.processing ? (
        <div className="widget-content queue-widget-content">
          <Skeleton width="100%" height="80px" />
        </div>
      ) : (
        <WidgetContent states={filteredStates} />
      )}
    </Widget>
  );
};

export default QueueWidget;
