import React, { FC, ReactComponentElement } from 'react';
import { ResponsiveBar } from '@nivo/bar';

import { AnyObject, CustomAny } from '../../../../shared/types/generics';
import SecondaryText from '../../../../shared/components/secondaryText/SecondaryText';

interface AgingGraphProps {
  chartData: { data: AnyObject[]; keys: string[] };
}

const AgingGraph: FC<AgingGraphProps> = (props) => {
  return props.chartData.keys?.length ? (
    <section className="aging-graph">
      <ResponsiveBar
        data={props.chartData.data}
        keys={props.chartData.keys}
        indexBy="age"
        margin={{ top: 15, right: 120, bottom: 50, left: 50 }}
        padding={0.3}
        colors={{ scheme: 'paired' }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Age',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickValues: 1,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Value',
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            itemWidth: 90,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            translateY: 0,
            translateX: 100,
            itemsSpacing: 2,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        tooltip={({ id, value, color, indexValue }): ReactComponentElement<CustomAny> => (
          <section className="chart-tooltip">
            <strong style={{ color }}>{id}</strong>
            <p>
              Age range: <strong>{indexValue}</strong> day(s)
            </p>
            <p>
              <strong>{value}</strong> document(s)
            </p>
          </section>
        )}
      />
    </section>
  ) : (
    <SecondaryText>No documents</SecondaryText>
  );
};

export default AgingGraph;
