import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';

import IconButton from '../iconButton/IconButton';
import ChipInput from './chipInput/ChipInput';
import { ChipItem } from './interfaces/ChipItem';
import { Size } from '../iconButton/enums/Size';
import { AnyObject, Nullable } from '../../types/generics';

import './ChipList.scss';

interface ChipListProps {
  items: ChipItem[];
  onChange?(newItems: string[]): void;
  readonly?: boolean;
  className?: string;
  errorsArray?: AnyObject[];
}

const ChipList: FC<ChipListProps> = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [activeItem, setActiveItem] = useState<Nullable<AnyObject>>(null);
  const className = classNames('chip-list', props.className);
  const onEdit = useCallback(
    (index = -1) => (): void => {
      setEditMode(true);
      setActiveItem({
        index,
        value: index !== -1 ? props.items[index].value : '',
      });
    },
    [props.items],
  );
  const onCancel = useCallback(() => {
    setEditMode(false);
    setActiveItem(null);
  }, []);
  const onItemChange = useCallback(
    (e) => {
      setActiveItem({ ...activeItem, value: e.target.value });
    },
    [activeItem],
  );
  const onSave = useCallback(() => {
    const newItems = props.items.map((item) => item.value);
    if (activeItem.index > -1) {
      newItems[activeItem.index] = activeItem.value;
    } else {
      newItems.push(activeItem.value);
    }
    props.onChange?.(_.uniq(newItems));
    setEditMode(false);
    setActiveItem(null);
  }, [activeItem, props.items, props.onChange]);
  const onDelete = useCallback(
    (itemIndex) => (): void => {
      const newItems = props.items.map((item) => item.value);
      newItems.splice(itemIndex, 1);
      props.onChange?.(newItems);
      setEditMode(false);
      setActiveItem(null);
    },
    [props.items, props.onChange],
  );
  const isError = useCallback((tagIndex): boolean => !!props.errorsArray?.[tagIndex], [props.errorsArray]);

  return (
    <section className={className}>
      {props.items.map((item, index) =>
        props.readonly ? (
          <Chip label={item.value} key={index} size="small" className="chip-list__item" />
        ) : activeItem?.index === index ? (
          <ChipInput key={index} item={activeItem} onChange={onItemChange} onCancel={onCancel} onSave={onSave} />
        ) : (
          <Tooltip disableFocusListener disableTouchListener title={item.value || ''} enterDelay={500} key={index}>
            <Chip
              label={item.value}
              size="small"
              onDelete={onDelete(index)}
              onClick={onEdit(index)}
              className={classNames('chip-list__item', { _error: isError(index) })}
            />
          </Tooltip>
        ),
      )}
      {!props.readonly && !editMode && (
        <IconButton className="chip-list__item" size={Size.Small} onClick={onEdit()}>
          <AddCircleOutlineOutlinedIcon />
        </IconButton>
      )}
      {!props.readonly && editMode && activeItem.index === -1 && (
        <ChipInput item={activeItem} onChange={onItemChange} onCancel={onCancel} onSave={onSave} />
      )}
    </section>
  );
};

export default ChipList;
