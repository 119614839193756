import React, { FC, ReactNode } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { Divider } from '@material-ui/core';

import Paper from '../components/paper/Paper';
import PrimarySection from '../layouts/primarySection/PrimarySection';
import { Align } from '../layouts/primarySection/enums/Align';

import './Widget.scss';

interface WidgetProps {
  title?: ReactNode;
}

const Widget: FC<WidgetProps> = (props) => {
  return (
    <Paper className="widget" padding={false}>
      {props.title && (
        <>
          <Toolbar className="widget__title" variant="dense">
            {props.title}
          </Toolbar>
          <Divider />
        </>
      )}
      <PrimarySection ignoreHeader className="widget__content" verticalAlign={Align.Start}>
        {props.children}
      </PrimarySection>
    </Paper>
  );
};

export default Widget;
