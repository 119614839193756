import { deleteRequest, getRequest, postRequest, putRequest } from '../api';
import { UsersPaths } from './UsersPaths';
import { UserState } from '../../../store/interfaces/UserState';
import { UserFormData } from '../../../pages/admin/users/userFormDialog/UserFormData';

export const get = (url = UsersPaths.Admin): Promise<UserState[]> => getRequest(url);

export const update = (id: number, formData: UserFormData): Promise<UserState> =>
  putRequest(`${UsersPaths.Admin}/${id}`, formData);

export const add = (formData: UserFormData): Promise<UserState> => postRequest(UsersPaths.Admin, formData);

export const remove = (id: number): Promise<UserState> => deleteRequest(`${UsersPaths.Admin}/${id}`);
