import { connect } from 'react-redux';

import { RootState } from '../../../store/interfaces/RootState';
import { isUserLoggedIn } from '../../../store/user/selectors';

export interface StateProps {
  showHeader: boolean;
  showMenu: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  const userLoggedIn = isUserLoggedIn(state);
  return {
    showHeader: userLoggedIn,
    showMenu: userLoggedIn,
  };
};

export const connector = connect(mapStateToProps);
