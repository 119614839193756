import { connect } from 'react-redux';

import { AnyDispatch, CustomAny } from '../../../shared/types/generics';
import {
  getFoldersList,
  editHotfolder,
  addHotfolder,
  removeHotfolder,
  activateHotfolder,
  pauseHotfolder,
} from '../../../store/folders/actions';
import { getStatesList, getInvisibleState } from '../../../store/states/actions';
import { getUsers } from '../../../store/users/actions';
import { getTemplatesTree } from '../../../store/templates/actions';
import { getStates } from '../../../store/states/selectors';
import { getUsersList } from '../../../store/users/selectors';
import { RootState } from '../../../store/interfaces/RootState';
import { TemplatesTree } from '../../../store/interfaces/TemplatesTree';
import { isProcessingPath } from '../../../store/requestsProcessing/selectors';
import { TemplatesPaths } from '../../../shared/api/templates/TemplatesPaths';
import { HotfolderTableState } from '../../../store/interfaces/HotfolderTableState';
import { StatesState } from '../../../store/interfaces/StatesState';
import { HotfolderState } from '../../../store/interfaces/HotfolderState';
import { UserState } from '../../../store/interfaces/UserState';
import { getFolders } from '../../../store/folders/selectors';
import { getTree } from '../../../store/templates/selectors';
import { getUserId } from '../../../store/user/selectors';
import { FoldersPaths } from '../../../shared/api/folders/FoldersPaths';
import { StatesPaths } from '../../../shared/api/states/StatesPaths';
import { UsersPaths } from '../../../shared/api/users/UsersPaths';

export interface StateProps {
  hotfolders: HotfolderTableState;
  processing: boolean;
  activeUser: number;
  states: StatesState[];
  templates: TemplatesTree;
  users: UserState[];
}

export interface DispatchProps {
  getHotfoldersList: () => void;
  onEditHotfolder: (id: number, data: HotfolderState) => void;
  getInvisibleState(): Promise<StatesState>;
  onAddHotfolder: (data: HotfolderState) => void;
  onActivateHotfolder: (id: number) => Promise<CustomAny>;
  onPauseHotfolder: (id: number) => Promise<CustomAny>;
  onRemoveHotfolder: (id: number) => void;
  getStatesList(): void;
  getUsers(): void;
  getTemplates(): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    hotfolders: getFolders(state),
    processing:
      isProcessingPath(state)(FoldersPaths.Admin) ||
      isProcessingPath(state)(StatesPaths.User) ||
      isProcessingPath(state)(UsersPaths.User),
    states: getStates(state),
    users: getUsersList(state),
    templates: getTree(state),
    activeUser: getUserId(state),
  };
};

const mapDispatchToProps = (dispatch: AnyDispatch): DispatchProps => {
  return {
    getHotfoldersList: (): Promise<CustomAny> => dispatch(getFoldersList()),
    onEditHotfolder: (id, data): Promise<CustomAny> => dispatch(editHotfolder(id, data)),
    onAddHotfolder: (data): Promise<CustomAny> => dispatch(addHotfolder(data)),
    onRemoveHotfolder: (id): Promise<CustomAny> => dispatch(removeHotfolder(id)),
    getInvisibleState: (): Promise<StatesState> => dispatch(getInvisibleState()),
    onActivateHotfolder: (id): Promise<CustomAny> => dispatch(activateHotfolder(id)),
    onPauseHotfolder: (id): Promise<CustomAny> => dispatch(pauseHotfolder(id)),
    getStatesList: (): Promise<CustomAny> => dispatch(getStatesList(StatesPaths.User)),
    getTemplates: (): Promise<CustomAny> => dispatch(getTemplatesTree(TemplatesPaths.User)),
    getUsers: (): Promise<CustomAny> => dispatch(getUsers(UsersPaths.User)),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
