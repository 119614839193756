import React, { FC, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import _ from 'lodash';

import List from '../../../components/list/List';
import { Routes } from '../../../../routes/Routes';
import { AdminRoutes } from '../../../../routes/adminRouter/AdminRoutes';
import { ListObject } from '../../../components/list/interfaces/ListObject';
import { PAGE_TITLE } from '../../../constants/application';

const AdminMenu: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const adminMenuItems: ListObject[] = useMemo(
    () => [
      {
        icon: InsertDriveFileIcon,
        label: PAGE_TITLE[Routes.Templates],
        selected: location.pathname === Routes.Templates,
        onClick: (): void => history.push(Routes.Templates),
      },
      {
        icon: SettingsIcon,
        label: PAGE_TITLE[Routes.Admin],
        selected:
          location.pathname === Routes.Admin || _.values(AdminRoutes).includes(location.pathname as AdminRoutes),
        onClick: (): void => history.push(Routes.Admin),
      },
    ],
    [history, location.pathname],
  );

  return <List listObject={adminMenuItems} />;
};

export default AdminMenu;
