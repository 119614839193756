import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import ArchiveIcon from '@material-ui/icons/Archive';
import GetAppIcon from '@material-ui/icons/GetApp';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { useLocalStorage } from 'react-use';
import _ from 'lodash';

import PrimarySection from '../../shared/layouts/primarySection/PrimarySection';
import ServerTable from '../../shared/components/table/ServerTable';
import DocumentFormDialog from './documentFormDialog/DocumentFormDialog';
import EditDocumentFormDialogProps from './editDocumentFormDialog/EditDocumentFormDialog';
import CustomActionDialog from '../../shared/components/dialog/CustomActionDialog';
import Select from '../../shared/components/form/controls/select/Select';
import { connector, DispatchProps, StateProps } from './connector';
import { Entity } from '../../shared/enums/Entity/Entity';
import { DOCUMENTS_COLUMNS } from './constants/table';
import { Align } from '../../shared/layouts/primarySection/enums/Align';
import { AnyObject, CustomAny, Nullable } from '../../shared/types/generics';
import { DocumentState } from '../../store/interfaces/DocumentState';
import { StatesState } from '../../store/interfaces/StatesState';
import { Action } from '../../shared/components/table/interfaces/Action';
import { ExtendedSearchAction } from '../../shared/components/table/interfaces/ExtendedSearchAction';
import { TableAction } from '../../shared/components/table/enums/TableAction';
import { updateDocument } from './helpers/documentHelper';
import { TABLE_CONF_PREFIX } from '../../shared/constants/application';
import { TableConf } from '../../shared/components/table/interfaces/TableConf';
import { IS_CONTENT_SEARCH, IS_METADATA_SEARCH, IS_PARSING_SEARCH } from '../../shared/constants/application';

import './Documents.scss';

interface DocumentsProps extends StateProps, DispatchProps {}

const Documents: FC<DocumentsProps> = (props) => {
  const [tableConf, setTableConf] = useLocalStorage<TableConf>(`${TABLE_CONF_PREFIX}${Entity.Document}`, {});
  const [isContentSearch, setIsContentSearch] = useLocalStorage<boolean>(IS_CONTENT_SEARCH, true);
  const [isMetadataSearch, setIsMetadataSearch] = useLocalStorage<boolean>(IS_METADATA_SEARCH, false);
  const [isParsingSearch, setIsParsingSearch] = useLocalStorage<boolean>(IS_PARSING_SEARCH, false);
  const [openDocumentDialog, setOpenDocumentDialog] = useState(false);
  const [openNewDocumentDialog, setOpenNewDocumentDialog] = useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [openUnarchiveDialog, setOpenUnarchivedDialog] = useState(false);
  const [invisibleState, setInvisibleState] = useState<StatesState>();
  const [selectedDocument, setSelectedDocument] = useState<Nullable<DocumentState>>(null);
  const [filterParams, setFilterParams] = useState({});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const sortOrder = useMemo(() => {
    if (tableConf?.fieldToSort) {
      return {
        fieldToSort: tableConf.fieldToSort,
        ascended: !!tableConf.ascended,
      };
    }

    return undefined;
  }, [tableConf]);

  const handleAddButtonClick = useCallback(() => {
    setSelectedDocument(null);
    setOpenNewDocumentDialog(true);
  }, []);

  const handleCloseFormDialog = useCallback(async () => {
    setOpenDocumentDialog(false);
    setOpenNewDocumentDialog(false);
  }, []);

  const handleEditButtonClick = useCallback((document) => {
    setOpenDocumentDialog(true);
    setSelectedDocument(document);
  }, []);

  const handleCloseErrorDialog = useCallback(() => {
    setErrorMessage('');
  }, []);

  const handleDownloadButtonClick = useCallback((document, version?) => props.download(document, version), [props]);

  const handleSubmitFormDialog = useCallback(
    (formData) => {
      if (selectedDocument) {
        return props.onEdit(selectedDocument?.id, formData, filterParams);
      }

      formData.defaultState = false;
      if (invisibleState && invisibleState.id === formData.stateDescriptorId) {
        formData.defaultState = true;
      }
      return props.onAdd(formData, filterParams);
    },
    [filterParams, props, selectedDocument, invisibleState],
  );

  const handleOpenRemoveDialog = useCallback((document) => {
    setSelectedDocument(document);
    setOpenArchiveDialog(true);
  }, []);

  const handleCloseArchiveDialog = useCallback(() => {
    setOpenArchiveDialog(false);
  }, []);

  const handleRowPerPageChange = useCallback(
    (value: number) => {
      setTableConf({
        ...tableConf,
        rowsPerPage: value,
      });
    },
    [setTableConf, tableConf],
  );

  const handleSortChange = useCallback(
    ({ ascended, fieldToSort }) => {
      setTableConf({
        ...tableConf,
        ascended,
        fieldToSort,
      });
    },
    [setTableConf, tableConf],
  );

  const handleArchive = useCallback(() => {
    props
      .onArchive(
        {
          ...selectedDocument,
          stateDescriptorId: selectedDocument.stateDescriptor.id,
        },
        filterParams,
      )
      .then((res) => {
        if (res.error) {
          setErrorMessage(res.error);
        }
      });
  }, [filterParams, props, selectedDocument]);

  const handleUpdateVersion = useCallback(
    async (file: File) => {
      if (selectedDocument?.id) {
        const updatedDocument: DocumentState = await props.updateVersion(selectedDocument.id, file);
        updateDocument(props.documents.content, updatedDocument);
        setSelectedDocument(updatedDocument);
      }
    },
    [props, selectedDocument],
  );

  const handleChangeState = useCallback(
    async (stateDescriptorId, comment = '') => {
      if (selectedDocument?.id) {
        const updatedDocument: DocumentState = await props.changeState(selectedDocument.id, {
          stateDescriptorId,
          comment,
        });
        updateDocument(props.documents.content, updatedDocument);
      }
    },
    [props, selectedDocument],
  );

  const handleUnarchive = useCallback(
    (document) => {
      setSelectedDocument(document);
      props.onUnarchive(
        {
          ...selectedDocument,
          stateDescriptorId: selectedDocument.stateDescriptor.id,
        },
        filterParams,
      );
    },
    [filterParams, props, selectedDocument],
  );
  const handleChangeUser = useCallback(
    async (userId) => {
      if (selectedDocument?.id) {
        const updatedDocument: DocumentState = await props.changeUser(selectedDocument.id, userId);
        updateDocument(props.documents.content, updatedDocument);
      }
    },
    [props, selectedDocument],
  );

  const handleOpenUnarchiveDialog = useCallback((document) => {
    setSelectedDocument(document);
    setOpenUnarchivedDialog(true);
  }, []);

  const handleCloseUnarchiveDialog = useCallback(() => {
    setOpenUnarchivedDialog(false);
  }, []);

  const tableActions: Action[] = useMemo(
    () => [
      {
        label: `${TableAction.Download} ${Entity.Document}`,
        onClick: handleDownloadButtonClick,
        icon: GetAppIcon,
        withProgress: true,
        isDisabled: (document): boolean => document.archived,
      },
      {
        label: `${TableAction.Edit} ${Entity.Document}`,
        onClick: handleEditButtonClick,
        icon: EditIcon,
      },
      {
        label: `${TableAction.Archive} ${Entity.Document}`,
        onClick: handleOpenRemoveDialog,
        icon: ArchiveIcon,
        action: TableAction.Archive,
        isHidden: (document): boolean => document.archived,
      },
      {
        label: `${TableAction.Unarchive} ${Entity.Document}`,
        onClick: handleOpenUnarchiveDialog,
        icon: UnarchiveIcon,
        isHidden: (document): boolean => !document.archived,
      },
    ],
    [handleDownloadButtonClick, handleEditButtonClick, handleOpenRemoveDialog, handleOpenUnarchiveDialog],
  );

  const tableExtendedSearchOptions: ExtendedSearchAction[] = useMemo(
    () => [
      {
        label: 'Content search',
        name: 'isContentSearch',
        onClick: setIsContentSearch,
        value: isContentSearch,
      },
      {
        label: 'Metadata search',
        name: 'isMetadataSearch',
        onClick: setIsMetadataSearch,
        value: isMetadataSearch,
      },
      {
        label: 'Extracted fields',
        name: 'isParsingSearch',
        onClick: setIsParsingSearch,
        value: isParsingSearch,
      },
    ],
    [isContentSearch, isMetadataSearch, isParsingSearch, setIsContentSearch, setIsMetadataSearch, setIsParsingSearch],
  );

  DOCUMENTS_COLUMNS[0].onClick = (dataIndex: number): void => handleEditButtonClick(props.documents.content[dataIndex]);
  DOCUMENTS_COLUMNS[_.findIndex(DOCUMENTS_COLUMNS, { field: 'tags' })].filterOptions = {
    names: props.documents.tags || [],
    display: (filterList, onChange, index, column): ReactElement => {
      const optionValues = props.documents.tags?.map((value) => ({ value, label: value }));
      return (
        <Select
          fullWidth
          options={optionValues || []}
          multiple
          label="Tags"
          disabled={!optionValues?.length}
          value={filterList[index]}
          onChange={(event): void => {
            filterList[index] = event.target.value;
            onChange(filterList[index], index, column);
          }}
        />
      );
    },
  };

  DOCUMENTS_COLUMNS[_.findIndex(DOCUMENTS_COLUMNS, { field: 'archived' })].filterOptions = {
    display: (filterList, onChange, index, column): ReactElement => {
      const optionValues = [
        { label: 'All', value: 'null' },
        { label: 'Archived', value: 'true' },
      ];

      return (
        <Select
          label="Show docs"
          options={optionValues}
          fullWidth
          value={filterList[index][0]}
          onChange={(event): void => {
            (filterList[index] as CustomAny) = [event.target.value];
            onChange(filterList[index], index, column);
          }}
        />
      );
    },
  };

  DOCUMENTS_COLUMNS[_.findIndex(DOCUMENTS_COLUMNS, { field: 'archived' })].customFilterListOptions = {
    render: (e): string => {
      return `Show docs: ${e[0] === 'null' ? 'all' : ''}${e[0] === 'true' ? 'archived' : ''}`;
    },
  };

  DOCUMENTS_COLUMNS[_.findIndex(DOCUMENTS_COLUMNS, { field: 'stateDescriptor' })].filterOptions = {
    names: [...props.states.map(({ id }) => `${id}`)],
    renderValue: (stateId): string => _.find(props.states, { id: parseInt(stateId) })?.name || '',
  };
  DOCUMENTS_COLUMNS[_.findIndex(DOCUMENTS_COLUMNS, { field: 'stateDescriptor' })].customFilterListOptions = {
    render: (stateId): string => `State: ${_.find(props.states, { id: parseInt(stateId) })?.name || ''}`,
  };

  const getInvisibleState = async () => {
    const state = await props.getInvisibleState();
    state && setInvisibleState(state);
  };

  useEffect(
    useCallback(() => {
      props.getMetadataList();
      props.getStatesList();
      props.getTemplates();
      props.getUsers();
      props.getTags();
    }, [props]),
    [],
  );

  useEffect(() => {
    if (props.states.length) {
      getInvisibleState();
    }
  }, [props.states]);

  useEffect(() => {
    return (): void => props.onLeaveTab();
  }, []);

  return (
    <PrimarySection align={Align.Start} className="documents" processing={props.processing}>
      <ServerTable
        columns={DOCUMENTS_COLUMNS}
        entity={Entity.Document}
        getData={props.getDocuments}
        data={props.documents.content}
        count={props.documents.totalElements}
        actions={tableActions}
        onAdd={handleAddButtonClick}
        filter
        textLabels={{
          toolbar: { filterTable: 'Filter Documents', search: 'Search text' },
          body: {
            noMatch: 'No documents',
          },
        }}
        setRowProps={(row: CustomAny[], rowIndex: number): AnyObject => {
          return row[2] === invisibleState?.name ? { style: { background: 'rgba(63, 81, 181, 0.1)' } } : {};
        }}
        searchName="content"
        search
        extendedSearchOptions={tableExtendedSearchOptions}
        onFilterChanged={setFilterParams}
        totalPages={props.documents.totalPages}
        currentPageIndex={props.documents.number}
        rowsPerPage={(tableConf as TableConf).rowsPerPage}
        onRowPerPage={handleRowPerPageChange}
        sortOrder={sortOrder}
        onSort={handleSortChange}
      />
      <EditDocumentFormDialogProps
        document={selectedDocument}
        open={openDocumentDialog}
        onSubmit={handleSubmitFormDialog}
        onClose={handleCloseFormDialog}
        metadataFields={props.metadata}
        onUpdateVersion={handleUpdateVersion}
        onChangeState={handleChangeState}
        onChangeUser={handleChangeUser}
        onDownload={props.download}
        userList={props.users}
        templateList={props.templates}
        stateList={props.states}
        invisibleState={invisibleState}
        getHistory={props.getDocumentHistory}
        getDocumentFieldsWithImages={props.getDocumentFieldsWithImages}
        processing={props.processingDialog}
      />
      <DocumentFormDialog
        open={openNewDocumentDialog}
        onSubmit={handleSubmitFormDialog}
        onClose={handleCloseFormDialog}
        metadataFields={props.metadata}
        userList={props.users}
        stateList={invisibleState ? [invisibleState, ...props.states] : props.states}
        templateList={props.templates}
        activeUser={props.activeUser}
      />
      <CustomActionDialog
        open={openUnarchiveDialog}
        onClose={handleCloseUnarchiveDialog}
        onSubmit={handleUnarchive}
        message="Are you sure you want to unarchive document?"
        title={
          <div className="dialog-title">
            Unarchive <span className="dialog-title__entity-name">{selectedDocument?.name}</span>
          </div>
        }
        actionName={TableAction.Unarchive.toLowerCase()}
      />
      <CustomActionDialog
        open={openArchiveDialog}
        onClose={handleCloseArchiveDialog}
        onSubmit={handleArchive}
        message="Are you sure you want to archive document?"
        title={
          <div className="dialog-title">
            Archive <span className="dialog-title__entity-name">{selectedDocument?.name}</span>
          </div>
        }
        actionName={TableAction.Archive.toLowerCase()}
      />
      <CustomActionDialog
        open={!!errorMessage}
        onClose={handleCloseErrorDialog}
        message={errorMessage}
        title={<div className="dialog-title dialog-title__entity-name">Server error</div>}
      />
    </PrimarySection>
  );
};

export default connector(Documents);
