import React, { FC, ReactElement } from 'react';
import { Draggable } from 'react-beautiful-dnd';

interface DraggableItemProps {
  draggableId: string;
  index: number;
}

const DraggableItem: FC<DraggableItemProps> = (props) => {
  return (
    <Draggable draggableId={props.draggableId} index={props.index}>
      {(provided): ReactElement => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          {props.children}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableItem;
