import React, { FC } from 'react';
import CheckboxMaterial from '@material-ui/core/Checkbox';

import { ControlRef } from '../../types/ControlRef';
import { Color } from './enums/Color';
import { AnyFunction } from '../../../../types/generics';

export interface CheckboxProps {
  checked?: boolean;
  name?: string;
  color?: Color;
  inputRef?: ControlRef;
  onChange?: AnyFunction;
  className?: string;
  disabled?: boolean;
}

const Checkbox: FC<CheckboxProps> = (props) => {
  return (
    <CheckboxMaterial
      name={props.name}
      inputRef={props.inputRef}
      color={props.color}
      checked={props.checked}
      onChange={props.onChange}
      disabled={props.disabled}
    />
  );
};
export default Checkbox;
