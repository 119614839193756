import React, { FC, useCallback, useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { FilledInputProps } from '@material-ui/core';
import _ from 'lodash';

import { AnyObject } from '../../../shared/types/generics';
import { Option } from '../../../shared/components/form/controls/select/interfaces/Option';
import { DocumentFieldRule } from '../../../store/interfaces/DocumentFieldRule';
import { Format } from '../../../store/enums/Format';
import InputControl from '../../../shared/components/form/controls/input/InputControl';
import DateTimeInputControl from '../../../shared/components/form/controls/datePicker/DateTimeInputControl';
import { DocumentFieldType } from '../../../store/enums/DocumentFieldType';
import IconButton from '../../../shared/components/iconButton/IconButton';
import { Size as IconSize } from '../../../shared/components/iconButton/enums/Size';
import { RULE_OPTIONS, SUBTYPE_OPTIONS } from '../constants/dialog';
import SelectControl from '../../../shared/components/form/controls/select/SelectControl';
import { DocumentSubFieldType } from '../../../store/enums/DocumentSubFieldType';
import AddNewRuleDialog from './AddNewRuleDialog';

import './PostProcessingFields.scss';

interface PostProcessingFieldsProps {
  fields: DocumentFieldRule[];
}

const PostProcessingFields: FC<PostProcessingFieldsProps> = (props) => {
  const { fields } = props;
  const [templateRules, setTemplateRules] = useState<DocumentFieldRule[]>([]);
  const [emptyRules, setEmptyRules] = useState<DocumentFieldRule[]>([]);
  const [openRuleDialog, setOpenRuleDialog] = useState(false);

  useEffect(() => {
    if (fields?.length) {
      const rules = [...fields];
      const notEmptyRules = rules.filter(
        (field) => field.subtype && (field as AnyObject)[`${field.subtype.toLowerCase()}DataFieldPolicy`],
      );

      notEmptyRules.forEach((rule) => {
        const index = rules.findIndex((e) => e.id === rule.id);
        index !== -1 && rules.splice(index, 1);
      });

      setTemplateRules([...notEmptyRules]);
      setEmptyRules([...rules]);
    }
  }, [fields]);

  const handleCloseFormDialog = useCallback(() => {
    setOpenRuleDialog(false);
  }, []);

  const handleSubmitFormDialog = useCallback(
    (FieldId: number) => {
      const rules = [...emptyRules];
      const index = rules.findIndex((e) => e.id === FieldId);
      if (index !== -1) {
        const field = rules.splice(index, 1)[0];

        setTemplateRules([...templateRules, field]);
        setEmptyRules([...rules]);
      }
      return handleCloseFormDialog();
    },
    [emptyRules, handleCloseFormDialog, templateRules],
  );

  const getFieldRuleDefaultValue = (field: DocumentFieldRule): string | number => {
    const subtypeMap = getSubtypeMap(field);
    if (subtypeMap && subtypeMap[1] && field.subtype === DocumentSubFieldType.DATE) {
      const date = subtypeMap[1];
      const newDate = _.reverse((date as string).split('/')).join('-');
      return (new Date(newDate) as unknown) as string;
    }
    return subtypeMap ? (subtypeMap[1] as string | number) : '';
  };

  const getFieldDefaultRule = (field: DocumentFieldRule): string | undefined => {
    const subtypeMap = getSubtypeMap(field);
    if (subtypeMap) {
      return subtypeMap[0];
    } else if (field.subtype && (RULE_OPTIONS as AnyObject)[`${field.subtype}_OPTIONS`]) {
      return (RULE_OPTIONS as AnyObject)[`${field.subtype}_OPTIONS`][0];
    }
    return undefined;
  };

  const getSubtypeMap = (field: DocumentFieldRule) => {
    const propName = `${field.subtype?.toLowerCase()}DataFieldPolicy`;
    if (!field.subtype || !(field as AnyObject)[propName]) {
      return '';
    }
    return Object.entries((field as AnyObject)[propName]).find(([key, value]) => key !== 'id' && value);
  };

  const getSubtypeOptions = (field: DocumentFieldRule): Option[] => {
    if (!field.subtype) {
      return RULE_OPTIONS.STRING_OPTIONS;
    }
    return (RULE_OPTIONS as AnyObject)[`${field.subtype}_OPTIONS`] || [];
  };

  const deleteRule = useCallback(
    (field: DocumentFieldRule) => {
      const rules = [...templateRules];
      const index = rules.findIndex((e) => e.id === field.id);
      if (index !== -1) {
        rules.splice(index, 1);

        field.subtype = null;
        field.rule = '';
        field.dateDataFieldPolicy = null;
        field.decimalDataFieldPolicy = null;
        field.integerDataFieldPolicy = null;
        field.stringDataFieldPolicy = null;

        setTemplateRules([...rules]);
        setEmptyRules([...emptyRules, field]);
      }
    },
    [emptyRules, templateRules],
  );

  const setSubtype = useCallback(
    (value: DocumentSubFieldType, field: DocumentFieldRule) => {
      const rules = [...templateRules];
      if (field.subtype !== value) {
        const index = rules.findIndex((e) => e.id === field.id);
        if (index !== -1) {
          rules[index].subtype = value;
          setTemplateRules([...rules]);
        }
      }
    },
    [templateRules],
  );

  const setSubtypeRule = useCallback(
    (value: DocumentSubFieldType, field: DocumentFieldRule) => {
      const rules = [...templateRules];
      if (field.rule !== value) {
        const index = rules.findIndex((e) => e.id === field.id);
        if (index !== -1) {
          rules[index].rule = value;
          setTemplateRules([...rules]);
        }
      }
    },
    [templateRules],
  );

  const renderInputValue = (field: DocumentFieldRule): JSX.Element => {
    const inputProps: Partial<FilledInputProps> = {};

    if (field.subtype === DocumentSubFieldType.DECIMAL) {
      inputProps.inputProps = { step: '0.001' };
    }

    if (field.subtype === DocumentSubFieldType.DATE) {
      return (
        <DateTimeInputControl
          name={`documentRule-${field.id}-value`}
          label="Default"
          type={Format.Date}
          value={getFieldRuleDefaultValue(field)}
          fullWidth
        />
      );
    }

    return (
      <InputControl
        fullWidth
        type={
          [DocumentSubFieldType.DECIMAL, DocumentSubFieldType.INTEGER].includes(field.subtype as DocumentSubFieldType)
            ? Format.Number
            : Format.String
        }
        name={`documentRule-${field.id}-value`}
        disabled={!field.subtype}
        InputProps={inputProps}
        label="Rule value"
        defaultValue={getFieldRuleDefaultValue(field)}
      />
    );
  };

  return (
    <section className="data-fields">
      {templateRules &&
        templateRules
          .filter((e) => e.type === DocumentFieldType.TEXT)
          .map((field) => {
            return (
              <div key={field.id} className="field-container">
                <div className="field-name">
                  {field.name}
                  <IconButton
                    size={IconSize.Small}
                    ariaLabel="Delete rule"
                    onClick={(): void => {
                      deleteRule(field);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
                {
                  <div className="document-rules-input-box">
                    <SelectControl
                      name={`documentRule-${field.id}-subtype`}
                      label="Field subtype"
                      options={SUBTYPE_OPTIONS}
                      value={field.subtype || undefined}
                      onChange={(value): void => {
                        setSubtype(value, field);
                      }}
                    />
                    <SelectControl
                      name={`documentRule-${field.id}-rule`}
                      label="Rule"
                      disabled={!field.subtype}
                      options={getSubtypeOptions(field)}
                      value={getFieldDefaultRule(field)}
                      onChange={(value): void => {
                        setSubtypeRule(value, field);
                      }}
                    />
                    {renderInputValue(field)}
                  </div>
                }
              </div>
            );
          })}
      <div className="field-add-button">
        <IconButton
          size={IconSize.Small}
          disabled={!emptyRules.filter((e) => e.type === DocumentFieldType.TEXT).length}
          ariaLabel="Add new rule"
          onClick={(): void => {
            setOpenRuleDialog(true);
          }}
        >
          <AddBoxOutlinedIcon />
        </IconButton>
      </div>
      <AddNewRuleDialog
        open={openRuleDialog}
        onClose={handleCloseFormDialog}
        onSubmit={handleSubmitFormDialog}
        emptyRules={emptyRules}
      />
    </section>
  );
};

export default PostProcessingFields;
