import { Action, handleActions } from 'redux-actions';

import { UserState } from '../interfaces/UserState';

const initialState: UserState[] = [];

export default handleActions(
  {
    USERS_SET: (state, action: Action<UserState[]>) => [...action.payload],
  },
  initialState,
);
