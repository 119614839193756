import React, { FC, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import { connector, StateProps } from './connector';
import { Align } from '../../shared/layouts/primarySection/enums/Align';
import { ADMIN_PAGE_TABS } from './constants/common';
import PrimarySection from '../../shared/layouts/primarySection/PrimarySection';
import Tabs from '../../shared/components/tabs/Tabs';
import AdminRouter from '../../routes/adminRouter/AdminRouter';
import TabContent from '../../shared/components/tabs/tabContent/TabContent';
import { AdminRoutes } from '../../routes/adminRouter/AdminRoutes';

const Admin: FC<StateProps> = () => {
  const { pathname } = useLocation();
  const [selectedTab, setSelectedTab] = useState(AdminRoutes.Users);

  useEffect(() => {
    if (_.values(AdminRoutes).includes(pathname as AdminRoutes)) {
      setSelectedTab(pathname as AdminRoutes);
    }
  }, [pathname]);

  return (
    <PrimarySection verticalAlign={Align.Start} fullWidth>
      <Tabs tabsObject={ADMIN_PAGE_TABS} selectedTab={selectedTab} />
      <TabContent>
        <AdminRouter />
      </TabContent>
    </PrimarySection>
  );
};

export default connector(Admin);
