import { TabObject } from '../../../shared/components/tabs/interfaces/TabObject';
import { AdminRoutes } from '../../../routes/adminRouter/AdminRoutes';
import { TabType } from '../../../shared/components/tabs/enums/TabType';

export const ADMIN_PAGE_TABS: TabObject[] = [
  {
    label: 'User management',
    value: AdminRoutes.Users,
    type: TabType.Link,
  },
  {
    label: 'Metadata fields',
    value: AdminRoutes.Metadata,
    type: TabType.Link,
  },
  {
    label: 'Document states',
    value: AdminRoutes.States,
    type: TabType.Link,
  },
  {
    label: 'Hotfolders',
    value: AdminRoutes.Hotfolders,
    type: TabType.Link,
  },
];
