import React, { FC, useCallback, useState, useMemo } from 'react';

import FormGroup from '../../../shared/components/form/formGroup/FormGroup';
import { AnyFunction } from '../../../shared/types/generics';
import { Option } from '../../../shared/components/form/controls/select/interfaces/Option';

import SelectControl from '../../../shared/components/form/controls/select/SelectControl';
import { DocumentFieldRule } from '../../../store/interfaces/DocumentFieldRule';
import { Color } from '../../../shared/components/button/enums/Color';
import { Variant } from '../../../shared/components/button/enums/Variant';
import Dialog from '../../../shared/components/dialog/Dialog';
import { DocumentFieldType } from '../../../store/enums/DocumentFieldType';

interface AddNewRuleDialogProps {
  open: boolean;
  onSubmit: AnyFunction;
  onClose: AnyFunction;
  emptyRules: DocumentFieldRule[];
}

const AddNewRuleDialog: FC<AddNewRuleDialogProps> = (props) => {
  const { onSubmit: handleSubmit } = props;
  const [emptyRule, setEmptyRule] = useState<number>();

  const onSubmit = useCallback(() => {
    handleSubmit(emptyRule);
  }, [emptyRule, handleSubmit]);

  const getFieldOptions = (emptyRules: DocumentFieldRule[]): Option[] =>
    emptyRules
      .filter((e) => e.type === DocumentFieldType.TEXT)
      .map((e) => {
        return {
          value: e.id,
          label: e.name,
        };
      });

  const options = useMemo(() => getFieldOptions(props.emptyRules), [props.emptyRules]);

  React.useEffect(() => {
    if (options.length) {
      setEmptyRule(options[0].value);
    }
  }, [options]);

  const actions = useMemo(
    () => [
      {
        label: 'Cancel',
        onClick: props.onClose,
        color: Color.Default,
      },
      {
        label: 'Add',
        onClick: onSubmit,
        color: Color.Primary,
        variant: Variant.Contained,
      },
    ],
    [props.onClose, onSubmit],
  );

  return (
    <Dialog open={props.open} onClose={props.onClose} title="Add new post-processing rule" actions={actions}>
      <FormGroup>
        <SelectControl
          name="RuleId"
          label="Field name"
          options={options}
          fullWidth
          required
          value={emptyRule}
          onChange={setEmptyRule}
        />
      </FormGroup>
    </Dialog>
  );
};

export default AddNewRuleDialog;
