import React, { FC, useState, createContext } from 'react';
import { useLocalStorage } from 'react-use';

import { AnyObject } from '../../types/generics';
import { SIDE_MENU_KEY } from '../../constants/application';

export const HeaderContext = createContext({} as AnyObject);

const HeaderProvider: FC = (props) => {
  const [menuState, setMenuState] = useLocalStorage(SIDE_MENU_KEY, false);
  const [menuOpened, setMenuOpened] = useState(menuState);
  const [showMenu, setShowMenu] = useState(false);
  const [showHeader, setShowHeader] = useState(false);

  const context = {
    menu: {
      show: showMenu,
      opened: menuOpened,
      setShow: setShowMenu,
      setOpened: (value: boolean): void => {
        setMenuState(value);
        setMenuOpened(value);
      },
    },
    header: {
      show: showHeader,
      setShow: setShowHeader,
    },
  };

  return <HeaderContext.Provider value={context}>{props.children}</HeaderContext.Provider>;
};

export default HeaderProvider;
