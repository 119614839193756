import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Users from '../../pages/admin/users/Users';
import Metadata from '../../pages/admin/metadata/Metadata';
import States from '../../pages/admin/states/States';
import Hotfolders from '../../pages/admin/hotfolders/Hotfolders';
import { AdminRoutes } from './AdminRoutes';

const AdminRouter: FC = () => {
  return (
    <Switch>
      <Route exact path={AdminRoutes.Users} component={Users} />
      <Route exact path={AdminRoutes.Metadata} component={Metadata} />
      <Route exact path={AdminRoutes.States} component={States} />
      <Route exact path={AdminRoutes.Hotfolders} component={Hotfolders} />
      <Redirect to={AdminRoutes.Users} />
    </Switch>
  );
};

export default AdminRouter;
