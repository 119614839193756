import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Routes } from './Routes';
import PrivateRoute from './privateRoute/PrivateRoute';
import { connector, StateProps } from './connector';
import Login from '../pages/login/Login';
import Admin from '../pages/admin/Admin';
import Templates from '../pages/templates/Templates';
import Dashboard from '../pages/dashboard/Dashboard';
import Documents from '../pages/documents/Documents';
import Logs from '../pages/logs/Logs';
import NotFound from '../pages/notFound/NotFound';

const AppRouter: FC<StateProps> = ({ hasAdminPermission, hasUserPermission }) => {
  return (
    <Switch>
      <Redirect exact from="/" to={Routes.Login} />
      <Route exact path={Routes.Login} component={Login} />
      <PrivateRoute exact path={Routes.NotFound} available component={NotFound} />
      <PrivateRoute path={Routes.Admin} available={hasAdminPermission} component={Admin} />
      <PrivateRoute exact path={Routes.Templates} available={hasAdminPermission} component={Templates} />
      <PrivateRoute exact path={Routes.Dashboard} available={hasUserPermission} component={Dashboard} />
      <PrivateRoute exact path={Routes.Documents} available={hasUserPermission} component={Documents} />
      <PrivateRoute exact path={Routes.Logs} available={hasUserPermission} component={Logs} />
      <Redirect to={Routes.NotFound} />
    </Switch>
  );
};

export default connector(AppRouter);
