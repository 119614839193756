import _ from 'lodash';

import { AnyObject, Nullable } from '../../../shared/types/generics';
import { DocumentState } from '../../../store/interfaces/DocumentState';

export const getDocumentVersion = (versionList: AnyObject[]): string =>
  _.orderBy(versionList, ['version'], ['desc'])[0]?.version || '';

export const getDocumentErrors = (versionList: AnyObject[]): string =>
  _.orderBy(versionList, ['version'], ['desc'])[0]?.errors || 0;

export const getDocumentById = (documentList: DocumentState[], id: string | number): Nullable<DocumentState> =>
  _.find(documentList, { id });

export const updateDocument = (documentList: DocumentState[], newDocument: DocumentState): void => {
  const docIndex = _.findIndex(documentList, { id: newDocument.id });
  if (docIndex > -1) {
    documentList.splice(docIndex, 1, newDocument);
  }
};
