import React, { FC, useContext } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';

import { connector, StateProps } from './connector';
import { HeaderContext } from '../headerProvider/HeaderProvider';
import SideMenuHeader from './sideMenuHeader/SideMenuHeader';
import AdminMenu from './adminMenu/AdminMenu';
import UserMenu from './userMenu/UserMenu';

import './SideMenu.scss';

const SideMenu: FC<StateProps> = (props) => {
  const { header, menu } = useContext(HeaderContext);
  if (!header.show || !menu.show) {
    return null;
  }

  return (
    <Drawer open={menu.opened} variant="persistent" PaperProps={{ className: 'side-menu' }}>
      <SideMenuHeader />
      <Divider />
      {props.hasUserPermission ? <UserMenu /> : null}
      <Divider />
      {props.hasAdminPermission ? <AdminMenu /> : null}
    </Drawer>
  );
};

export default connector(SideMenu);
