import React, { FC, useCallback } from 'react';

import FormDialog from '../../../shared/components/dialog/FormDialog';
import FormGroup from '../../../shared/components/form/formGroup/FormGroup';
import { AnyFunction } from '../../../shared/types/generics';
import InputControl from '../../../shared/components/form/controls/input/InputControl';
import { MoveStateSchema } from './MoveStateSchema';
import { MoveStateFields } from './MoveStateFields';
import { MoveStateData } from './MoveStateData';

interface MoveStateDialogProps {
  open: boolean;
  from: string;
  to: string;
  onSubmit: AnyFunction;
  onClose: AnyFunction;
}

const MoveStateDialog: FC<MoveStateDialogProps> = (props) => {
  const onSubmit = useCallback(
    async (formData: MoveStateData) => {
      await props.onSubmit(formData);
    },
    [props],
  );

  return (
    <FormDialog
      title={
        <div className="dialog-title">
          Move from <span className="dialog-title__entity-name">{props.from}</span> to{' '}
          <span className="dialog-title__entity-name">{props.to}</span>
        </div>
      }
      onSubmit={onSubmit}
      open={props.open}
      onClose={props.onClose}
      validationSchema={MoveStateSchema}
    >
      <FormGroup>
        <InputControl name={MoveStateFields.Comment} multiline fullWidth autoFocus label="Comment" />
      </FormGroup>
    </FormDialog>
  );
};

export default MoveStateDialog;
