import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import Toolbar from '@material-ui/core/Toolbar';

import { DispatchProps, StateProps, connector } from './connector';
import DraggableList from '../../../shared/components/draggableList/DraggableList';
import PrimarySection from '../../../shared/layouts/primarySection/PrimarySection';
import IconButton from '../../../shared/components/iconButton/IconButton';
import StateDeleteDialog from './stateDeleteDialog/StateDeleteDialog';
import StateFormDialog from './stateFormDialog/StateFormDialog';
import SecondaryText from '../../../shared/components/secondaryText/SecondaryText';
import { Align } from '../../../shared/layouts/primarySection/enums/Align';
import { TableAction } from '../../../shared/components/table/enums/TableAction';
import { Nullable } from '../../../shared/types/generics';
import { StatesState } from '../../../store/interfaces/StatesState';
import { Entity } from '../../../shared/enums/Entity/Entity';
import { StateDeleteFormData } from './stateDeleteDialog/StateDeleteFormData';

interface StatesProps extends StateProps, DispatchProps {}

const States: FC<StatesProps> = (props) => {
  const [openStateDialog, setOpenStateDialog] = useState(false);
  const [openDeleteStateDialog, setOpenDeleteStateDialog] = useState(false);
  const [selectedState, setSelectedState] = useState<Nullable<StatesState>>(null);

  const handleAddButtonClick = useCallback(() => {
    setSelectedState(null);
    setOpenStateDialog(true);
  }, []);

  const handleCloseFormDialog = useCallback(() => {
    setOpenStateDialog(false);
  }, []);

  const handleEditButtonClick = useCallback((state) => {
    setSelectedState(state);
    setOpenStateDialog(true);
  }, []);

  const handleSubmitFormDialog = useCallback(
    (formData) => {
      if (selectedState) {
        return props.onEditState(selectedState?.id, formData);
      }
      return props.onAddState(formData);
    },
    [props, selectedState],
  );

  const handleOpenRemoveDialog = useCallback((state) => {
    setSelectedState(state);
    setOpenDeleteStateDialog(!!state);
  }, []);

  const handleCloseRemoveDialog = useCallback(() => {
    setOpenDeleteStateDialog(false);
  }, []);

  const handleRemove = useCallback(
    (data: StateDeleteFormData) => {
      props.onRemoveState(selectedState?.id, data);
      setSelectedState(null);
    },
    [props, selectedState],
  );

  const onDragEnd = useCallback(
    (result) => {
      if (result.destination) {
        props.onChangeOrder(parseInt(result.draggableId), result.destination.index + 1);
      }
    },
    [props],
  );

  useEffect(
    useCallback(() => {
      props.getStates();
    }, [props]),
    [],
  );

  const itemList = useMemo(
    () =>
      props.states
        .sort((state1, state2) => state1.order - state2.order)
        .map((state) => ({
          id: state.id.toString(),
          name: state.name,
          label: <>{state.name}</>,
          onItemClick: (): void => handleEditButtonClick(state),
          actions: [
            {
              label: `${TableAction.Edit} ${Entity.State}`,
              onClick: (): void => handleEditButtonClick(state),
              icon: EditIcon,
            },
            {
              label: `${TableAction.Delete} ${Entity.State}`,
              onClick: (): void => handleOpenRemoveDialog(state),
              icon: DeleteIcon,
              isDisabled: (): boolean =>
                props.states.length === 1 ||
                state.parserErrorState ||
                state.invalidDataState ||
                state.validDataState ||
                state.missingDataState,
            },
          ],
        })),
    [handleEditButtonClick, handleOpenRemoveDialog, props.states],
  );

  return (
    <PrimarySection className="states" verticalAlign={Align.Start} ignoreHeader processing={props.processing}>
      <Toolbar disableGutters>
        <IconButton onClick={handleAddButtonClick} ariaLabel={`Add ${Entity.State}`}>
          <AddBoxOutlinedIcon />
        </IconButton>
      </Toolbar>
      <DraggableList items={itemList} onDragEnd={onDragEnd} emptyPlaceholder="No document states" />
      <StateFormDialog
        open={openStateDialog}
        state={selectedState}
        onClose={handleCloseFormDialog}
        onSubmit={handleSubmitFormDialog}
        statesNumber={props.states.length}
      />
      <StateDeleteDialog
        open={openDeleteStateDialog}
        onClose={handleCloseRemoveDialog}
        onSubmit={handleRemove}
        state={selectedState}
        stateList={props.states}
      />
    </PrimarySection>
  );
};

export default connector(States);
