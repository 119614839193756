import { deleteRequest, getRequest, postRequest, putRequest } from '../api';
import { StatesPaths } from './StatesPaths';
import { StatesState } from '../../../store/interfaces/StatesState';
import { StateFormData } from '../../../pages/admin/states/stateFormDialog/StateFormData';
import { StateDeleteFormData } from '../../../pages/admin/states/stateDeleteDialog/StateDeleteFormData';

export const get = (url = StatesPaths.Admin): Promise<StatesState[]> => getRequest(url);

export const getInvisibleState = (url = StatesPaths.Admin): Promise<StatesState> =>
  getRequest(`${url}/invisible_state`);

export const update = (id: number, formData: StateFormData): Promise<StatesState> =>
  putRequest(`${StatesPaths.Admin}/${id}`, formData);

export const add = (formData: StateFormData): Promise<StatesState> => postRequest(StatesPaths.Admin, formData);

export const remove = (id: number, data: StateDeleteFormData): Promise<StatesState> =>
  deleteRequest(`${StatesPaths.Admin}/${id}`, data);
