import React, { FC } from 'react';
import TreeItem from '@material-ui/lab/TreeItem';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

import { TreeNode } from '../interfaces/TreeNode';

interface TreeItemListProps {
  structure: TreeNode[];
}

const TreeItemList: FC<TreeItemListProps> = (props) => {
  return (
    <>
      {props.structure.map((node) => (
        <TreeItem
          className="tree-item"
          key={node.id}
          nodeId={'' + node.id}
          label={<div className="tree-item__label">{node.label}</div>}
          TransitionProps={{ timeout: 150 }}
          endIcon={node.icon ? <node.icon /> : <FolderOpenIcon />}
        >
          {node.children?.length ? <TreeItemList structure={node.children} /> : null}
        </TreeItem>
      ))}
    </>
  );
};

export default TreeItemList;
