import { connect } from 'react-redux';

import { AnyDispatch, AnyObject, CustomAny } from '../../shared/types/generics';
import { DocumentState } from '../../store/interfaces/DocumentState';
import { RootState } from '../../store/interfaces/RootState';
import { isProcessingPath } from '../../store/requestsProcessing/selectors';
import { getDocuments } from '../../store/documents/selectors';
import {
  postForDocumentList,
  addDocument,
  editDocument,
  archiveDocument,
  downloadDocument,
  updateVersion,
  changeState,
  changeUser,
  getTagList,
  unarchiveDocument,
} from '../../store/documents/actions';
import { getMetadataList } from '../../store/metadata/actions';
import { MetadataPaths } from '../../shared/api/metadata/MetadataPaths';
import { MetadataState } from '../../store/interfaces/MetadataState';
import { getMetadata } from '../../store/metadata/selectors';
import { DocumentsTableState } from '../../store/interfaces/DocumentsTableState';
import { getStates } from '../../store/states/selectors';
import { StatesState } from '../../store/interfaces/StatesState';
import { getStatesList, getInvisibleState } from '../../store/states/actions';
import { StatesPaths } from '../../shared/api/states/StatesPaths';
import { getTemplatesTree } from '../../store/templates/actions';
import { TemplatesPaths } from '../../shared/api/templates/TemplatesPaths';
import { TemplatesTree } from '../../store/interfaces/TemplatesTree';
import { getTree } from '../../store/templates/selectors';
import { getUsersList } from '../../store/users/selectors';
import { getUsers } from '../../store/users/actions';
import { UsersPaths } from '../../shared/api/users/UsersPaths';
import { UserState } from '../../store/interfaces/UserState';
import { getUserId } from '../../store/user/selectors';
import { DocumentsPaths } from '../../shared/api/documents/DocumentsPaths';
import { LogState } from '../../store/interfaces/LogState';
import { getDocumentHistory } from '../../store/logs/actions';
import { LogsPaths } from '../../shared/api/logs/LogsPaths';
import { resetDocsToDefault, getDocumentFieldsWithImages } from '../../store/documents/actions';
import { DocumentField } from '../../store/interfaces/DocumentField';

export interface StateProps {
  documents: DocumentsTableState;
  metadata: MetadataState[];
  states: StatesState[];
  templates: TemplatesTree;
  users: UserState[];
  activeUser: number;
  processingDialog: boolean;
  processing: boolean;
}

export interface DispatchProps {
  onLeaveTab(): void;
  getDocuments(props: CustomAny): void;
  getMetadataList(): void;
  getStatesList(): void;
  getInvisibleState(): Promise<StatesState>;
  getTemplates(): void;
  getUsers(): void;
  getTags(): void;
  onAdd(data: DocumentState, filterParams: AnyObject): void;
  onEdit(id: string, data: DocumentState, filterParams: AnyObject): void;
  onArchive(data: DocumentState, filterParams: AnyObject): Promise<CustomAny>;
  onUnarchive(data: DocumentState, filterParams: AnyObject): void;
  download(data: DocumentState, version?: number): void;
  updateVersion(id: string | number, file: File): Promise<DocumentState>;
  changeState(id: number, moveProps: { stateDescriptorId: number; comment?: string }): Promise<DocumentState>;
  changeUser(id: number, userId: number): Promise<DocumentState>;
  getDocumentHistory(params: AnyObject): Promise<LogState[]>;
  getDocumentFieldsWithImages(id: number): Promise<DocumentField[]>;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    documents: getDocuments(state),
    metadata: getMetadata(state),
    states: getStates(state),
    templates: getTree(state),
    users: getUsersList(state),
    activeUser: getUserId(state),
    processingDialog: isProcessingPath(state)(LogsPaths.List) || isProcessingPath(state)(DocumentsPaths.List),
    processing:
      isProcessingPath(state)(MetadataPaths.User) ||
      isProcessingPath(state)(StatesPaths.User) ||
      isProcessingPath(state)(TemplatesPaths.User) ||
      isProcessingPath(state)(DocumentsPaths.Tags) ||
      isProcessingPath(state)(UsersPaths.User) ||
      isProcessingPath(state)(DocumentsPaths.List),
  };
};

const mapDispatchToProps = (dispatch: AnyDispatch): DispatchProps => {
  return {
    onLeaveTab: (): Promise<CustomAny> => dispatch(resetDocsToDefault()),
    getDocuments: (props): Promise<CustomAny> => dispatch(postForDocumentList(props)),
    getMetadataList: (): Promise<CustomAny> => dispatch(getMetadataList(MetadataPaths.User)),
    getStatesList: (): Promise<CustomAny> => dispatch(getStatesList(StatesPaths.User)),
    getInvisibleState: (): Promise<StatesState> => dispatch(getInvisibleState(StatesPaths.InisibleState)),
    getTemplates: (): Promise<CustomAny> => dispatch(getTemplatesTree(TemplatesPaths.User)),
    getUsers: (): Promise<CustomAny> => dispatch(getUsers(UsersPaths.User)),
    getTags: (): Promise<CustomAny> => dispatch(getTagList()),
    onAdd: (data, filterParams = {}): Promise<CustomAny> => dispatch(addDocument(data, filterParams)),
    onEdit: (id, data, filterParams = {}): Promise<CustomAny> => dispatch(editDocument(id, data, filterParams)),
    onArchive: (data, filterParams = {}): Promise<CustomAny> => dispatch(archiveDocument(data, filterParams)),
    onUnarchive: (data, filterParams = {}): Promise<CustomAny> => dispatch(unarchiveDocument(data, filterParams)),
    download: (data, version?): Promise<CustomAny> => dispatch(downloadDocument(data, version)),
    updateVersion: (id, file): Promise<DocumentState> => dispatch(updateVersion(id, file)),
    changeState: (id, moveProps): Promise<DocumentState> => dispatch(changeState(id, moveProps)),
    changeUser: (id, userId): Promise<DocumentState> => dispatch(changeUser(id, userId)),
    getDocumentHistory: (params = {}): Promise<LogState[]> => dispatch(getDocumentHistory(params)),
    getDocumentFieldsWithImages: (id: number): Promise<DocumentField[]> => dispatch(getDocumentFieldsWithImages(id)),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
