import React, { FC, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DescriptionIcon from '@material-ui/icons/Description';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListAltIcon from '@material-ui/icons/ListAlt';

import List from '../../../components/list/List';
import { Routes } from '../../../../routes/Routes';
import { ListObject } from '../../../components/list/interfaces/ListObject';
import { PAGE_TITLE } from '../../../constants/application';

const UserMenu: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const userMenuItems: ListObject[] = useMemo(
    () => [
      {
        icon: DashboardIcon,
        label: PAGE_TITLE[Routes.Dashboard],
        selected: location.pathname === Routes.Dashboard,
        onClick: (): void => history.push(Routes.Dashboard),
      },
      {
        icon: DescriptionIcon,
        label: PAGE_TITLE[Routes.Documents],
        selected: location.pathname === Routes.Documents,
        onClick: (): void => history.push(Routes.Documents),
      },
      {
        icon: ListAltIcon,
        label: PAGE_TITLE[Routes.Logs],
        selected: location.pathname === Routes.Logs,
        onClick: (): void => history.push(Routes.Logs),
      },
    ],
    [history, location.pathname],
  );

  return <List listObject={userMenuItems} />;
};

export default UserMenu;
