import { connect } from 'react-redux';

import { AnyDispatch, CustomAny } from '../../../shared/types/generics';
import { getStatesList, addState, editState, removeState, changeStateOrder } from '../../../store/states/actions';
import { RootState } from '../../../store/interfaces/RootState';
import { isProcessingPath } from '../../../store/requestsProcessing/selectors';
import { StatesState } from '../../../store/interfaces/StatesState';
import { StateFormData } from './stateFormDialog/StateFormData';
import { getStates } from '../../../store/states/selectors';
import { StatesPaths } from '../../../shared/api/states/StatesPaths';
import { StateDeleteFormData } from './stateDeleteDialog/StateDeleteFormData';

export interface StateProps {
  states: StatesState[];
  processing: boolean;
}

export interface DispatchProps {
  getStates: () => void;
  onEditState: (id: number, formData: StateFormData) => void;
  onAddState: (formData: StateFormData) => void;
  onRemoveState: (id: number, data: StateDeleteFormData) => void;
  onChangeOrder: (id: number, order: string) => void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    states: getStates(state),
    processing: isProcessingPath(state)(StatesPaths.Admin),
  };
};

const mapDispatchToProps = (dispatch: AnyDispatch): DispatchProps => {
  return {
    getStates: (): Promise<CustomAny> => dispatch(getStatesList()),
    onEditState: (id, formData): Promise<CustomAny> => dispatch(editState(id, formData)),
    onAddState: (formData): Promise<CustomAny> => dispatch(addState(formData)),
    onRemoveState: (id, data): Promise<CustomAny> => dispatch(removeState(id, data)),
    onChangeOrder: (id, order): Promise<CustomAny> => dispatch(changeStateOrder(id, order)),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
