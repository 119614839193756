import { handleActions } from 'redux-actions';

import { DocumentsTableState } from '../interfaces/DocumentsTableState';

const initialState: DocumentsTableState = {
  content: [],
  tags: [],
  totalElements: 0,
  numberOfElements: 0,
  totalPages: 0,
  number: 0,
};

export default handleActions(
  {
    DOCUMENTS_UPDATE: (state, { payload: documents }) => ({ ...state, ...documents }),
    TAGS_SET: (state, { payload: { tags } }) => ({
      ...state,
      tags,
    }),
  },
  initialState,
);
