import React, { FC } from 'react';
import PopoverMaterial, { PopoverOrigin } from '@material-ui/core/Popover';

interface PopoverProps {
  id: string;
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLButtonElement | null;
  anchorOrigin?: PopoverOrigin;
}

const Popover: FC<PopoverProps> = (props) => {
  const { open, onClose, anchorEl, id, anchorOrigin } = props;

  return (
    <PopoverMaterial
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={
        anchorOrigin || {
          vertical: 'bottom',
          horizontal: 'left',
        }
      }
    >
      {props.children}
    </PopoverMaterial>
  );
};

export default Popover;
