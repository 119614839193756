import React, { FC, useCallback, useState, useReducer, useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import _ from 'lodash';

import { AnyFunction, AnyObject, CustomAny, Nullable } from '../../../../shared/types/generics';
import PrimarySection from '../../../../shared/layouts/primarySection/PrimarySection';
import MoveStateDialog from '../../../documents/moveStateDialog/MoveStateDialog';
import SecondaryText from '../../../../shared/components/secondaryText/SecondaryText';
import DocumentList from './documentList/DocumentList';
import { StatesState } from '../../../../store/interfaces/StatesState';
import { connector, DispatchProps } from './connector';
import { DocumentByStateProcessingReducer } from '../../helpers/common';

import './WidgetContent.scss';

interface WidgetContentProps extends DispatchProps {
  states: StatesState[];
}

const WidgetContent: FC<WidgetContentProps> = (props) => {
  const { changeState, getDocuments } = props;
  const [moveDialogOpened, setMoveDialogOpened] = useState(false);
  const handleCloseMoveDialog = useCallback(() => {
    setMoveDialogOpened(false);
    setMoveFrom(null);
    setMoveTo(null);
  }, []);
  const [processingState] = useReducer(DocumentByStateProcessingReducer, []);
  const [moveTo, setMoveTo] = useState<Nullable<AnyObject>>(null);
  const [moveFrom, setMoveFrom] = useState<Nullable<AnyObject>>(null);
  const [droppedState, setDroppedState] = useState<AnyObject>({});
  const [selectedDocumentId, setSelectedDocumentId] = useState<Nullable<number>>(null);
  const moveState = useCallback(
    async ({ comment }) => {
      if (selectedDocumentId && droppedState?.source?.id && droppedState?.destination?.id) {
        await changeState(selectedDocumentId, {
          stateDescriptorId: parseInt(droppedState.destination.id),
          comment,
        });
        setMoveFrom(droppedState.source);
        setMoveTo(droppedState.destination);
      }
      setMoveDialogOpened(false);
    },
    [selectedDocumentId, droppedState, changeState],
  );
  const onDragEnd = (data: CustomAny): void => {
    if (!data.destination || data.source.droppableId === data.destination.droppableId) {
      return;
    }
    setDroppedState({
      source: _.find(props.states, { id: parseInt(data.source.droppableId) }),
      destination: _.find(props.states, { id: parseInt(data.destination.droppableId) }),
    });
    setSelectedDocumentId(parseInt(data.draggableId));
    setMoveDialogOpened(true);
  };

  return (
    <PrimarySection ignoreHeader className="widget-content queue-widget-content">
      {props.states.length ? (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            {props.states.map((state, index) => {
              return (
                <DocumentList
                  key={index}
                  state={state}
                  moveTo={moveTo}
                  moveFrom={moveFrom}
                  processingState={processingState.includes(state.id)}
                  getDocuments={getDocuments}
                />
              );
            })}
          </DragDropContext>
          <MoveStateDialog
            open={moveDialogOpened}
            onSubmit={moveState}
            onClose={handleCloseMoveDialog}
            from={droppedState?.source?.name}
            to={droppedState?.destination?.name}
          />
        </>
      ) : (
        <SecondaryText>No states</SecondaryText>
      )}
    </PrimarySection>
  );
};

export default connector(WidgetContent);
