import React, { FC, useCallback } from 'react';
import classNames from 'classnames';

import FormDialog from '../../../../shared/components/dialog/FormDialog';
import FormGroup from '../../../../shared/components/form/formGroup/FormGroup';
import InputControl from '../../../../shared/components/form/controls/input/InputControl';
import CheckboxControl from '../../../../shared/components/form/controls/checkbox/CheckboxControl';
import { AnyFunction } from '../../../../shared/types/generics';
import { StatesState } from '../../../../store/interfaces/StatesState';
import { StateFormData } from './StateFormData';
import { StateFormSchema } from './StateFormSchema';
import { StateFormFields } from './StateFormFields';
import { Entity } from '../../../../shared/enums/Entity/Entity';
import { Color as CheckboxColor } from '../../../../shared/components/form/controls/checkbox/enums/Color';

import './StateFormDialog.scss';

interface StateFormDialogProps {
  open: boolean;
  onSubmit: AnyFunction;
  onClose: AnyFunction;
  state?: StatesState;
  statesNumber: number;
}

const StateFormDialog: FC<StateFormDialogProps> = (props) => {
  const onSubmit = useCallback(
    async (formData: StateFormData) => {
      await props.onSubmit({
        ...formData,
        [StateFormFields.Order]: props.state?.order || props.statesNumber + 1,
      });
      props.onClose();
    },
    [props],
  );

  return (
    <FormDialog
      open={props.open}
      onClose={props.onClose}
      title={
        <div className="dialog-title">
          {props.state?.id ? (
            <>
              Edit <span className="dialog-title__entity-name">{props.state.name}</span>
            </>
          ) : (
            `Add new ${Entity.State}`
          )}
        </div>
      }
      className="state-dialog"
      onSubmit={onSubmit}
      validationSchema={StateFormSchema}
    >
      <FormGroup>
        <InputControl
          name={StateFormFields.Name}
          label="Name"
          required
          fullWidth
          defaultValue={props.state?.name}
          autoFocus
        />
      </FormGroup>
      {/* <FormGroup className={classNames({ _hidden: !props.statesNumber || props.state?.defaultState })}>
        <CheckboxControl
          name={StateFormFields.DefaultState}
          label="State is default"
          color={CheckboxColor.Primary}
          checked={!props.statesNumber ? true : props.state?.defaultState}
        />
      </FormGroup> */}
      <FormGroup>
        <CheckboxControl
          name={StateFormFields.ValidDataState}
          label="Default for valid data"
          color={CheckboxColor.Secondary}
          checked={props.state?.validDataState}
          disabled={props.state?.validDataState}
        />
        <CheckboxControl
          name={StateFormFields.InvalidDataState}
          label="Default for invalid data"
          color={CheckboxColor.Secondary}
          checked={props.state?.invalidDataState}
          disabled={props.state?.invalidDataState}
        />
        <CheckboxControl
          name={StateFormFields.MissingDataState}
          label="Default for missing data"
          color={CheckboxColor.Secondary}
          checked={props.state?.missingDataState}
          disabled={props.state?.missingDataState}
        />
        <CheckboxControl
          name={StateFormFields.ParserErrorState}
          label="Default for data parse error"
          color={CheckboxColor.Secondary}
          checked={props.state?.parserErrorState}
          disabled={props.state?.parserErrorState}
        />
      </FormGroup>
    </FormDialog>
  );
};

export default StateFormDialog;
