import { connect } from 'react-redux';

import { RootState } from '../store/interfaces/RootState';
import { hasAdminPermission, hasUserPermission } from '../store/user/selectors';

export interface StateProps {
  hasAdminPermission: boolean;
  hasUserPermission: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    hasAdminPermission: hasAdminPermission(state),
    hasUserPermission: hasUserPermission(state),
  };
};

export const connector = connect(mapStateToProps);
