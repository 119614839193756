import React, { FC, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import classNames from 'classnames';
import { useLocalStorage } from 'react-use';

import { StatesState } from '../../../../../store/interfaces/StatesState';
import Paper from '../../../../../shared/components/paper/Paper';
import PrimarySection from '../../../../../shared/layouts/primarySection/PrimarySection';
import { AnyObject, CustomAny, Nullable } from '../../../../../shared/types/generics';
import { QUEUE_WIDGET_TABLE_COLUMNS } from '../../../helpers/common';
import { TableConf } from '../../../../../shared/components/table/interfaces/TableConf';
import { TABLE_CONF_PREFIX } from '../../../../../shared/constants/application';
import { DocumentsTableState } from '../../../../../store/interfaces/DocumentsTableState';
import { Entity } from '../../../../../shared/enums/Entity/Entity';
import ServerTable from '../../../../../shared/components/table/ServerTable';
import { serverTableInitialState } from '../../../constants/constants';

interface DocumentListProps {
  state: StatesState;
  processingState: boolean;
  getDocuments: (bodyParams?: AnyObject, shouldUpdateList?: boolean) => Promise<DocumentsTableState>;
  moveTo: Nullable<AnyObject>;
  moveFrom: Nullable<AnyObject>;
}

const DocumentList: FC<DocumentListProps> = (props) => {
  const { state, processingState, getDocuments: fetchDocuments, moveFrom, moveTo } = props;
  const [documentsState, setDocumentsState] = useState<DocumentsTableState>(serverTableInitialState);
  const [processing, setProcessing] = useState<boolean>(false);
  const [tableConf, setTableConf] = useLocalStorage<TableConf>(`${TABLE_CONF_PREFIX}_DASH_QUEUE_${state.id}`, {});

  const getDocuments = useCallback(
    async (filterParams: AnyObject) => {
      setProcessing(true);
      const docList = await fetchDocuments(
        {
          ...filterParams,
          stateIdFilter: state.id,
        },
        false,
      );
      setDocumentsState(docList);
      setProcessing(false);
    },
    [fetchDocuments, state.id],
  );

  useEffect(() => {
    if (moveFrom && moveTo && (moveFrom.id === state.id || moveTo.id === state.id)) {
      getDocuments({
        page: 0,
        rowsPerPage: 5,
        search: '',
        size: 5,
      });
    }
  }, [getDocuments, moveFrom, moveTo, state.id]);

  const sortOrder = useMemo(() => {
    if (tableConf?.fieldToSort) {
      return {
        fieldToSort: tableConf.fieldToSort,
        ascended: !!tableConf.ascended,
      };
    }

    return undefined;
  }, [tableConf]);
  const handleSortChange = useCallback(
    ({ ascended, fieldToSort }) => {
      setTableConf({
        ...tableConf,
        ascended,
        fieldToSort,
      });
    },
    [setTableConf, tableConf],
  );

  const customRowRender = (data: CustomAny[], dataIndex: number): ReactNode => {
    const [id] = data;
    const arrayWithoutId: CustomAny[] = [...data];
    arrayWithoutId.shift();
    return (
      <Draggable key={id} draggableId={id.toString()} index={dataIndex}>
        {(provided, snapshot): ReactElement => (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={classNames({ _dragging: snapshot.isDragging })}
          >
            {arrayWithoutId.map((dataValue, index) => {
              const title = ['string', 'number'].includes(typeof dataValue) ? dataValue : undefined;
              return (
                <TableCell key={index} title={title}>
                  {dataValue}
                </TableCell>
              );
            })}
          </TableRow>
        )}
      </Draggable>
    );
  };

  const TableBodyComponent = (droppablePlaceholder: CustomAny) => (props: CustomAny): ReactElement => {
    return (
      <tbody>
        {props.data.length > 0 ? (
          props.data.map((dataObject: CustomAny, index: number) => customRowRender(dataObject.data, index))
        ) : (
          <TableRow>
            <TableCell colSpan={3}>No documents</TableCell>
          </TableRow>
        )}
        {droppablePlaceholder}
      </tbody>
    );
  };

  return (
    <Paper className={classNames('draggable-area-container queue-card')} elevation={1} padding={false}>
      <Toolbar variant="dense" className="draggable-area-container__toolbar">
        <span>{state.name}</span>
        <span>
          Total: <b>{documentsState.totalElements}</b>
        </span>
      </Toolbar>
      <Divider />
      <Droppable droppableId={state.id.toString()}>
        {(provided): ReactElement => (
          <PrimarySection ignoreHeader processing={processing || processingState} innerRef={provided.innerRef}>
            <ServerTable
              columns={QUEUE_WIDGET_TABLE_COLUMNS}
              entity={Entity.Document}
              hideToolbar
              getData={getDocuments}
              data={documentsState.content}
              count={documentsState.totalElements}
              customRowRender={customRowRender}
              search={false}
              fixedHeader
              totalPages={documentsState.totalPages}
              currentPageIndex={documentsState.number}
              rowsPerPage={5}
              rowsPerPageOptions={[5, 10]}
              sortOrder={sortOrder}
              onSort={handleSortChange}
              components={{
                TableBody: TableBodyComponent(provided.placeholder),
              }}
            />
          </PrimarySection>
        )}
      </Droppable>
    </Paper>
  );
};

export default DocumentList;
