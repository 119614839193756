import { connect } from 'react-redux';

import { LogTableState } from '../../store/interfaces/LogTableState';
import { RootState } from '../../store/interfaces/RootState';
import { getLogs } from '../../store/logs/selectors';
import { isProcessingPath } from '../../store/requestsProcessing/selectors';
import { LogsPaths } from '../../shared/api/logs/LogsPaths';
import { AnyDispatch, AnyObject, CustomAny } from '../../shared/types/generics';
import { getLogsList, getOperationsList } from '../../store/logs/actions';
import { getUsers } from '../../store/users/actions';
import { UsersPaths } from '../../shared/api/users/UsersPaths';
import { UserState } from '../../store/interfaces/UserState';
import { getUsersList } from '../../store/users/selectors';
import { normalizeOperationType } from './constants/table';

export interface StateProps {
  logs: LogTableState;
  users: UserState[];
  processing: boolean;
}

export interface DispatchProps {
  getLogs(): void;
  getUsers(): void;
  getOperations(): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    logs: getLogs(state),
    users: getUsersList(state),
    processing:
      isProcessingPath(state)(LogsPaths.List) ||
      isProcessingPath(state)(LogsPaths.Operations) ||
      isProcessingPath(state)(UsersPaths.User),
  };
};

const mapDispatchToProps = (dispatch: AnyDispatch): DispatchProps => {
  return {
    getLogs: (params: AnyObject = {}): Promise<CustomAny> => {
      if (params.logTypeFilter) {
        params.logTypeFilter = normalizeOperationType(params.logTypeFilter);
      }
      return dispatch(getLogsList(params));
    },
    getUsers: (): Promise<CustomAny> => dispatch(getUsers(UsersPaths.User)),
    getOperations: (): Promise<CustomAny> => dispatch(getOperationsList()),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
