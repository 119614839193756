import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import Input, { InputProps } from './Input';
import { AnyObject } from '../../../../types/generics';

import './InputControl.scss';

interface InputControlProps extends InputProps {
  name: string;
  handleError?(errors: AnyObject): [boolean, string];
}

const InputControl: FC<InputControlProps> = (props) => {
  const { errors, register } = useFormContext();
  let error = !!errors?.[props.name];
  let helperText = errors?.[props.name]?.message || props.helperText;
  if (props.handleError) {
    const [isError, message] = props.handleError(errors);
    if (isError) {
      error = isError;
      helperText = message || props.helperText;
    }
  }

  return (
    <Input
      name={props.name}
      inputRef={register({ required: props.required })}
      label={props.label}
      type={props.type}
      required={props.required}
      error={error}
      helperText={helperText}
      fullWidth={props.fullWidth}
      multiline={props.multiline}
      defaultValue={props.defaultValue}
      autoComplete={props.autoComplete}
      InputProps={props.InputProps}
      className="input-control"
      autoFocus={props.autoFocus}
      disabled={props.disabled}
    />
  );
};

export default InputControl;
