import React, { FC, useCallback } from 'react';
import classNames from 'classnames';

import { CustomAny, AnyFunction } from '../../../shared/types/generics';
import { TemplateState } from '../../../store/interfaces/TemplateState';
import { TemplateFormData } from './TemplateFormData';
import FormDialog from '../../../shared/components/dialog/FormDialog';
import { NodeType } from '../../../store/enums/NodeType';
import FormGroup from '../../../shared/components/form/formGroup/FormGroup';
import InputControl from '../../../shared/components/form/controls/input/InputControl';
import SelectControl from '../../../shared/components/form/controls/select/SelectControl';
import FileInput from '../../../shared/components/form/controls/fileInput/FileInput';
import { TemplateFormSchema } from './TemplateFormSchema';
import { TemplateFormFields } from './TemplateFormFields';

interface TemplateFormDialogProps {
  open: boolean;
  onSubmit: AnyFunction;
  onClose: AnyFunction;
  parentId?: string;
  directories?: CustomAny;
}

const TemplateFormDialog: FC<TemplateFormDialogProps> = (props) => {
  const onSubmit = useCallback(
    async (formData: TemplateFormData) => {
      await props.onSubmit(formData);
    },
    [props],
  );

  return (
    <FormDialog
      open={props.open}
      onClose={props.onClose}
      title={<div className="dialog-title">{`Add new ${NodeType.Template.toLowerCase()}`}</div>}
      className="template-node-dialog template-node-dialog_new"
      onSubmit={onSubmit}
      validationSchema={TemplateFormSchema}
    >
      <FormGroup>
        <InputControl name={TemplateFormFields.Name} label="Name" required fullWidth autoFocus />
      </FormGroup>
      <FormGroup>
        <SelectControl
          name={TemplateFormFields.Parent}
          options={props.directories}
          label="Parent folder"
          fullWidth
          value={props.parentId || undefined}
        />
      </FormGroup>
      <FormGroup>
        <FileInput name={TemplateFormFields.File} />
      </FormGroup>
    </FormDialog>
  );
};

export default TemplateFormDialog;
