import React, { FC, useCallback, useState } from 'react';
import { MUIDataTableMeta } from 'mui-datatables';
import _ from 'lodash';

import IconButton from '../../iconButton/IconButton';
import { Size } from '../enums/Size';
import { Action } from '../interfaces/Action';
import { AnyObject, CustomAny } from '../../../types/generics';
import CircularLoading from '../../circularLoading/CircularLoading';

import './TableActions.scss';

interface TableActionsProps {
  id: string;
  tableMeta: MUIDataTableMeta;
  actions: Action[];
  data: AnyObject[];
  idField: string;
}

const TableActions: FC<TableActionsProps> = (props) => {
  const { id, tableMeta, actions, data, idField } = props;
  const [loadingFields, setLoadingFields] = useState<number[]>([]);
  const onClick = useCallback(
    (action: Action, index: number) => async (): Promise<CustomAny> => {
      if (action.withProgress) {
        setLoadingFields([...loadingFields, index]);
        await action.onClick(_.find(data, { [idField]: id || tableMeta.rowData[0] }));
        setLoadingFields(loadingFields.filter((progressIndex) => progressIndex !== index));
      } else {
        return action.onClick(_.find(data, { [idField]: id || tableMeta.rowData[0] }));
      }
    },
    [data, id, idField, loadingFields, tableMeta.rowData],
  );
  return (
    <div className="table-actions">
      {actions?.map((action, index) =>
        action.isHidden?.(_.find(data, { [idField]: id || tableMeta.rowData[0] })) ? null : loadingFields.includes(
            index,
          ) ? (
          <div className="table-actions__progress" key={index}>
            <CircularLoading size={18} />
          </div>
        ) : (
          <IconButton
            onClick={onClick(action, index)}
            ariaLabel={action.label}
            size={Size.Small}
            key={index}
            disabled={action.isDisabled?.(_.find(data, { [idField]: id || tableMeta.rowData[0] }))}
          >
            <action.icon />
          </IconButton>
        ),
      )}
    </div>
  );
};

export default TableActions;
