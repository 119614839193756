import React, { FC, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import Select, { SelectProps } from './Select';

interface SelectControlProps extends SelectProps {
  name: string;
}

const SelectControl: FC<SelectControlProps> = (props) => {
  const { errors, control, watch } = useFormContext();
  const error = !!errors?.[props.name];
  const helperText = errors?.[props.name]?.message;
  const changedValue = watch(props.name);
  useEffect(() => {
    props.onChange?.(changedValue);
  }, [changedValue, props.onChange]);

  return (
    <Controller
      control={control}
      as={
        <Select
          options={props.options}
          label={props.label}
          helperText={helperText}
          error={error}
          fullWidth={props.fullWidth}
          multiple={props.multiple}
          required={props.required}
          disabled={props.disabled}
        />
      }
      defaultValue={props.value}
      name={props.name}
    />
  );
};

export default SelectControl;
