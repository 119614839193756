import React, { FC, useMemo } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';

import PrimarySection from '../../../../shared/layouts/primarySection/PrimarySection';
import Table from '../../../../shared/components/table/Table';
import { VERSIONS_COLUMNS } from '../../constants/dialog';
import TabContent from '../../../../shared/components/tabs/tabContent/TabContent';
import { DocumentState } from '../../../../store/interfaces/DocumentState';
import { Action } from '../../../../shared/components/table/interfaces/Action';
import { TableAction } from '../../../../shared/components/table/enums/TableAction';
import { Entity } from '../../../../shared/enums/Entity/Entity';
import { AnyObject } from '../../../../shared/types/generics';

interface VersionsTabProps {
  document: DocumentState;
  onDownload(data: DocumentState, version?: number): void;
}

const VersionsTab: FC<VersionsTabProps> = (props) => {
  const versionsActions: Action[] = useMemo(
    () => [
      {
        label: `${TableAction.Download} ${Entity.Document}`,
        onClick: (data): void => props.onDownload(props.document, data.version),
        icon: GetAppIcon,
        withProgress: true,
        isDisabled: (data): boolean => data.archived,
      },
    ],
    [props],
  );
  return (
    <TabContent>
      <PrimarySection ignoreHeader>
        <Table
          columns={VERSIONS_COLUMNS}
          data={props.document?.documentFileVersions.map((documentVersion: AnyObject) => ({
            ...documentVersion,
            archived: props.document.archived,
          }))}
          search={false}
          hideToolbar
          fixedHeader
          actions={versionsActions}
          customIdField="version"
          pagination={false}
        />
      </PrimarySection>
    </TabContent>
  );
};

export default VersionsTab;
