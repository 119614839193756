import { handleActions } from 'redux-actions';

import { HotfolderTableState } from '../interfaces/HotfolderTableState';

const initialState: HotfolderTableState = {
  content: [],
  totalElements: 0,
  numberOfElements: 0,
  totalPages: 0,
  number: 0,
};

export default handleActions(
  {
    FOLDERS_UPDATE: (state, { payload: folders }) => ({ ...state, ...folders }),
  },
  initialState,
);
