import React, { FC } from 'react';

import { Align } from '../../layouts/primarySection/enums/Align';
import PrimarySection from '../../layouts/primarySection/PrimarySection';
import AppLogo from '../appLogo/AppLogo';

const Loading: FC = () => {
  return (
    <PrimarySection align={Align.Center}>
      <AppLogo />
    </PrimarySection>
  );
};

export default Loading;
