import React, { FC } from 'react';

import Dialog from '../../../shared/components/dialog/Dialog';
import { AnyFunction } from '../../../shared/types/generics';
import { Color } from '../../../shared/components/button/enums/Color';

import './LogCommentDialog.scss';

interface LogCommentDialogProps {
  open: boolean;
  onClose: AnyFunction;
}

const LogCommentDialog: FC<LogCommentDialogProps> = (props) => {
  const actions = [
    {
      label: 'Close',
      onClick: props.onClose,
      color: Color.Primary,
    },
  ];
  return (
    <Dialog open={props.open} onClose={props.onClose} actions={actions} title="Move comment">
      <p className="dialog-content">{props.children}</p>
    </Dialog>
  );
};

export default LogCommentDialog;
