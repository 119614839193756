import { handleActions } from 'redux-actions';
import { UserState } from '../interfaces/UserState';

const initialState: UserState = {
  id: 0,
  login: '',
  email: '',
  permissions: [],
};

export default handleActions(
  {
    USER_SET: (state, { payload: user }) => ({ ...state, ...user }),
    USER_DATA_CLEAR: () => ({ ...initialState }),
  },
  initialState,
);
