import { createAction } from 'redux-actions';

import { AnyDispatch, CustomAny } from '../../shared/types/generics';
import { finishProcessingPath, startProcessingPath } from '../requestsProcessing/actions';
import { MetadataPaths } from '../../shared/api/metadata/MetadataPaths';
import * as metadataService from '../../shared/api/metadata/MetadataApiService';
import { MetadataFormData } from '../../pages/admin/metadata/metadataFormDialog/MetadataFormData';

const updateMetadata = createAction('METADATA_UPDATE');

export const getMetadataList = (url = MetadataPaths.Admin) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(url));
  try {
    const metadata = await metadataService.get(url);
    dispatch(updateMetadata(metadata));
  } finally {
    dispatch(finishProcessingPath(url));
  }
};

export const addMetadataField = (field: MetadataFormData) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(MetadataPaths.Admin));
  try {
    await metadataService.add(field);
    await dispatch(getMetadataList());
  } finally {
    dispatch(finishProcessingPath(MetadataPaths.Admin));
  }
};

export const editMetadataField = (id: number, field: MetadataFormData) => async (
  dispatch: AnyDispatch,
): Promise<CustomAny> => {
  dispatch(startProcessingPath(MetadataPaths.Admin));
  try {
    await metadataService.update(id, field);
    await dispatch(getMetadataList());
  } finally {
    dispatch(finishProcessingPath(MetadataPaths.Admin));
  }
};

export const removeMetadataField = (id: number) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(MetadataPaths.Admin));
  try {
    await metadataService.remove(id);
    await dispatch(getMetadataList());
  } finally {
    dispatch(finishProcessingPath(MetadataPaths.Admin));
  }
};
