import React, { ReactElement } from 'react';
import moment from 'moment';

import { TabObject } from '../../../shared/components/tabs/interfaces/TabObject';
import { Column } from '../../../shared/components/table/interfaces/Column';
import { CustomAny } from '../../../shared/types/generics';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT } from '../../../shared/constants/application';
import { UserState } from '../../../store/interfaces/UserState';
import { prettifyOperationType } from '../../logs/constants/table';

export const DIALOG_TABS: TabObject[] = [
  {
    label: 'General',
    value: 'General',
  },
  {
    label: 'Fields',
    value: 'Fields',
  },
  {
    label: 'History',
    value: 'History',
  },
  {
    label: 'Versions',
    value: 'Versions',
  },
];

export const VERSIONS_COLUMNS: Column[] = [
  { title: 'Version', field: 'version', sort: false },
  {
    title: 'Date',
    field: 'creationDate',
    sort: false,
    getValue: (value: CustomAny): string => moment(new Date(value)).format(DEFAULT_DATE_FORMAT),
  },
];

export const FIELDS_COLUMNS: Column[] = [
  { title: 'Name', field: 'name', sort: false },
  { title: 'Value', field: 'value', sort: false },
  {
    title: 'Image',
    field: 'image',
    sort: false,
    getValue: (value: string): ReactElement =>
      value ? (
        <img height={30} width="auto" src={`data:image/png;base64, ${value}`} alt="" />
      ) : (
        <span>No image provided</span>
      ),
  },
  {
    title: 'Parsing Status',
    field: 'fieldParsingStatus',
    sort: true,
  },
];

export const HISTORY_COLUMNS: Column[] = [
  {
    title: 'Operation',
    field: 'type',
    sort: true,
    getValue: prettifyOperationType,
  },
  {
    title: 'Date',
    field: 'createdAt',
    sort: true,
    getValue: (value: CustomAny): string => moment(new Date(value)).format(DEFAULT_DATE_TIME_FORMAT),
  },
  {
    title: 'User',
    field: 'user',
    sort: true,
    sortName: 'user.login',
    getValue: (value: UserState): string => value?.login || '',
  },
  {
    title: 'Description',
    field: 'description',
    sort: false,
  },
];

export const MAX_TAG_LENGTH = 64;
export const MAX_TAG_LENGTH_ERROR = 'Value of tags must be at most 64 characters';
