import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { InputProps, InputAdornment } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import IconButton from '@material-ui/core/IconButton';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  DatePicker,
  DateTimePicker,
} from '@material-ui/pickers';

import { ControlRef } from '../../types/ControlRef';
import { AnyFunction, Nullable } from '../../../../types/generics';
import { Format } from '../../../../../store/enums/Format';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT } from '../../../../constants/application';

export interface DatePickerProps {
  name?: string;
  inputRef?: ControlRef;
  label?: string;
  placeholder?: string;
  type?: Format.Date | Format.DateTime;
  error?: boolean;
  helperText?: string;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  value?: Nullable<Date | string>;
  id?: string;
  onChange: AnyFunction;
  onBlur?: AnyFunction;
  disableKeyboard?: boolean;
}

const DateTimeInput: FC<DatePickerProps> = (props) => {
  const pickerProps = useMemo(() => {
    const properties = {
      disableToolbar: true,
      fullWidth: props.fullWidth,
      name: props.name,
      label: props.label,
      error: props.error,
      disabled: props.disabled,
      helperText: props.helperText,
      className: classNames('input input-control', props.className),
      onChange: props.onChange,
      id: props.id,
      value: props.value,
      autoOk: true,
      onBlur: props.onBlur,
      KeyboardButtonProps: {
        'aria-label': 'change date',
      },
      InputProps: {
        margin: 'dense',
      } as Partial<InputProps>,
    };
    if (props.disableKeyboard) {
      delete properties.KeyboardButtonProps;
      delete properties.InputProps;
    }
    return properties;
  }, [
    props.className,
    props.disableKeyboard,
    props.error,
    props.fullWidth,
    props.helperText,
    props.id,
    props.label,
    props.name,
    props.onBlur,
    props.onChange,
    props.value,
    props.disabled,
  ]);
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
      {props.type === Format.DateTime ? (
        props.disableKeyboard ? (
          <DateTimePicker
            {...pickerProps}
            format={DEFAULT_DATE_TIME_FORMAT}
            allowKeyboardControl={false}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <EventIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <KeyboardDateTimePicker {...pickerProps} format={DEFAULT_DATE_TIME_FORMAT} />
        )
      ) : props.disableKeyboard ? (
        <DatePicker
          {...pickerProps}
          format={DEFAULT_DATE_FORMAT}
          allowKeyboardControl={false}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <EventIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <KeyboardDatePicker {...pickerProps} format={DEFAULT_DATE_FORMAT} />
      )}
    </MuiPickersUtilsProvider>
  );
};

export default DateTimeInput;
