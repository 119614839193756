import React, { FC } from 'react';
import TreeView from '@material-ui/lab/TreeView';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

import { TreeNode } from './interfaces/TreeNode';
import { OnChange } from '../tabs/types/OnChange';
import TreeItemList from './treeItemList/TreeItemList';

import './Tree.scss';

interface TreeProps {
  structure: TreeNode[];
  expanded?: [];
  onNodeToggle?: OnChange;
}

const Tree: FC<TreeProps> = (props) => {
  return (
    <TreeView
      expanded={props.expanded}
      className="tree"
      defaultCollapseIcon={<FolderOpenIcon />}
      defaultExpandIcon={<FolderIcon />}
      onNodeToggle={props.onNodeToggle}
    >
      <TreeItemList structure={props.structure} />
    </TreeView>
  );
};

export default Tree;
