import React, { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import MUIDataTable, { MUIDataTableTextLabels } from 'mui-datatables';
import _ from 'lodash';

import { AnyFunction, AnyObject, CustomAny } from '../../types/generics';
import { Action } from './interfaces/Action';
import { Column } from './interfaces/Column';
import { generateOptions, generateColumns, generateActions, getSortParams } from './helpers/tableHelper';
import TableTitle from './tableTitle/TableTitle';
import { TableSettings } from './interfaces/TableSettings';
import { DEFAULT_TABLE_SETTINGS } from './constants/common';

import './Table.scss';

export interface TableProps {
  columns: Column[];
  data: AnyObject[];
  actions?: Action[];
  title?: string;
  entity?: string;
  onAdd?: MouseEventHandler;
  textLabels?: Partial<MUIDataTableTextLabels>;
  tableSettings?: TableSettings;
  onChangeTableSettings?: (settings: TableSettings) => void;
  pagination?: boolean;
  search?: boolean;
  hideToolbar?: boolean;
  fixedHeader?: boolean;
  tableBodyMaxHeight?: string;
  customIdField?: string;
  rowsPerPageOptions?: number[];
  customRowRender?: (data: CustomAny[], dataIndex: number, rowIndex: number) => React.ReactNode;
  setRowProps?: (row: CustomAny[], rowIndex: number) => AnyObject;
  components?: CustomAny;
  rowsPerPage?: number;
  onRowPerPage?: AnyFunction;
  sortOrder?: { fieldToSort: string; ascended: boolean };
  onSort?: AnyFunction;
}

const Table: FC<TableProps> = (props) => {
  const { customRowRender, components } = props;
  const [sortParams, setSortParams] = useState<{ fieldToSort?: string; ascended?: boolean }>(props.sortOrder || {});
  const columns = useMemo(() => {
    const generatedColumns = generateColumns(props.columns);
    if (props.actions?.length) {
      generatedColumns.push(generateActions(props.actions, props.data, props.customIdField));
    }
    return generatedColumns;
  }, [props.actions, props.columns, props.customIdField, props.data]);

  const handleChangeRowsPerPage = useCallback(
    (rowsPerPage) => {
      if (props.onChangeTableSettings) {
        props.onChangeTableSettings({ ...(props.tableSettings || DEFAULT_TABLE_SETTINGS), rowsPerPage });
      }
    },
    [props],
  );
  const handleChangePage = useCallback(
    (page) => {
      if (props.onChangeTableSettings) {
        props.onChangeTableSettings({ ...(props.tableSettings || DEFAULT_TABLE_SETTINGS), page });
      }
    },
    [props],
  );
  const onColumnSortChange = useCallback(
    (changedColumn, direction) => {
      props.onSort?.(getSortParams(changedColumn, direction, columns));
      setSortParams(getSortParams(changedColumn, direction, columns));
    },
    [columns, props.onSort],
  );

  const options = generateOptions({
    title: <TableTitle title={props.title} onAdd={props.onAdd} />,
    options: {
      textLabels: props.textLabels,
      rowsPerPage: props.tableSettings?.rowsPerPage,
      onChangeRowsPerPage: handleChangeRowsPerPage,
      page: props.tableSettings?.page,
      onChangePage: handleChangePage,
      pagination: props.pagination !== false,
      search: props.search !== false,
      selectToolbarPlacement: props.hideToolbar ? 'none' : 'above',
      fixedHeader: props.fixedHeader,
      tableBodyMaxHeight: props.tableBodyMaxHeight,
      rowsPerPageOptions: props.rowsPerPageOptions || DEFAULT_TABLE_SETTINGS.rowsPerPageOptions,
      customRowRender,
      onColumnSortChange,
    },
  });
  if (sortParams.fieldToSort) {
    options.sortOrder = {
      name: _.find(props.columns, { sortName: sortParams.fieldToSort })?.field || sortParams.fieldToSort,
      direction: sortParams.ascended ? 'asc' : 'desc',
    };
  }

  return (
    <div className="table">
      <MUIDataTable
        data={props.data}
        columns={columns}
        title={!props.hideToolbar && <TableTitle title={props.title} onAdd={props.onAdd} entity={props.entity} />}
        options={options}
        components={components}
      />
    </div>
  );
};

export default Table;
