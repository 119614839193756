import React, { FC } from 'react';
import classNames from 'classnames';
import MaterialList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { ListItemIcon } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';

import { ListObject } from './interfaces/ListObject';

interface ListProps {
  dense?: boolean;
  disablePadding?: boolean;
  className?: string;
  listObject: ListObject[];
}

const List: FC<ListProps> = (props) => {
  return (
    <MaterialList
      dense={props.dense}
      disablePadding={props.disablePadding}
      className={classNames('list', props.className)}
    >
      {props.listObject.map((item, index) => (
        <ListItem className="list__item" key={index} onClick={item.onClick} button selected={item.selected}>
          {item.icon && (
            <ListItemIcon>
              <item.icon />
            </ListItemIcon>
          )}
          <ListItemText primary={item.label} />
        </ListItem>
      ))}
    </MaterialList>
  );
};

export default List;
