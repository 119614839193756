import { connect } from 'react-redux';

import { RootState } from '../../store/interfaces/RootState';
import { getUserId } from '../../store/user/selectors';

export interface StateProps {
  userId: number;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    userId: getUserId(state),
  };
};

export const connector = connect(mapStateToProps);
