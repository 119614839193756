import React, { FC, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';

import Title from '../../../../shared/components/title/Title';
import { Variants } from '../../../../shared/components/title/enums/Variants';
import ChipList from '../../../../shared/components/chipList/ChipList';
import SecondaryText from '../../../../shared/components/secondaryText/SecondaryText';
import { ChipItem } from '../../../../shared/components/chipList/interfaces/ChipItem';
import { AnyFunction, AnyObject } from '../../../../shared/types/generics';
import { MAX_TAG_LENGTH, MAX_TAG_LENGTH_ERROR } from '../../constants/dialog';

interface TagListProps {
  tagList: ChipItem[];
  onChange: AnyFunction;
}

const TagList: FC<TagListProps> = (props) => {
  const { errors, clearErrors, setError } = useFormContext();
  const errorMsg = useMemo(() => {
    if (errors.tags) {
      return (_.compact(errors.tags) as AnyObject[])[0].message;
    }

    return '';
  }, [errors.tags]);
  const onChange = useCallback(
    (value) => {
      clearErrors('tags');
      value.forEach((tag: string, index: number) => {
        if (tag.length > MAX_TAG_LENGTH) {
          setError(`tags[${index}]`, { type: 'submit', message: MAX_TAG_LENGTH_ERROR });
        }
      });
      props.onChange(value);
    },
    [clearErrors, props, setError],
  );
  return (
    <section className="tags">
      <Title variant={Variants.H6}>
        Tags {errorMsg && <SecondaryText className="_error _capitalized">*{errorMsg}</SecondaryText>}
      </Title>
      <ChipList items={props.tagList} onChange={onChange} errorsArray={errors.tags} />
    </section>
  );
};

export default TagList;
