import { Link } from 'react-router-dom';

import { TabObject } from '../interfaces/TabObject';
import { TabType } from '../enums/TabType';
import { AnyObject } from '../../../types/generics';

export const buildTabProps = (tab: TabObject): AnyObject => {
  let props = {};

  switch (tab.type) {
    case TabType.Link:
      props = {
        component: Link,
        to: tab.value,
      };
      break;
    default:
      break;
  }

  return props;
};
