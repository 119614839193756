import { applyMiddleware, createStore, Store } from 'redux';
import thunk from 'redux-thunk';

import { RootState } from './interfaces/RootState';
import rootReducer from './rootReducer';
import { isAuthKeyEmpty } from '../shared/api/helpers/authKeyHelper';
import { getUser } from './user/actions';
import { AnyDispatch } from '../shared/types/generics';

export default function configureStore(): Store<RootState> {
  const store: Store<RootState> = createStore(rootReducer, applyMiddleware(thunk));

  if (!isAuthKeyEmpty()) {
    (store.dispatch as AnyDispatch)(getUser());
  }

  return store;
}
