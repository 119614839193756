import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import _ from 'lodash';

import { connector, StateProps, DispatchProps } from './connector';
import PrimarySection from '../../../shared/layouts/primarySection/PrimarySection';
import ServerTable from '../../../shared/components/table/ServerTable';
import RemoveDialog from '../../../shared/components/dialog/RemoveDialog';
import HotfoldersFormDialog from './hotfoldersFormDialog/HotfoldersFormDialog';
import { Nullable } from '../../../shared/types/generics';
import { Align } from '../../../shared/layouts/primarySection/enums/Align';
import { HotfolderState } from '../../../store/interfaces/HotfolderState';
import { HOTFOLDERS_COLUMNS } from './constants/table';
import { Action } from '../../../shared/components/table/interfaces/Action';
import { TableAction } from '../../../shared/components/table/enums/TableAction';
import { Entity } from '../../../shared/enums/Entity/Entity';
import { DEFAULT_TABLE_SETTINGS } from '../../../shared/components/table/constants/common';
import { HotfoldersFormFields } from './hotfoldersFormDialog/HotfoldersFormFields';
import { StatesState } from '../../../store/interfaces/StatesState';
import { TABLE_CONF_PREFIX } from '../../../shared/constants/application';
import { TableConf } from '../../../shared/components/table/interfaces/TableConf';
import CustomActionDialog from '../../../shared/components/dialog/CustomActionDialog';

import './Hotfolders.scss';

interface HotfoldersProps extends StateProps, DispatchProps {}

const Hotfolders: FC<HotfoldersProps> = (props) => {
  const [tableConf, setTableConf] = useLocalStorage<TableConf>(`${TABLE_CONF_PREFIX}${Entity.Metadata}`, {});
  const [tableSettings, setTableSettings] = useState({
    ...DEFAULT_TABLE_SETTINGS,
    ...tableConf,
  });
  const [openHotfolderDialog, setOpenHotfolderDialog] = useState(false);
  const [invisibleState, setInvisibleState] = useState<StatesState>();
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [selectedHotfolder, setSelectedHotfolder] = useState<Nullable<HotfolderState>>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const sortOrder = useMemo(() => {
    if (tableConf?.fieldToSort) {
      return {
        fieldToSort: tableConf.fieldToSort,
        ascended: !!tableConf.ascended,
      };
    }

    return undefined;
  }, [tableConf]);

  useEffect(() => {
    return setTableSettings({
      ...DEFAULT_TABLE_SETTINGS,
      ...tableConf,
    });
  }, [tableConf]);

  const handleAddButtonClick = useCallback(() => {
    setSelectedHotfolder(null);
    setOpenHotfolderDialog(true);
  }, []);

  const handleEditButtonClick = useCallback((folder) => {
    setSelectedHotfolder(folder);
    setOpenHotfolderDialog(true);
  }, []);

  const handleOpenRemoveDialog = useCallback((folder) => {
    setSelectedHotfolder(folder);
    setOpenRemoveDialog(true);
  }, []);

  const handleCloseRemoveDialog = useCallback(() => {
    setOpenRemoveDialog(false);
  }, []);

  const handleCloseErrorDialog = useCallback(() => {
    setErrorMessage('');
  }, []);

  const handleCloseFormDialog = useCallback(async () => {
    setOpenHotfolderDialog(false);
  }, []);

  const handleSubmitFormDialog = useCallback(
    (formData) => {
      formData.defaultState = false;
      if (invisibleState && invisibleState.id === formData.stateId) {
        formData.defaultState = true;
      }

      if (selectedHotfolder) {
        return props.onEditHotfolder(selectedHotfolder?.id, formData);
      }
      return props.onAddHotfolder(formData);
    },
    [invisibleState, props, selectedHotfolder],
  );

  const handleActivate = useCallback(
    (folder) => {
      setSelectedHotfolder(folder);
      if (folder.active) {
        return props.onPauseHotfolder(folder.id).then((res) => {
          if (res.error) {
            setErrorMessage(res.error);
          }
        });
      }
      return props.onActivateHotfolder(folder.id).then((res) => {
        if (res.error) {
          setErrorMessage(res.error);
        }
      });
    },
    [props],
  );

  const handleRemove = useCallback(() => {
    return props.onRemoveHotfolder(selectedHotfolder?.id);
  }, [props, selectedHotfolder]);

  const handleRowPerPageChange = useCallback(
    (value: number) => {
      setTableConf({
        ...tableConf,
        rowsPerPage: value,
      });
    },
    [setTableConf, tableConf],
  );

  const handleSortChange = useCallback(
    ({ ascended, fieldToSort }) => {
      setTableConf({
        ...tableConf,
        ascended,
        fieldToSort,
      });
    },
    [setTableConf, tableConf],
  );

  const tableActions: Action[] = useMemo(
    () => [
      {
        label: `${TableAction.Pause} ${Entity.Hotfolder}`,
        onClick: handleActivate,
        icon: StopIcon,
        action: TableAction.Archive,
        isHidden: (folder): boolean => !folder.active,
      },
      {
        label: `${TableAction.Activate} ${Entity.Hotfolder}`,
        onClick: handleActivate,
        icon: PlayArrowIcon,
        isHidden: (folder): boolean => folder.active,
      },
      {
        label: `${TableAction.Edit} ${Entity.Hotfolder}`,
        onClick: handleEditButtonClick,
        icon: EditIcon,
        isDisabled: (folder): boolean => folder.active,
      },
      {
        label: `${TableAction.Delete} ${Entity.Hotfolder}`,
        onClick: handleOpenRemoveDialog,
        icon: DeleteIcon,
        isDisabled: (folder): boolean => folder.active,
      },
    ],
    [handleOpenRemoveDialog, handleActivate, handleEditButtonClick],
  );

  HOTFOLDERS_COLUMNS[_.findIndex(HOTFOLDERS_COLUMNS, { field: HotfoldersFormFields.User })].getValue = (
    userId,
  ): string => _.find(props.users, { id: parseInt(userId) })?.login || '';

  HOTFOLDERS_COLUMNS[_.findIndex(HOTFOLDERS_COLUMNS, { field: HotfoldersFormFields.State })].getValue = (
    stateId,
  ): string =>
    _.find([{ ...invisibleState, name: '(automatic)' }, ...props.states], { id: parseInt(stateId) })?.name || '';

  const getInvisibleState = async (): Promise<void> => {
    const state = await props.getInvisibleState();
    state && setInvisibleState(state);
  };

  useEffect(
    useCallback(() => {
      props.getStatesList();
      props.getUsers();
      props.getHotfoldersList();
      props.getTemplates();
      getInvisibleState();
    }, [props]),
    [],
  );

  return (
    <PrimarySection verticalAlign={Align.Start} ignoreHeader className="hotfolders" processing={props.processing}>
      <ServerTable
        columns={HOTFOLDERS_COLUMNS}
        entity={Entity.Hotfolder}
        getData={props.getHotfoldersList}
        data={props.hotfolders.content}
        count={props.hotfolders.totalElements}
        actions={tableActions}
        onAdd={handleAddButtonClick}
        textLabels={{
          toolbar: { search: 'Content search' },
          body: {
            noMatch: 'No folders',
          },
        }}
        searchName="content"
        search
        totalPages={props.hotfolders.totalPages}
        currentPageIndex={props.hotfolders.number}
        rowsPerPage={(tableConf as TableConf).rowsPerPage}
        onRowPerPage={handleRowPerPageChange}
        sortOrder={sortOrder}
        onSort={handleSortChange}
      />
      <HotfoldersFormDialog
        open={openHotfolderDialog}
        onSubmit={handleSubmitFormDialog}
        onClose={handleCloseFormDialog}
        userList={props.users}
        stateList={invisibleState ? [invisibleState, ...props.states] : props.states}
        templateList={props.templates}
        activeUser={props.activeUser}
        folder={selectedHotfolder}
      />
      <RemoveDialog
        open={openRemoveDialog}
        onClose={handleCloseRemoveDialog}
        onSubmit={handleRemove}
        name={selectedHotfolder?.name}
      />
      <CustomActionDialog
        open={!!errorMessage}
        onClose={handleCloseErrorDialog}
        message={errorMessage}
        title={<div className="dialog-title dialog-title__entity-name">Server error</div>}
      />
    </PrimarySection>
  );
};

export default connector(Hotfolders);
