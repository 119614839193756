export enum HotfoldersFormFields {
  Name = 'name',
  DirectoryPath = 'directoryPath',
  Active = 'active',
  State = 'stateId',
  User = 'userId',
  Prefix = 'prefix',
  TemplateFileId = 'templateFileId',
  HotfolderType = 'hotfolderType',
  Password = 'password',
  Username = 'username',
  Host = 'host',
}
