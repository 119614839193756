import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';

import PrimarySection from '../../shared/layouts/primarySection/PrimarySection';
import Paper from '../../shared/components/paper/Paper';
import AppLogo from '../../shared/components/appLogo/AppLogo';
import AppVersion from '../../shared/components/appVersion/AppVersion';
import LoginForm from './loginForm/LoginForm';
import { Align } from '../../shared/layouts/primarySection/enums/Align';
import { connector, StateProps } from './connector';
import { Routes } from '../../routes/Routes';

import './Login.scss';

const Login: FC<StateProps> = (props) => {
  if (props.userLoggedIn) {
    return <Redirect to={props.hasUserPermission ? Routes.Dashboard : Routes.Admin} />;
  }

  return (
    <PrimarySection align={Align.Center} className="login">
      <Paper elevation={5}>
        <AppLogo />
        <LoginForm />
        <AppVersion />
      </Paper>
    </PrimarySection>
  );
};

export default connector(Login);
