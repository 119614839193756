import axios from 'axios';

import { CustomAny } from '../types/generics';
import { getAuthKey, clearAuthKey } from './helpers/authKeyHelper';
import { handleResponse, handleDownload, buildFileFormData, clearGETFromRestrictedChars } from './helpers/apiHelper';

axios.interceptors.request.use(function (config) {
  const user = getAuthKey();
  config.headers.Authorization = `Basic ${user}`;
  if (config.method === 'get') {
    config.params = clearGETFromRestrictedChars(config.params);
  }

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      clearAuthKey();
      error.message = 'Wrong password or login';
    } else if (error.response.data.message) {
      error.message = error.response.data.message;
      error.fieldErrors = error.response.data.fieldErrors;
    }

    return Promise.reject(error);
  },
);

export const getRequest = (url: string, params = {}): Promise<CustomAny> => {
  return axios.get(url, { params }).then(handleResponse);
};

export const postRequest = (url: string, data: CustomAny, config = {}): Promise<CustomAny> => {
  return axios.post(url, data, { headers: { 'Content-Type': 'application/json' }, ...config }).then(handleResponse);
};

export const postUploadRequest = async (url: string, files: File[], data = {}): Promise<CustomAny> => {
  const formData = buildFileFormData(files, data);
  return axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(handleResponse);
};

export const putRequest = (url: string, data: CustomAny): Promise<CustomAny> => {
  return axios.put(url, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } }).then(handleResponse);
};

export const deleteRequest = (url: string, params = {}): Promise<CustomAny> => {
  return axios.delete(url, { headers: { 'Content-Type': 'application/json' }, params }).then(handleResponse);
};

export const downloadRequest = (url: string, fileName: string): Promise<CustomAny> => {
  return axios.get(url, { responseType: 'blob' }).then(handleDownload(fileName));
};
