import React, { FC } from 'react';

import PrimarySection from '../../shared/layouts/primarySection/PrimarySection';
import { Align } from '../../shared/layouts/primarySection/enums/Align';

const NotFound: FC = () => {
  return <PrimarySection align={Align.Center}>404 - Not found</PrimarySection>;
};

export default NotFound;
