import React, { FC } from 'react';

import { CustomAny } from '../../../types/generics';

import './DropzoneText.scss';

interface DropzoneTextProps {
  files?: CustomAny[];
  placeholder?: string;
}

const DropzoneText: FC<DropzoneTextProps> = (props) => {
  const { files = [], placeholder = 'Drop a file, or click to select' } = props;
  return (
    <section className="dropzone-text">
      {files.length ? files.map((file, index) => <span key={index}>{file.name}</span>) : placeholder}
    </section>
  );
};

export default DropzoneText;
