import { createAction } from 'redux-actions';

import { LoginFormData } from '../../pages/login/loginForm/LoginFormData';
import { LoginFormFields } from '../../pages/login/loginForm/LoginFormFields';
import { UserState } from '../interfaces/UserState';
import { signIn } from '../../shared/api/login/LoginApiService';
import { AnyDispatch, CustomAny, Nullable } from '../../shared/types/generics';
import { clearAuthKey, setAuthKey, updateAuthKey, getAuthKey } from '../../shared/api/helpers/authKeyHelper';
import { finishProcessingPath, startProcessingPath } from '../requestsProcessing/actions';
import { LoginPaths } from '../../shared/api/login/LoginPaths';

export const login = (formData: LoginFormData) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  const user = btoa(
    unescape(encodeURIComponent(`${formData[LoginFormFields.Login]}:${formData[LoginFormFields.Password]}`)),
  );
  setAuthKey(user, formData[LoginFormFields.Remember]);

  dispatch(startProcessingPath(LoginPaths.SignIn));

  try {
    const userState = await signIn();
    dispatch(setUser(userState));
  } finally {
    dispatch(finishProcessingPath(LoginPaths.SignIn));
  }
};

export const logout = () => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  clearAuthKey();
  dispatch(clearUserData());
};

export const getUser = () => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(LoginPaths.SignIn));
  try {
    const userState = await signIn();
    dispatch(setUser(userState));
  } catch (e) {
    clearAuthKey();
  } finally {
    dispatch(finishProcessingPath(LoginPaths.SignIn));
  }
};

export const updateActiveUser = (userState: UserState, password: Nullable<string>) => async (
  dispatch: AnyDispatch,
): Promise<CustomAny> => {
  if (password) {
    updateAuthKey(btoa(unescape(encodeURIComponent(`${userState.login}:${password}`))));
  } else {
    const decodedPass = decodeURIComponent(escape(atob(getAuthKey() as string))).split(':')[1];
    updateAuthKey(btoa(unescape(encodeURIComponent(`${userState.login}:${decodedPass}`))));
  }
  dispatch(setUser(userState));
};

const setUser = createAction('USER_SET', (user: UserState) => user);

const clearUserData = createAction('USER_DATA_CLEAR');
