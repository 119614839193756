import React, { FC, useCallback, useContext } from 'react';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import IconButton from '../../components/iconButton/IconButton';
import Title from '../../components/title/Title';
import { Variants } from '../../components/title/enums/Variants';
import { Edge } from '../../components/iconButton/enums/Edge';
import { HeaderContext } from '../headerProvider/HeaderProvider';
import { connector, DispatchProps, StateProps } from './connector';
import { Align } from '../../components/title/enums/Align';

import './Header.scss';

interface HeaderProps extends DispatchProps, StateProps {
  page?: string;
}

const Header: FC<HeaderProps> = (props) => {
  const { header, menu } = useContext(HeaderContext);

  const onOpenMenu = useCallback(() => menu.setOpened(true), [menu]);

  if (!header.show) {
    return null;
  }

  return (
    <AppBar color="default" className={classNames('header')} elevation={1}>
      <Toolbar>
        {menu.show && !menu.opened && (
          <IconButton ariaLabel="Open menu" onClick={onOpenMenu} edge={Edge.Start}>
            <MenuIcon />
          </IconButton>
        )}
        <Title variant={Variants.H5} className="header__page-title">
          {props.page}
        </Title>
        <Title align={Align.Right}>{props.login}</Title>
        <IconButton ariaLabel="Sign out" edge={Edge.End} onClick={props.onLogout}>
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default connector(Header);
