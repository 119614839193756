import React, { FC, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox, { CheckboxProps } from './Checkbox';

interface CheckboxControlProps extends CheckboxProps {
  name: string;
  label?: string;
  title?: string;
}

const CheckboxControl: FC<CheckboxControlProps> = (props) => {
  const formContext = useFormContext();
  const [checked, setChecked] = useState(props.checked || false);
  const onChange = useCallback(() => {
    props.onChange?.(!checked);
    setChecked(!checked);
  }, [checked, props]);
  return (
    <FormControlLabel
      control={<Checkbox color={props.color} name={props.name} inputRef={formContext ? formContext.register : null} />}
      label={props.label}
      checked={checked}
      onChange={onChange}
      title={props.title}
      disabled={props.disabled}
    />
  );
};

export default CheckboxControl;
