import { connect } from 'react-redux';

import { hasUserPermission, isUserLoggedIn } from '../../store/user/selectors';
import { RootState } from '../../store/interfaces/RootState';
import { isProcessingPath } from '../../store/requestsProcessing/selectors';
import { LoginPaths } from '../../shared/api/login/LoginPaths';

export interface StateProps {
  hasUserPermission: boolean;
  userLoggedIn: boolean;
  processingLogin: boolean;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    hasUserPermission: hasUserPermission(state),
    userLoggedIn: isUserLoggedIn(state),
    processingLogin: isProcessingPath(state)(LoginPaths.SignIn),
  };
};

export const connector = connect(mapStateToProps);
