import { Option } from '../../../../shared/components/form/controls/select/interfaces/Option';
import { AgeRanges } from '../../enums/AgeRanges';

export interface AgeRange {
  minAge: string;
  maxAge: string;
}

export const AGE_RANGES_OPTIONS: Option[] = [
  { value: AgeRanges.Months, label: 'Months' },
  { value: AgeRanges.Weeks, label: 'Weeks' },
  { value: AgeRanges.Days, label: 'Days' },
];

export const AGE_RANGES: { [key: string]: AgeRange[] } = {
  [AgeRanges.Months]: [
    { minAge: '0', maxAge: '30' },
    { minAge: '31', maxAge: '60' },
    { minAge: '61', maxAge: '-1' },
  ],
  [AgeRanges.Weeks]: [
    { minAge: '0', maxAge: '6' },
    { minAge: '7', maxAge: '13' },
    { minAge: '14', maxAge: '-1' },
  ],
  [AgeRanges.Days]: [
    { minAge: '0', maxAge: '0' },
    { minAge: '1', maxAge: '1' },
    { minAge: '2', maxAge: '-1' },
  ],
};
