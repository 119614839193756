import React, { useEffect, useMemo, FC, useCallback, useRef } from 'react';
import PublishIcon from '@material-ui/icons/Publish';

import Button, { ButtonProps } from './Button';
import CircularLoading from '../circularLoading/CircularLoading';
import { Color as LoadingColor } from '../circularLoading/enums/Color';
import { CustomAny, Nullable } from '../../types/generics';

import './ButtonUpload.scss';

interface ButtonUploadProps extends ButtonProps {
  processing?: boolean;
  accept?: string;
  onFileUpdate(e: CustomAny): void;
}

const ButtonUpload: FC<ButtonUploadProps> = (props) => {
  const inputEl = useRef<CustomAny>(null);
  const { accept = 'application/pdf' } = props;
  const onClick = useCallback(() => {
    inputEl.current.click();
  }, []);
  const onInputChange = useCallback(
    (e: CustomAny) => {
      props.onFileUpdate(e.target.files[0]);
    },
    [props],
  );
  const loadingColor = useMemo(
    () =>
      props.color && Object.values(LoadingColor).includes(props.color as LoadingColor)
        ? (props.color as LoadingColor)
        : LoadingColor.Inherit,
    [props.color],
  );

  useEffect(() => {
    return (): void => props.onFileUpdate(null);
  }, []);

  return (
    <Button
      variant={props.variant}
      color={props.color}
      disabled={props.disabled || props.processing}
      className="button-upload"
      onClick={onClick}
      endIcon={<PublishIcon />}
    >
      {props.children}
      {props.processing && <CircularLoading size={24} color={loadingColor} />}
      <input type="file" accept={accept} ref={inputEl} onChange={onInputChange} />
    </Button>
  );
};

export default ButtonUpload;
