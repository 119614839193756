import React, { FC, useCallback, useMemo, useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import _ from 'lodash';

import { AnyFunction } from '../../../../shared/types/generics';
import { HotfoldersFormData } from './HotfoldersFormData';
import { HotfoldersFormSchema } from './HotfoldersFormSchema';
import { HotfoldersFormFields } from './HotfoldersFormFields';
import { buildStatesList, buildTemplatesList } from '../../../templates/helpers/treeHelper';
import FormDialog from '../../../../shared/components/dialog/FormDialog';
import FormGroup from '../../../../shared/components/form/formGroup/FormGroup';
import InputControl from '../../../../shared/components/form/controls/input/InputControl';
import SelectControl from '../../../../shared/components/form/controls/select/SelectControl';
import { HotfolderState, SftpCredentialDTO } from '../../../../store/interfaces/HotfolderState';
import { UserState } from '../../../../store/interfaces/UserState';
import { StatesState } from '../../../../store/interfaces/StatesState';
import { TemplatesTree } from '../../../../store/interfaces/TemplatesTree';
import { Entity } from '../../../../shared/enums/Entity/Entity';
import { HOTFOLDER_TYPES } from '../../constants/dialog';
import { HotfolderType } from '../../../../store/enums/HotfolderType';
import { Size } from '../../../../shared/components/dialog/enums/Size';

interface HotfoldersFormDialogProps {
  open: boolean;
  onSubmit: AnyFunction;
  onClose: AnyFunction;
  activeUser?: number;
  folder?: HotfolderState;
  userList: UserState[];
  stateList: StatesState[];
  templateList: TemplatesTree;
}

const HotfoldersFormDialog: FC<HotfoldersFormDialogProps> = (props) => {
  const userOptions = useMemo(() => props.userList.map((user) => ({ value: user.id, label: user.login })), [
    props.userList,
  ]);
  const stateOptions = useMemo(() => buildStatesList(props.stateList, '(automatic)', 'processing'), [props.stateList]);
  const templateOptions = useMemo(() => buildTemplatesList(props.templateList), [props.templateList]);

  const [currentHotfolderType, setCurrentHotfolderType] = useState(props.folder?.hotfolderType);

  React.useEffect(() => {
    setCurrentHotfolderType(props.folder?.hotfolderType);
  }, [props.folder]);

  const onSubmit = useCallback(
    async (formData: HotfoldersFormData & { sftpCredentialDTO?: SftpCredentialDTO }) => {
      if (formData.hotfolderType === HotfolderType.SFTP && formData.host && formData.password && formData.username) {
        formData.sftpCredentialDTO = {
          host: formData.host,
          password: formData.password,
          username: formData.username,
        };
        delete formData.host;
        delete formData.password;
        delete formData.username;
      }
      await props.onSubmit(formData);
      props.onClose();
    },
    [props],
  );

  return (
    <FormDialog
      open={props.open}
      size={Size.Sm}
      onClose={props.onClose}
      title={
        <div className="dialog-title">
          {props.folder?.id ? (
            <>
              Edit <span className="dialog-title__entity-name">{props.folder.name}</span>
            </>
          ) : (
            `Add new ${Entity.Hotfolder}`
          )}
        </div>
      }
      className="metadata-dialog"
      onSubmit={onSubmit}
      validationSchema={HotfoldersFormSchema}
    >
      <FormGroup doubleColumn>
        <InputControl
          name={HotfoldersFormFields.Name}
          label="Name"
          required
          fullWidth
          defaultValue={props.folder?.name}
          autoFocus
        />
        <InputControl name={HotfoldersFormFields.Prefix} label="Prefix" fullWidth defaultValue={props.folder?.prefix} />
      </FormGroup>
      <FormGroup>
        <InputControl
          name={HotfoldersFormFields.DirectoryPath}
          label="Directory path"
          required
          fullWidth
          defaultValue={props.folder?.directoryPath}
        />
      </FormGroup>
      <FormGroup doubleColumn>
        <SelectControl
          name={HotfoldersFormFields.HotfolderType}
          label="Hotfolder type"
          required
          options={HOTFOLDER_TYPES}
          value={props.folder?.hotfolderType || HOTFOLDER_TYPES[0].value}
          onChange={setCurrentHotfolderType}
          fullWidth
        />
        <SelectControl
          name={HotfoldersFormFields.User}
          label="Assign to"
          required
          options={userOptions}
          value={props.activeUser}
          fullWidth
        />
        <SelectControl
          name={HotfoldersFormFields.State}
          label="State"
          required
          options={stateOptions}
          value={props.folder?.stateId || props.stateList[0]?.id}
          fullWidth
        />
        <SelectControl
          name={HotfoldersFormFields.TemplateFileId}
          disabled={!!props.folder?.id}
          label="Template"
          required
          options={templateOptions}
          value={
            templateOptions.length > 1
              ? _.find(templateOptions, { value: props.folder?.templateFileId })?.value || templateOptions[0].value
              : templateOptions[0].value
          }
          fullWidth
        />
      </FormGroup>
      <FormGroup>
        {currentHotfolderType === HotfolderType.SFTP && (
          <Collapse in={currentHotfolderType === HotfolderType.SFTP}>
            <InputControl
              name={HotfoldersFormFields.Host}
              label="Host"
              required
              fullWidth
              defaultValue={props.folder?.sftpCredentialDTO?.host}
            />
            <InputControl
              name={HotfoldersFormFields.Username}
              label="Username"
              required
              fullWidth
              defaultValue={props.folder?.sftpCredentialDTO?.username}
            />
            <InputControl
              name={HotfoldersFormFields.Password}
              label="Password"
              type="password"
              required
              fullWidth
              defaultValue={props.folder?.sftpCredentialDTO?.password}
            />
          </Collapse>
        )}
      </FormGroup>
    </FormDialog>
  );
};

export default HotfoldersFormDialog;
