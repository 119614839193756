import React, { FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import FormHelperText from '@material-ui/core/FormHelperText';
import classNames from 'classnames';

import DropzoneText from './dropzoneText/DropzoneText';
import { AnyFunction, CustomAny } from '../../types/generics';
import { FileType } from './enums/FileType';

import './Dropzone.scss';

export interface DropzoneProps {
  onChange?: AnyFunction;
  value?: CustomAny;
  accept?: FileType;
  multiple?: boolean;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  onError?: AnyFunction;
  clearError?: AnyFunction;
}

const Dropzone: FC<DropzoneProps> = (props) => {
  const { value: files = [], accept = FileType.PDF } = props;
  const onDrop = useCallback(
    (acceptedFiles) => {
      props.clearError?.();
      props.onChange?.(acceptedFiles);
    },
    [props.clearError, props.onChange],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: 104857600,
    onDrop,
    preventDropOnDocument: true,
    accept: accept,
    multiple: !!props.multiple,
    onDropRejected: (data) => props.onError?.(data[0].errors[0].message),
  });

  return (
    <div
      {...getRootProps({
        className: classNames('dropzone', {
          _focused: isDragActive,
          _filled: files.length > 0,
          _error: props.error,
        }),
      })}
    >
      <input {...getInputProps()} />
      <DropzoneText files={files} placeholder={props.placeholder} />
      <FormHelperText className="dropzone__help">{props.helperText || ''}</FormHelperText>
    </div>
  );
};

export default Dropzone;
