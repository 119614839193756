import * as yup from 'yup';

import { UserFormFields } from './UserFormFields';
import { Permission } from '../../../../store/enums/Permission';

export const EditUserFormSchema = yup.object().shape({
  [UserFormFields.Login]: yup
    .string()
    .trim()
    .required()
    .matches(/^\w+$/, 'Login can contain only English alphabet letters, digits and underscores')
    .min(3, 'Login must be between 3 and 32 characters')
    .max(32, 'Login must be between 3 and 32 characters'),
  [UserFormFields.Email]: yup.string().trim().email().max(128),
  [UserFormFields.Password]: yup
    .string()
    .matches(
      /^((?=.*[0-9])(?=.*[a-z[A-Z])(?=\S+$)\w*|)$/,
      'Password can contain only English alphabet letters, no whitespace characters and at least one digit',
    )
    .matches(/^(\S{6,32}|)$/, 'Password must be between 6 and 32 characters'),
  [UserFormFields.Permissions]: yup.array(yup.mixed<Permission[]>()).required(),
});

export const AddUserFormSchema = yup.object().shape({
  [UserFormFields.Login]: yup
    .string()
    .trim()
    .required()
    .matches(/^\w+$/, 'Login can contain only English alphabet letters, digits and underscores')
    .min(3, 'Login must be between 3 and 32 characters')
    .max(32, 'Login must be between 3 and 32 characters'),
  [UserFormFields.Email]: yup.string().trim().email(),
  [UserFormFields.Password]: yup
    .string()
    .required()
    .matches(
      /^(?=.*[0-9])(?=.*[a-z[A-Z])(?=\S+$)\w*$/,
      'Password can contain only English alphabet letters, no whitespace characters and at least one digit',
    )
    .matches(/^\S{6,32}$/, 'Password must be between 6 and 32 characters'),
  [UserFormFields.Permissions]: yup.array(yup.mixed<Permission[]>()).required(),
});
