import React, { FC, MouseEventHandler, useCallback } from 'react';
import MaterialIconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { Edge } from './enums/Edge';
import { Size } from './enums/Size';

import './IconButton.scss';

interface IconButtonProps {
  edge?: Edge;
  size?: Size;
  ariaLabel?: string;
  className?: string;
  onClick?: MouseEventHandler;
  disabled?: boolean;
}

const IconButton: FC<IconButtonProps> = (props) => {
  const onClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      props.onClick?.(event);
    },
    [props],
  );
  return (
    <Tooltip disableFocusListener disableTouchListener title={props.ariaLabel || ''}>
      <span className="disabled-wrapper">
        <MaterialIconButton
          edge={props.edge}
          aria-label={props.ariaLabel}
          className={props.className}
          onClick={onClick}
          size={props.size}
          disabled={props.disabled}
        >
          {props.children}
        </MaterialIconButton>
      </span>
    </Tooltip>
  );
};

export default IconButton;
