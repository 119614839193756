import React, { FC, useCallback } from 'react';
import MaterialTabs from '@material-ui/core/Tabs';
import MaterialTab from '@material-ui/core/Tab';

import { TabObject } from './interfaces/TabObject';
import Paper from '../paper/Paper';
import { buildTabProps } from './helpers/tabHelper';
import { Variant } from './enums/Variant';

interface TabsProps {
  tabsObject: TabObject[];
  selectedTab: string;
  variant?: Variant;
  onChange?(value: string): void;
}

const Tabs: FC<TabsProps> = (props) => {
  const { variant = Variant.Standard } = props;
  const onChange = useCallback(
    (e, value) => {
      props.onChange?.(value);
    },
    [props.onChange],
  );
  return (
    <Paper elevation={1} padding={false}>
      <MaterialTabs
        indicatorColor="primary"
        textColor="primary"
        value={props.selectedTab}
        onChange={onChange}
        variant={variant}
      >
        {props.tabsObject.map((item, index) => (
          <MaterialTab
            color="default"
            key={index}
            label={item.label}
            value={item.value}
            data-value={item.value}
            className={item.className}
            {...buildTabProps(item)}
          />
        ))}
      </MaterialTabs>
    </Paper>
  );
};

export default Tabs;
