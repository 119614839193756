import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import MaterialDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Size } from './enums/Size';
import { DialogProps } from './Dialog';
import { OnSubmit } from '../form/types/OnSubmit';
import { CustomAny } from '../../types/generics';
import { Color } from '../button/enums/Color';
import { Variant } from '../button/enums/Variant';
import Form from '../form/Form';
import ButtonSubmit from '../button/ButtonSubmit';
import Button from '../button/Button';
import FormError from '../form/formError/FormError';

import './FormDialog.scss';

interface FormDialogProps extends DialogProps {
  onSubmit: OnSubmit;
  validationSchema?: CustomAny;
  submitLabel?: string;
  title?: ReactNode;
  processing?: boolean;
  readonly?: boolean;
  isValid?(state: boolean): void;
}

const FormDialog: FC<FormDialogProps> = (props) => {
  const { size = Size.Xs, submitLabel = 'Save' } = props;

  return (
    <MaterialDialog
      open={props.open}
      onClose={props.onClose}
      className={classNames('form-dialog', props.className)}
      fullWidth
      maxWidth={size}
      disableBackdropClick
    >
      {props.title && <DialogTitle>{props.title}</DialogTitle>}
      <Form
        onSubmit={props.onSubmit}
        validationSchema={props.validationSchema}
        className="form-dialog__form"
        isValid={props.isValid}
      >
        <DialogContent dividers>
          {props.children}
          <FormError />
        </DialogContent>
        <DialogActions>
          <Button color={Color.Default} onClick={props.onClose}>
            Cancel
          </Button>
          {props.actions?.map((action, index) => {
            return (
              <Button
                onClick={action.onClick}
                title={action.label}
                key={index}
                variant={action.variant}
                color={action.color}
              >
                {action.label}
              </Button>
            );
          })}
          {!props.readonly && (
            <ButtonSubmit variant={Variant.Contained} color={Color.Primary} processing={props.processing}>
              {submitLabel}
            </ButtonSubmit>
          )}
        </DialogActions>
      </Form>
    </MaterialDialog>
  );
};

export default FormDialog;
