import * as yup from 'yup';

import { TemplateFormFields } from './EditTemplateFormFields';

export const TemplateFormSchema = yup.object().shape({
  [TemplateFormFields.Name]: yup.string().trim().required().max(64),
  [TemplateFormFields.File]: yup.array().required(),
});

export const TemplateFormEditSchema = yup.object().shape({
  [TemplateFormFields.Name]: yup.string().trim().required().max(64),
});
