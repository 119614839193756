import React, { FC, useCallback, useState, useMemo, useEffect } from 'react';
import _ from 'lodash';

import { AnyFunction, AnyObject } from '../../../shared/types/generics';
import { Option } from '../../../shared/components/form/controls/select/interfaces/Option';
import { TemplateState } from '../../../store/interfaces/TemplateState';
import FormDialog from '../../../shared/components/dialog/FormDialog';
import { Size } from '../../../shared/components/dialog/enums/Size';
import FormGroup from '../../../shared/components/form/formGroup/FormGroup';
import InputControl from '../../../shared/components/form/controls/input/InputControl';
import CheckboxControl from '../../../shared/components/form/controls/checkbox/CheckboxControl';
import SelectControl from '../../../shared/components/form/controls/select/SelectControl';
import Title from '../../../shared/components/title/Title';
import { TemplateFormEditSchema } from './EditTemplateFormSchema';
import { TemplateFormFields } from './EditTemplateFormFields';
import PostProcessingFields from '../postProcessingFields/PostProcessingFields';
import { DocumentStatus } from '../../../store/enums/DocumentStatus';
import { DocumentFieldRule } from '../../../store/interfaces/DocumentFieldRule';
import { Variants } from '../../../shared/components/title/enums/Variants';
import { Color as CheckboxColor } from '../../../shared/components/form/controls/checkbox/enums/Color';

import './EditTemplateFormDialog.scss';

interface EditTemplateFormDialogProps {
  open: boolean;
  onSubmit: AnyFunction;
  onClose: AnyFunction;
  template: TemplateState;
  parentId?: string;
  directories?: Option[];
  getStatus(id: string): Promise<DocumentStatus>;
  getTemplateFields(id: string): Promise<DocumentFieldRule[]>;
}

const EditTemplateFormDialog: FC<EditTemplateFormDialogProps> = (props) => {
  const { getStatus, getTemplateFields, onSubmit: handleSubmit, template } = props;
  const [templateStatus, setTemplateStatus] = useState<DocumentStatus | undefined>();
  const [templateFields, setTemplateFields] = useState<DocumentFieldRule[]>([]);
  const [isUpdateDocuments, setIsUpdateDocuments] = useState<boolean | undefined>(true);

  const onSubmit = useCallback(
    async (formData: AnyObject) => {
      const parentId = formData.parentId;
      const name = formData.name;
      const updateDocuments = formData.updateDocuments || false;
      delete formData.parentId;
      delete formData.name;
      delete formData.updateDocuments;
      const array = Object.keys(formData)
        .reverse()
        .map((e) => e.replaceAll(/documentRule-|-subtype|-value|-rule/g, ''));
      const policyArray = _.uniq(array)
        .reverse()
        .map((id) => {
          return {
            id,
            subtype: formData[`documentRule-${id}-subtype`],
            rule: formData[`documentRule-${id}-rule`],
            value: formData[`documentRule-${id}-value`],
            name: templateFields.find((e) => e.id.toString() === id)?.name,
          };
        })
        .filter((e) => e.subtype && e.rule && e.value && e.name);
      await handleSubmit(name, parentId, updateDocuments, policyArray);
    },
    [handleSubmit, templateFields],
  );

  const getTemplateData = useCallback(async () => {
    if (template?.id) {
      const status: DocumentStatus = await getStatus(template.id);
      setTemplateStatus(status);
      if (status === DocumentStatus.VALID_DATA) {
        setTemplateFields(await getTemplateFields(template.id));
      }
    }
  }, [getStatus, getTemplateFields, template]);

  useEffect(() => {
    if (!props.open) {
      setTemplateStatus(undefined);
      setTemplateFields([]);
    }
  }, [props.open]);

  useEffect(
    useCallback(() => {
      if (template?.id && props.open) {
        getTemplateData();
      }
    }, [getTemplateData, props.open, template]),
    [template, props.open],
  );

  const getStatusName = (status: DocumentStatus | undefined): JSX.Element | null => {
    switch (status) {
      case DocumentStatus.UNPROCESSED:
        return <span className="doc-status_warn">Unprocessed</span>;
      case DocumentStatus.PARSER_ERROR:
        return <span className="doc-status_error">Error file parsing</span>;
      default:
        return null;
    }
  };

  const statusLabel = useMemo(() => getStatusName(templateStatus), [templateStatus]);

  return (
    <FormDialog
      open={props.open}
      onClose={props.onClose}
      title={
        <div className="dialog-title">
          Edit <span className="dialog-title__entity-name">{template?.name}</span>
        </div>
      }
      className="template-node-dialog edit-template-dialog"
      onSubmit={onSubmit}
      size={Size.Md}
      validationSchema={TemplateFormEditSchema}
    >
      <FormGroup doubleColumn>
        <InputControl
          name={TemplateFormFields.Name}
          label="Name"
          required
          fullWidth
          defaultValue={template?.name}
          autoFocus
        />
        <SelectControl
          name={TemplateFormFields.Parent}
          options={props.directories || []}
          label="Parent folder"
          fullWidth
          value={template?.parentId || props.parentId || undefined}
        />
      </FormGroup>
      {statusLabel && (
        <section className="document-status-section">
          <Title variant={Variants.H6}>Processing status: {statusLabel}</Title>
        </section>
      )}
      {_.size(templateFields) > 0 && (
        <FormGroup>
          <section className="metadata">
            <Title variant={Variants.H6}>Post-processing rules</Title>
            <CheckboxControl
              name="updateDocuments"
              label="Update documents"
              checked={isUpdateDocuments}
              onChange={setIsUpdateDocuments}
              color={CheckboxColor.Primary}
            />
            <PostProcessingFields fields={templateFields} />
          </section>
        </FormGroup>
      )}
    </FormDialog>
  );
};

export default EditTemplateFormDialog;
