import React, { FC } from 'react';

import './TabContent.scss';

interface TabContentProps {
  hidden?: boolean;
}

const TabContent: FC<TabContentProps> = (props) => {
  if (props.hidden) {
    return null;
  }

  return (
    <section hidden={props.hidden} className="tab-content">
      {props.children}
    </section>
  );
};

export default TabContent;
