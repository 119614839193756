import React, { FC, useMemo, useContext } from 'react';
import classNames from 'classnames';

import { Align } from './enums/Align';
import { HeaderContext } from '../headerProvider/HeaderProvider';
import CircularLoading from '../../components/circularLoading/CircularLoading';
import { CustomAny } from '../../types/generics';

import './PrimarySection.scss';

interface PrimarySectionProps {
  align?: Align;
  verticalAlign?: Align;
  className?: string;
  ignoreHeader?: boolean;
  fullWidth?: boolean;
  processing?: boolean;
  innerRef?: CustomAny;
}

const PrimarySection: FC<PrimarySectionProps> = (props) => {
  const { align = Align.Start, verticalAlign = Align.Center, ignoreHeader = false, fullWidth = false } = props;
  const { header } = useContext(HeaderContext);
  const classList: string = useMemo(
    () =>
      classNames(
        'primary-section',
        `primary-section_${align}`,
        `primary-section_vertical-${verticalAlign}`,
        {
          'primary-section_with-header': !ignoreHeader && header.show,
          'primary-section_full-width': fullWidth,
        },
        props.className,
      ),
    [align, fullWidth, header.show, ignoreHeader, props.className, verticalAlign],
  );

  return (
    <section className={classList} ref={props.innerRef}>
      {props.children}
      {props.processing && (
        <div className="primary-section__loading">
          <CircularLoading />
        </div>
      )}
    </section>
  );
};

export default PrimarySection;
