import React, { FC } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';

import IconButton from '../../iconButton/IconButton';
import { AnyFunction } from '../../../types/generics';

interface TableToolbarProps {
  onClick: AnyFunction;
}

const TableToolbar: FC<TableToolbarProps> = (props) => {
  return (
    <IconButton onClick={props.onClick} ariaLabel="Refresh table data">
      <RefreshIcon />
    </IconButton>
  );
};

export default TableToolbar;
