import moment from 'moment';

import { Column } from '../../../shared/components/table/interfaces/Column';
import { DocumentFormFields } from '../documentFormDialog/DocumentFormFields';
import { CustomAny } from '../../../shared/types/generics';
import { DEFAULT_DATE_FORMAT } from '../../../shared/constants/application';
import { getDocumentVersion } from '../helpers/documentHelper';
import { ColumnType } from '../../../shared/components/table/enums/ColumnType';
import { Align } from '../../../shared/components/table/enums/Align';
import { Padding } from '../../../shared/components/table/enums/Padding';

export const DOCUMENTS_COLUMNS: Column[] = [
  {
    title: 'Name',
    field: DocumentFormFields.Name,
    sort: true,
    type: ColumnType.ClickableString,
    filter: true,
    filterType: 'textField',
    filterName: 'documentNameFilter',
  },
  {
    title: 'Template',
    field: 'templateFile',
    sort: true,
    sortName: 'templateFile.name',
    filter: true,
    filterType: 'textField',
    filterName: 'templateNameFilter',
    getValue: (value: CustomAny): string => value?.name || '',
  },
  {
    title: 'State',
    field: 'stateDescriptor',
    sort: true,
    sortName: 'stateDescriptor.name',
    filter: true,
    filterName: 'stateIdFilter',
    getValue: (value: CustomAny): string => value?.name || '',
  },
  {
    title: 'Version',
    field: 'documentFileVersions',
    sort: false,
    align: Align.Center,
    padding: Padding.Checkbox,
    getValue: (versionList): string => getDocumentVersion(versionList),
  },
  {
    title: 'Date',
    field: 'creationDate',
    sort: true,
    getValue: (value: CustomAny): string => moment(new Date(value)).format(DEFAULT_DATE_FORMAT),
  },
  {
    title: 'Tags',
    field: 'tags',
    display: 'excluded',
    filter: true,
    sort: false,
    filterName: 'tagsFilter',
    filterType: 'custom',
    customFilterListOptions: {
      render: (value: CustomAny): string => value,
    },
  },
  {
    title: 'Show docs',
    field: 'archived',
    display: 'excluded',
    filter: true,
    sort: false,
    filterName: 'archivedFilter',
    filterType: 'custom',
  },
];
