import React, { FC, useCallback, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Form from '../../../shared/components/form/Form';
import FormGroup from '../../../shared/components/form/formGroup/FormGroup';
import InputControl from '../../../shared/components/form/controls/input/InputControl';
import ButtonSubmit from '../../../shared/components/button/ButtonSubmit';
import CheckboxControl from '../../../shared/components/form/controls/checkbox/CheckboxControl';
import FormError from '../../../shared/components/form/formError/FormError';
import IconButton from '../../../shared/components/iconButton/IconButton';
import { LoginFormFields } from './LoginFormFields';
import { LoginFormData } from './LoginFormData';
import { LoginFormSchema } from './LoginFormSchema';
import { Variant } from '../../../shared/components/button/enums/Variant';
import { Color as ButtonColor } from '../../../shared/components/button/enums/Color';
import { Color as CheckboxColor } from '../../../shared/components/form/controls/checkbox/enums/Color';
import { connector, DispatchProps, StateProps } from './connector';
import { Size } from '../../../shared/components/iconButton/enums/Size';

import './LoginForm.scss';

interface LoginFormProps extends StateProps, DispatchProps {}

const LoginForm: FC<LoginFormProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleSubmit = useCallback((formData: LoginFormData) => props.onSubmit(formData), [props]);
  const handleClickShowPassword = useCallback(() => setShowPassword(!showPassword), [showPassword]);

  return (
    <Form onSubmit={handleSubmit} className="login-form" validationSchema={LoginFormSchema}>
      <FormGroup>
        <InputControl name={LoginFormFields.Login} label="Login" required fullWidth />
      </FormGroup>
      <FormGroup>
        <InputControl
          name={LoginFormFields.Password}
          label="Password"
          type={showPassword ? 'text' : 'password'}
          required
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size={Size.Small} aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormGroup>
      <FormGroup>
        <CheckboxControl name={LoginFormFields.Remember} label="Remember me" color={CheckboxColor.Secondary} />
      </FormGroup>
      <FormError />
      <ButtonSubmit variant={Variant.Outlined} color={ButtonColor.Primary} processing={props.processing}>
        Sign in
      </ButtonSubmit>
    </Form>
  );
};

export default connector(LoginForm);
