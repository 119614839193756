import { Option } from '../../../shared/components/form/controls/select/interfaces/Option';
import { DocumentPolicyFieldRules } from '../../../store/enums/DocumentPolicyFieldRules';
import { DocumentSubFieldType } from '../../../store/enums/DocumentSubFieldType';

export const SUBTYPE_OPTIONS: Option[] = [
  { value: DocumentSubFieldType.STRING, label: 'String' },
  { value: DocumentSubFieldType.INTEGER, label: 'Integer' },
  { value: DocumentSubFieldType.DATE, label: 'Date' },
  { value: DocumentSubFieldType.DECIMAL, label: 'Decimal' },
];

export const RULE_OPTIONS = {
  STRING_OPTIONS: [
    { value: DocumentPolicyFieldRules.EQUALS, label: 'Equals to value' },
    { value: DocumentPolicyFieldRules.NOT_EQUALS, label: 'Not equals to value' },
    { value: DocumentPolicyFieldRules.CONTAINS, label: 'Contains the value' },
    { value: DocumentPolicyFieldRules.NOT_CONTAINS, label: 'Not contains the value' },
    { value: DocumentPolicyFieldRules.REG_EXP, label: 'Includes regular expression' },
  ],
  INTEGER_OPTIONS: [
    { value: DocumentPolicyFieldRules.EQUALS, label: 'Equals to value' },
    { value: DocumentPolicyFieldRules.NOT_EQUALS, label: 'Not equals to value' },
    { value: DocumentPolicyFieldRules.MIN, label: 'Min value' },
    { value: DocumentPolicyFieldRules.MAX, label: 'Max value' },
  ],
  DATE_OPTIONS: [
    { value: DocumentPolicyFieldRules.EQUALS, label: 'Equals to date' },
    { value: DocumentPolicyFieldRules.NOT_EQUALS, label: 'Not equals to date' },
    { value: DocumentPolicyFieldRules.BEFORE, label: 'Before date' },
    { value: DocumentPolicyFieldRules.AFTER, label: 'After date' },
  ],
  DECIMAL_OPTIONS: [
    { value: DocumentPolicyFieldRules.EQUALS, label: 'Equals to value' },
    { value: DocumentPolicyFieldRules.NOT_EQUALS, label: 'Not equals to value' },
    { value: DocumentPolicyFieldRules.MIN, label: 'Min value' },
    { value: DocumentPolicyFieldRules.MAX, label: 'Max value' },
  ],
};

export const TREE_LEVEL_INDENT = 16;
