import { connect } from 'react-redux';

import { AnyDispatch, CustomAny } from '../../../shared/types/generics';
import {
  getMetadataList,
  addMetadataField,
  editMetadataField,
  removeMetadataField,
} from '../../../store/metadata/actions';
import { RootState } from '../../../store/interfaces/RootState';
import { isProcessingPath } from '../../../store/requestsProcessing/selectors';
import { MetadataState } from '../../../store/interfaces/MetadataState';
import { MetadataFormData } from './metadataFormDialog/MetadataFormData';
import { getMetadata } from '../../../store/metadata/selectors';
import { MetadataPaths } from '../../../shared/api/metadata/MetadataPaths';

export interface StateProps {
  metadata: MetadataState[];
  processing: boolean;
}

export interface DispatchProps {
  getMetadataList: () => void;
  onEditMetadata: (id: number, formData: MetadataFormData) => void;
  onAddMetadata: (formData: MetadataFormData) => void;
  onRemoveMetadata: (id: number) => void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    metadata: getMetadata(state),
    processing: isProcessingPath(state)(MetadataPaths.Admin),
  };
};

const mapDispatchToProps = (dispatch: AnyDispatch): DispatchProps => {
  return {
    getMetadataList: (): Promise<CustomAny> => dispatch(getMetadataList()),
    onEditMetadata: (id, formData): Promise<CustomAny> => dispatch(editMetadataField(id, formData)),
    onAddMetadata: (formData): Promise<CustomAny> => dispatch(addMetadataField(formData)),
    onRemoveMetadata: (id): Promise<CustomAny> => dispatch(removeMetadataField(id)),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
