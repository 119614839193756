import { USER_KEY } from '../../constants/application';

export const setAuthKey = (data: string, remember: boolean): void => {
  if (remember) {
    localStorage.setItem(USER_KEY, data);
  } else {
    sessionStorage.setItem(USER_KEY, data);
  }
};

export const updateAuthKey = (data: string): void => {
  if (localStorage.getItem(USER_KEY)) {
    localStorage.setItem(USER_KEY, data);
  } else {
    sessionStorage.setItem(USER_KEY, data);
  }
};

export const clearAuthKey = (): void => {
  sessionStorage.removeItem(USER_KEY);
  localStorage.removeItem(USER_KEY);
};

export const getAuthKey = (): string | null => {
  return localStorage.getItem(USER_KEY) || sessionStorage.getItem(USER_KEY);
};

export const isAuthKeyEmpty = (): boolean => !getAuthKey();
