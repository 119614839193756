import React, { FC } from 'react';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';

import { Variants } from './enums/Variants';
import { Align } from './enums/Align';

interface TitleProps {
  variant?: Variants;
  className?: string;
  align?: Align;
}

const Title: FC<TitleProps> = (props) => {
  return (
    <Typography variant={props.variant} className={classNames('title', props.className)} align={props.align}>
      {props.children}
    </Typography>
  );
};

export default Title;
