import { connect } from 'react-redux';

import { RootState } from '../../../store/interfaces/RootState';
import { getUserId } from '../../../store/user/selectors';
import { postForDocumentListByUser } from '../../../store/documents/actions';
import { AnyDispatch, AnyObject, CustomAny } from '../../../shared/types/generics';
import { DocumentsTableState } from '../../../store/interfaces/DocumentsTableState';

export interface StateProps {
  activeUser: number;
}

export interface DispatchProps {
  getDocuments(bodyParams?: AnyObject, shouldUpdateList?: boolean): Promise<DocumentsTableState>;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    activeUser: getUserId(state),
  };
};

const mapDispatchToProps = (dispatch: AnyDispatch): DispatchProps => {
  return {
    getDocuments: (props): Promise<CustomAny> => dispatch(postForDocumentListByUser(props, false, false)),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
