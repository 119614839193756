import { createAction } from 'redux-actions';
import _ from 'lodash';

import { AnyDispatch, AnyFunction, CustomAny } from '../../shared/types/generics';
import { finishProcessingPath, startProcessingPath } from '../requestsProcessing/actions';
import { StatesPaths } from '../../shared/api/states/StatesPaths';
import * as stateService from '../../shared/api/states/StatesApiService';
import { StateFormData } from '../../pages/admin/states/stateFormDialog/StateFormData';
import { getStates } from './selectors';
import { StatesState } from '../interfaces/StatesState';
import { StateDeleteFormData } from '../../pages/admin/states/stateDeleteDialog/StateDeleteFormData';

const updateStates = createAction('STATES_UPDATE');

export const getStatesList = (url = StatesPaths.Admin) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(url));
  try {
    const states = await stateService.get(url);
    dispatch(updateStates(states));
  } finally {
    dispatch(finishProcessingPath(url));
  }
};

export const getInvisibleState = (url = StatesPaths.Admin) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(url));
  try {
    const invisibleState = await stateService.getInvisibleState(url);
    return invisibleState;
  } finally {
    dispatch(finishProcessingPath(url));
  }
};

export const addState = (field: StateFormData) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(StatesPaths.Admin));
  try {
    await stateService.add(field);
    await dispatch(getStatesList());
  } finally {
    dispatch(finishProcessingPath(StatesPaths.Admin));
  }
};

export const editState = (id: number, field: StateFormData) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(StatesPaths.Admin));
  try {
    await stateService.update(id, field);
    await dispatch(getStatesList());
  } finally {
    dispatch(finishProcessingPath(StatesPaths.Admin));
  }
};

export const changeStateOrder = (id: number, order: string) => async (
  dispatch: AnyDispatch,
  getState: AnyFunction,
): Promise<CustomAny> => {
  const states = getStates(getState());
  const originState = _.find(states, { id });
  if (!originState || parseInt(order) === originState.order) return;
  dispatch(startProcessingPath(StatesPaths.Admin));
  try {
    await makeTemporaryReorder(states, originState, parseInt(order), dispatch);
    await stateService.update(id, { ...originState, order: parseInt(order) });
    await dispatch(getStatesList());
  } finally {
    dispatch(finishProcessingPath(StatesPaths.Admin));
  }
};

export const removeState = (id: number, data: StateDeleteFormData) => async (
  dispatch: AnyDispatch,
): Promise<CustomAny> => {
  dispatch(startProcessingPath(StatesPaths.Admin));
  try {
    await stateService.remove(id, data);
    await dispatch(getStatesList());
  } finally {
    dispatch(finishProcessingPath(StatesPaths.Admin));
  }
};

const makeTemporaryReorder: AnyFunction = async (
  states: StatesState[],
  originState: StatesState,
  order: number,
  dispatch: AnyDispatch,
) => {
  const id = originState.id;
  const tempStates = states.map(
    (state): StatesState => {
      let newOrder = state.order;
      if (state.id === id) {
        newOrder = order;
      } else if (originState.order < order && state.order > originState.order && state.order <= order) {
        newOrder -= 1;
      } else if (originState.order > order && state.order < originState.order && state.order >= order) {
        newOrder += 1;
      }

      return {
        id: state.id,
        name: state.name,
        order: newOrder,
        defaultState: false,
        validDataState: state.validDataState,
        invalidDataState: state.invalidDataState,
        missingDataState: state.missingDataState,
        parserErrorState: state.parserErrorState,
      };
    },
  );
  await dispatch(updateStates(tempStates));
};
