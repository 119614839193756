import React from 'react';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import _ from 'lodash';

import SecondaryText from '../../../shared/components/secondaryText/SecondaryText';
import { TemplatesTree } from '../../../store/interfaces/TemplatesTree';
import { TreeNode } from '../../../shared/components/tree/interfaces/TreeNode';
import { AnyFunction, Nullable } from '../../../shared/types/generics';
import { Option } from '../../../shared/components/form/controls/select/interfaces/Option';
import TreeNodeLabel from '../treeNodeLabel/TreeNodeLabel';
import { TREE_LEVEL_INDENT } from '../constants/dialog';
import { StatesState } from '../../../store/interfaces/StatesState';
import { FolderState } from '../../../store/interfaces/FolderState';

export const buildTemplatesTree = (
  tree: TemplatesTree,
  openTemplateDialog: AnyFunction,
  openFolderDialog: AnyFunction,
  openRemoveDialog: AnyFunction,
  downloadTemplate: AnyFunction,
): TreeNode[] => {
  let templatesTree = _.sortBy(tree.directories || [], (node) => node.name.toLowerCase()).map((node) => ({
    id: node.id,
    label: (
      <TreeNodeLabel
        label={node.name}
        hasChildren={hasChildren(node)}
        onTemplateAdd={(): void => openTemplateDialog({ parent: node.id })}
        onFolderAdd={(): void => openFolderDialog({ parent: node.id })}
        onEdit={(): void => openFolderDialog({ folder: node })}
        onDelete={(): void => openRemoveDialog(node)}
      />
    ),
    children: buildTemplatesTree(node, openTemplateDialog, openFolderDialog, openRemoveDialog, downloadTemplate),
  }));

  templatesTree = [
    ...templatesTree,
    ..._.sortBy(tree.templates || [], (node) => node.name.toLowerCase()).map((node) => ({
      id: `t.${node.id}`,
      label: (
        <TreeNodeLabel
          isTemplate
          label={node.name}
          onEdit={(): void => openTemplateDialog({ template: node })}
          onDelete={(): void => openRemoveDialog(node)}
          onDownload={(): void => downloadTemplate(node)}
        />
      ),
      children: [],
      icon: InsertDriveFileIcon,
    })),
  ];

  return templatesTree;
};

export const buildDirectoriesList = (tree: TemplatesTree, excludeNode: Nullable<TreeNode>, level = 0): Option[] => {
  let directoriesList: Option[] = [];
  _.sortBy(tree.directories || [], (folder) => folder.name.toLowerCase()).forEach((folder) => {
    if (!isFolder(excludeNode) || folder.id !== excludeNode.id) {
      directoriesList.push({
        value: folder.id,
        label: folder.name,
        style: {
          paddingLeft: `${TREE_LEVEL_INDENT + level * TREE_LEVEL_INDENT}px`,
        },
      });
      directoriesList = [...directoriesList, ...buildDirectoriesList(folder, excludeNode, level + 1)];
    }
  });

  return directoriesList;
};

export const buildTemplatesList = (tree: TemplatesTree, level = 0, path = ''): Option[] => {
  let templateOptions: Option[] = [];
  _.sortBy(tree.directories || [], (folder) => folder.name.toLowerCase()).forEach((folder) => {
    if (!folder.directories.length && !folder.templates.length) {
      return;
    }
    templateOptions = [...templateOptions, ...buildTemplatesList(folder, level + 1, path + `${folder.name}/`)];
  });
  _.sortBy(tree.templates || [], (template) => template.name.toLowerCase()).forEach((template) => {
    templateOptions.push({
      value: template.id,
      label: path + template.name,
    });
  });

  if (templateOptions.length === 0 && level === 0) {
    templateOptions = [{ value: undefined, label: 'No templates', style: { fontStyle: 'italic' } }];
  }
  return templateOptions;
};

export const buildStatesList = (
  states: StatesState[],
  defaultName = '(automatic)',
  nameToReplace?: string,
): Option[] => {
  let stateOptions: Option[] = states.map((state) => ({
    value: state.id,
    label: <div>{state.name === nameToReplace ? defaultName : state.name || defaultName}</div>,
  }));

  if (!stateOptions.length) {
    stateOptions = [{ value: '', label: 'No states', style: { fontStyle: 'italic' } }];
  }
  return stateOptions;
};

export const isFolder = (treeNode: Nullable<TreeNode>): boolean => treeNode?.hasOwnProperty('templates');

const hasChildren = (node: FolderState): boolean => {
  let isParent = false;
  if (node.templates.length) {
    isParent = true;
  }

  if (node.directories.length > 0) {
    node.directories.forEach((subFolder) => {
      if (isParent) return;

      isParent = hasChildren(subFolder);
    });
  }

  return isParent;
};
