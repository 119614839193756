import { downloadRequest, getRequest, postUploadRequest, putRequest, postRequest } from '../api';
import { DocumentsPaths } from './DocumentsPaths';
import { DocumentState } from '../../../store/interfaces/DocumentState';
import { AnyObject, CustomAny } from '../../types/generics';
import { DocumentsTableState } from '../../../store/interfaces/DocumentsTableState';
import { buildFormData } from '../helpers/apiHelper';
import { DocumentField } from '../../../store/interfaces/DocumentField';

export const get = (queryParams: AnyObject = {}): Promise<DocumentsTableState> =>
  getRequest(DocumentsPaths.List, { ...queryParams, archivedFilter: false });

export const add = (documentData: DocumentState, files: File[]): Promise<CustomAny> =>
  postUploadRequest(DocumentsPaths.List, files, { documentDTO: JSON.stringify(documentData) });

export const update = (id: string | number, documentData: DocumentState): Promise<CustomAny> =>
  putRequest(`${DocumentsPaths.List}/${id}`, documentData);

export const download = (id: string | number, version: string | number, name: string): Promise<CustomAny> =>
  downloadRequest(`${DocumentsPaths.List}/${id}/versions/${version}`, name);

export const updateVersion = (id: string | number, file: File): Promise<DocumentState> =>
  postUploadRequest(`${DocumentsPaths.List}/${id}/versions`, [file]);

export const getTagList = (): Promise<string[]> => getRequest(DocumentsPaths.Tags);

export const archiveDocument = (id: string | number): Promise<CustomAny> =>
  postRequest(`${DocumentsPaths.List}/${id}/archived`, buildFormData({ archived: true }), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const unarchiveDocument = (id: string | number): Promise<CustomAny> =>
  postRequest(`${DocumentsPaths.List}/${id}/archived`, buildFormData({ archived: false }), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const getDocumentFields = (id: number): Promise<DocumentField[]> =>
  getRequest(`${DocumentsPaths.List}/${id}/fields`);

export const getDocumentFieldsWithImages = (id: number): Promise<DocumentField[]> =>
  getRequest(`${DocumentsPaths.List}/${id}/fields/v2`);

export const getStatistics = (queryParams: AnyObject = {}): Promise<CustomAny> =>
  getRequest(DocumentsPaths.Statistics, queryParams);

export const changeState = (
  id: string | number,
  moveProps: { stateDescriptorId: number; comment?: string },
): Promise<CustomAny> =>
  postRequest(`${DocumentsPaths.List}/${id}/state`, buildFormData(moveProps), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const assignUser = (id: string | number, userId: number): Promise<CustomAny> =>
  postRequest(`${DocumentsPaths.List}/${id}/user`, buildFormData({ userId }), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const postWithTextSearch = (queryParams: AnyObject = {}): Promise<DocumentsTableState> =>
  postRequest(`${DocumentsPaths.List}/search`, buildFormData({ archivedFilter: false, ...queryParams }), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const postWithUserSearch = (queryParams: AnyObject = {}): Promise<DocumentsTableState> =>
  postRequest(`${DocumentsPaths.List}/user-search`, buildFormData(queryParams), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const postWithStateSearch = (queryParams: AnyObject = {}): Promise<DocumentsTableState> =>
  postRequest(`${DocumentsPaths.List}/state-search`, buildFormData(queryParams), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
