import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import classNames from 'classnames';
import _ from 'lodash';

import FormDialog from '../../../shared/components/dialog/FormDialog';
import FormGroup from '../../../shared/components/form/formGroup/FormGroup';
import InputControl from '../../../shared/components/form/controls/input/InputControl';
import SelectControl from '../../../shared/components/form/controls/select/SelectControl';
import FileInput from '../../../shared/components/form/controls/fileInput/FileInput';
import Title from '../../../shared/components/title/Title';
import MetadataFields from '../metadataFields/MetadataFields';
import TagList from './tagList/TagList';
import DocumentTitle from './documentTitle/DocumentTitle';
import { AnyFunction, AnyObject } from '../../../shared/types/generics';
import { DocumentFormSchema, extendWithMetadata } from './DocumentFormSchema';
import { DocumentFormData } from './DocumentFormData';
import { DocumentFormFields } from './DocumentFormFields';
import { Variants } from '../../../shared/components/title/enums/Variants';
import { MetadataState } from '../../../store/interfaces/MetadataState';
import { Size } from '../../../shared/components/dialog/enums/Size';
import { UserState } from '../../../store/interfaces/UserState';
import { StatesState } from '../../../store/interfaces/StatesState';
import { TemplatesTree } from '../../../store/interfaces/TemplatesTree';
import { buildTemplatesList, buildStatesList } from '../../templates/helpers/treeHelper';
import { LAST_TEMPLATE } from '../../../shared/constants/application';

import './DocumentFormDialog.scss';

export interface DocumentFormDialogProps {
  open: boolean;
  onSubmit: AnyFunction;
  onClose: AnyFunction;
  metadataFields: MetadataState[];
  activeUser?: number;
  userList: UserState[];
  stateList: StatesState[];
  templateList: TemplatesTree;
}

const DocumentFormDialog: FC<DocumentFormDialogProps> = (props) => {
  const [storedTemplate, storeTemplate] = useLocalStorage(LAST_TEMPLATE, undefined);
  const [tags, setTags] = useState<string[]>([]);
  const [metadataObject, setMetadataObject] = useState<AnyObject>({});
  const tagItems = useMemo(() => tags.map((tag) => ({ value: tag })), [tags]);
  const userOptions = useMemo(() => props.userList.map((user) => ({ value: user.id, label: user.login })), [
    props.userList,
  ]);
  const stateOptions = useMemo(() => buildStatesList(props.stateList, '(automatic)', 'processing'), [props.stateList]);
  const templateOptions = useMemo(() => buildTemplatesList(props.templateList), [props.templateList]);

  const onTagsChange = useCallback((tagList: string[]) => {
    setTags(tagList);
  }, []);
  const onSubmit = useCallback(
    async (formData: DocumentFormData) => {
      formData[DocumentFormFields.Tags] = tags;
      await props.onSubmit({
        ...formData,
        [DocumentFormFields.Metadata]: _.mapKeys(
          formData[DocumentFormFields.Metadata],
          (value: AnyObject, key: string) => key.replace('md', ''),
        ),
      });
      props.onClose();
    },
    [props, tags],
  );

  // refresh effect
  useEffect(() => {
    if (props.open) {
      setTags([]);
      setMetadataObject({});
    }
  }, [props.open]);

  return (
    <FormDialog
      className={classNames('document-dialog', { 'document-dialog__lg': _.size(props.metadataFields) > 0 })}
      onSubmit={onSubmit}
      validationSchema={extendWithMetadata(DocumentFormSchema, props.metadataFields)}
      open={props.open}
      onClose={props.onClose}
      title={<DocumentTitle />}
      size={Size.Md}
    >
      <FormGroup doubleColumn>
        <InputControl name={DocumentFormFields.Name} label="Name" required fullWidth autoFocus />
        <SelectControl
          name={DocumentFormFields.Template}
          label="Template"
          required
          options={templateOptions}
          value={
            templateOptions.length > 1
              ? _.find(templateOptions, { value: storedTemplate })
                ? storedTemplate
                : undefined
              : templateOptions[0].value
          }
          fullWidth
          onChange={storeTemplate}
        />
      </FormGroup>
      <FormGroup doubleColumn>
        <SelectControl
          name={DocumentFormFields.User}
          label="Assign to"
          required
          options={userOptions}
          value={props.activeUser}
          fullWidth
        />
        <SelectControl
          name={DocumentFormFields.State}
          label="State"
          required
          options={stateOptions}
          value={props.stateList[0]?.id}
          fullWidth
        />
      </FormGroup>
      <FormGroup>
        <TagList tagList={tagItems} onChange={onTagsChange} />
      </FormGroup>
      {_.size(props.metadataFields) > 0 && (
        <FormGroup>
          <section className="metadata">
            <Title variant={Variants.H6}>Metadata</Title>
            <MetadataFields values={metadataObject} fields={props.metadataFields} />
          </section>
        </FormGroup>
      )}
      <FormGroup>
        <FileInput name={DocumentFormFields.File} />
      </FormGroup>
    </FormDialog>
  );
};

export default DocumentFormDialog;
