import React, { FC, useCallback } from 'react';

import { AnyFunction } from '../../types/generics';
import FormDialog from './FormDialog';

interface RemoveDialogProps {
  open: boolean;
  onClose: AnyFunction;
  onSubmit: AnyFunction;
  type?: string;
  name?: string;
}

const RemoveDialog: FC<RemoveDialogProps> = (props) => {
  const { type = '', name = '' } = props;
  const onSubmit = useCallback(async () => {
    await props.onSubmit();
    props.onClose();
  }, [props]);

  return (
    <FormDialog
      open={props.open}
      onClose={props.onClose}
      title={
        <div className="dialog-title">
          Delete{type ? ` ${type}` : ''} <span className="dialog-title__entity-name">{name}</span>
        </div>
      }
      className="metadata-dialog"
      onSubmit={onSubmit}
      submitLabel="Remove"
    >
      <p>This action cannot be undone. Are you sure?</p>
    </FormDialog>
  );
};

export default RemoveDialog;
