import { Action, handleActions } from 'redux-actions';

import { TemplatesStructuresState } from '../interfaces/TemplatesStructuresState';
import { CustomAny } from '../../shared/types/generics';

const initialState: TemplatesStructuresState = {
  list: [],
  tree: {
    id: '0',
    directories: [],
    templates: [],
  },
};

export default handleActions(
  {
    TEMPLATES_TREE_SET: (state, { payload: tree }: Action<CustomAny>) => ({ ...state, tree }),
  },
  initialState,
);
