import { createAction } from 'redux-actions';
import _ from 'lodash';

import { UserState } from '../interfaces/UserState';
import { AnyDispatch, AnyFunction, CustomAny } from '../../shared/types/generics';
import { finishProcessingPath, startProcessingPath } from '../requestsProcessing/actions';
import * as usersService from '../../shared/api/users/UsersApiService';
import { UsersPaths } from '../../shared/api/users/UsersPaths';
import { UserFormData } from '../../pages/admin/users/userFormDialog/UserFormData';
import { UserFormFields } from '../../pages/admin/users/userFormDialog/UserFormFields';
import { getUser } from '../user/selectors';
import { updateActiveUser } from '../user/actions';

export const getUsers = (url = UsersPaths.Admin) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(url));

  try {
    const usersState = await usersService.get(url);
    dispatch(setUsers(usersState));
  } finally {
    dispatch(finishProcessingPath(url));
  }
};

export const updateUser = (id: number, formData: UserFormData) => async (
  dispatch: AnyDispatch,
  getState: AnyFunction,
): Promise<CustomAny> => {
  dispatch(startProcessingPath(UsersPaths.Admin));

  if (!formData[UserFormFields.Password]) {
    formData = _.omit(formData, UserFormFields.Password);
  }
  const currentUser = getUser(getState());

  try {
    const updatedUser = await usersService.update(id, formData);
    if (currentUser.id === updatedUser.id) {
      await dispatch(updateActiveUser(updatedUser, formData[UserFormFields.Password] || null));
    }
    const usersState = await usersService.get();
    dispatch(setUsers(usersState));
  } finally {
    dispatch(finishProcessingPath(UsersPaths.Admin));
  }
};

export const addUser = (formData: UserFormData) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(UsersPaths.Admin));

  try {
    await usersService.add(formData);
    const usersState = await usersService.get();
    dispatch(setUsers(usersState));
  } finally {
    dispatch(finishProcessingPath(UsersPaths.Admin));
  }
};

export const removeUser = (id: number) => async (dispatch: AnyDispatch): Promise<CustomAny> => {
  dispatch(startProcessingPath(UsersPaths.Admin));

  try {
    await usersService.remove(id);
    const usersState = await usersService.get();
    dispatch(setUsers(usersState));
  } finally {
    dispatch(finishProcessingPath(UsersPaths.Admin));
  }
};

export const setUsers = createAction('USERS_SET', (users: UserState[]) => users);
