import React, { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { connector, DispatchProps, StateProps } from './connector';
import PrimarySection from '../../../shared/layouts/primarySection/PrimarySection';
import Table from '../../../shared/components/table/Table';
import UserFormDialog from './userFormDialog/UserFormDialog';
import RemoveDialog from '../../../shared/components/dialog/RemoveDialog';
import { Align } from '../../../shared/layouts/primarySection/enums/Align';
import { USERS_COLUMNS } from './constants/table';
import { UserState } from '../../../store/interfaces/UserState';
import { Nullable } from '../../../shared/types/generics';
import { Action } from '../../../shared/components/table/interfaces/Action';
import { TableAction } from '../../../shared/components/table/enums/TableAction';
import { Entity } from '../../../shared/enums/Entity/Entity';
import { DEFAULT_TABLE_SETTINGS } from '../../../shared/components/table/constants/common';
import { TABLE_CONF_PREFIX } from '../../../shared/constants/application';
import { TableConf } from '../../../shared/components/table/interfaces/TableConf';

import './Users.scss';

interface UsersProps extends StateProps, DispatchProps {}

const Users: FC<UsersProps> = (props) => {
  const [tableConf, setTableConf] = useLocalStorage<TableConf>(`${TABLE_CONF_PREFIX}${Entity.User}`, {});
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Nullable<UserState>>(null);
  const [tableSettings, setTableSettings] = useState({
    ...DEFAULT_TABLE_SETTINGS,
    ...tableConf,
  });
  const sortOrder = useMemo(() => {
    if (tableConf?.fieldToSort) {
      return {
        fieldToSort: tableConf.fieldToSort,
        ascended: !!tableConf.ascended,
      };
    }

    return undefined;
  }, [tableConf]);

  useEffect(() => {
    return setTableSettings({
      ...DEFAULT_TABLE_SETTINGS,
      ...tableConf,
    });
  }, [tableConf]);

  const handleAddButtonClick = useCallback(() => {
    setOpenUserDialog(true);
  }, []);

  const handleCloseFormDialog = useCallback(() => {
    setSelectedUser(null);
    setOpenUserDialog(false);
  }, []);

  const handleEditButtonClick = useCallback((user) => {
    setSelectedUser(user);
    setOpenUserDialog(true);
  }, []);

  const handleSubmitFormDialog = useCallback(
    (formData) => {
      if (selectedUser) {
        return props.onEditUser(selectedUser?.id, formData);
      }
      return props.onAddUser(formData);
    },
    [props, selectedUser],
  );

  const handleOpenRemoveDialog = useCallback((user) => {
    setSelectedUser(user);
    setOpenDeleteUserDialog(true);
  }, []);

  const handleCloseRemoveDialog = useCallback(() => {
    setOpenDeleteUserDialog(false);
  }, []);

  const handleRemove = useCallback(() => {
    props.onRemoveUser(selectedUser?.id);
    setSelectedUser(null);
  }, [props, selectedUser]);

  const handleTableSettingsChange = useCallback(
    (newSettings) => {
      setTableSettings(newSettings);
      setTableConf({
        ...tableConf,
        rowsPerPage: newSettings.rowsPerPage,
      });
    },
    [setTableConf, tableConf],
  );

  const handleSortChange = useCallback(
    ({ ascended, fieldToSort }) => {
      setTableConf({
        ...tableConf,
        ascended,
        fieldToSort,
      });
    },
    [setTableConf, tableConf],
  );

  useEffect(
    useCallback(() => {
      props.getUsers();
    }, [props]),
    [],
  );

  const tableActions: Action[] = useMemo(
    () => [
      {
        label: `${TableAction.Edit} ${Entity.User}`,
        onClick: handleEditButtonClick,
        icon: EditIcon,
      },
      {
        label: `${TableAction.Delete} ${Entity.User}`,
        onClick: handleOpenRemoveDialog,
        icon: DeleteIcon,
        isDisabled: (user): boolean => user.id === props.activeUserId,
      },
    ],
    [handleEditButtonClick, handleOpenRemoveDialog, props.activeUserId],
  );
  USERS_COLUMNS[0].onClick = (dataIndex: number): void => handleEditButtonClick(props.users[dataIndex]);

  return (
    <PrimarySection className="users" verticalAlign={Align.Start} ignoreHeader processing={props.processing}>
      <Table
        columns={USERS_COLUMNS}
        data={props.users}
        actions={tableActions}
        onAdd={handleAddButtonClick}
        entity={Entity.User}
        tableSettings={tableSettings}
        onChangeTableSettings={handleTableSettingsChange}
        sortOrder={sortOrder}
        onSort={handleSortChange}
        textLabels={{
          body: {
            noMatch: 'No users',
          },
        }}
      />
      <UserFormDialog
        open={openUserDialog}
        data={selectedUser}
        onClose={handleCloseFormDialog}
        onSubmit={handleSubmitFormDialog}
      />
      <RemoveDialog
        open={openDeleteUserDialog}
        onClose={handleCloseRemoveDialog}
        onSubmit={handleRemove}
        name={selectedUser?.login}
      />
    </PrimarySection>
  );
};

export default connector(Users);
