import React, { FC } from 'react';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';

import { AnyFunction } from '../../../types/generics';
import IconButton from '../../iconButton/IconButton';
import Title from '../../title/Title';
import { Variants } from '../../title/enums/Variants';
import CircularLoading from '../../circularLoading/CircularLoading';

import './TableTitle.scss';

interface TableTitleProps {
  title?: string;
  entity?: string;
  onAdd?: AnyFunction;
  isLoading?: boolean;
}

const TableTitle: FC<TableTitleProps> = (props) => {
  return (
    <section className="table-title">
      {props.title && (
        <Title variant={Variants.H5} className="table-title__name">
          {props.title}
        </Title>
      )}
      {props.isLoading ? (
        <CircularLoading size={24} />
      ) : (
        props.onAdd && (
          <IconButton onClick={props.onAdd} ariaLabel={`Add ${props.entity || ''}`.trim()}>
            <AddBoxOutlinedIcon />
          </IconButton>
        )
      )}
    </section>
  );
};

export default TableTitle;
