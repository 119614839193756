import { handleActions } from 'redux-actions';

import { LogTableState } from '../interfaces/LogTableState';

const initialState: LogTableState = {
  content: [],
  totalElements: 0,
  operations: [],
  totalPages: 0,
  number: 0,
};

export default handleActions(
  {
    LOGS_SET: (state, { payload: logs }) => ({ ...state, ...logs }),
    OPERATIONS_SET: (state, { payload: { operations } }) => ({
      ...state,
      operations,
    }),
  },
  initialState,
);
